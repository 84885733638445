import { OrderType, SortRows, SortRowsForKey } from '../../domain/schemas';

/**
 * typeがstringの場合のソート処理
 * @param rows ソート対象のrows
 * @param index ソート対象の列のindex
 * @param order ソート条件
 */
export const sortString = <T extends SortRows | SortRowsForKey>(
  rows: T[],
  index: number,
  order: OrderType,
  /**
   * [機種別商圏] PPM区分の場合のみ特定の順序で並び替える必要があるため、その判別に使用する
   */
  sortColumnCode?: string
): T[] => {
  const compareStrings = (a: T, b: T, order: OrderType) => {
    const valueA = a.data.at(index)?.value;
    const valueB = b.data.at(index)?.value;

    if (valueA == null || valueB == null) {
      return 0;
    }

    // queryParameterにnameが含まれる場合はvalueがnullの場合は一番上に配置(平均合計行の場合はnullなので一番上に持ってくる)
    if ('name' in a.queryParameter) {
      if (a.queryParameter.value === null) {
        return -1;
      }
    } else {
      // queryParameter.kiList(他の場合も)がnullの場合は一番上に配置(平均合計行の場合はnullなので一番上に持ってくる)
      if (
        a.queryParameter.kiList === null ||
        a.queryParameter.kiList?.at(0) === null ||
        a.queryParameter.sisTypes === null ||
        a.queryParameter.sisTypes?.at(0) === null ||
        a.queryParameter.makers === null ||
        a.queryParameter.makers?.at(0) === null ||
        a.queryParameter.halls === null ||
        a.queryParameter.halls?.at(0) === null
      ) {
        return -1;
      }
    }
    if ('name' in b.queryParameter) {
      if (b.queryParameter.value === null) {
        return 1;
      }
    } else {
      if (
        b.queryParameter.kiList === null ||
        b.queryParameter.kiList?.at(0) === null ||
        b.queryParameter.sisTypes === null ||
        b.queryParameter.sisTypes?.at(0) === null ||
        b.queryParameter.makers === null ||
        b.queryParameter.makers?.at(0) === null ||
        b.queryParameter.halls === null ||
        b.queryParameter.halls?.at(0) === null
      ) {
        return 1;
      }
    }

    // 表示項目がPPM区分の場合のみ、特定の順序で並び替える
    if (sortColumnCode === 'ppmKbn') {
      // ハイフンを後ろにするための条件
      if (valueA === '-') return 1;
      if (valueB === '-') return -1;
      const orderList = ['問題児', '負け犬', '花形', '金のなる木'];
      const sortedOrderList =
        order === 'desc' ? orderList : [...orderList].reverse();
      const posA = sortedOrderList.indexOf(valueA);
      const posB = sortedOrderList.indexOf(valueB);

      if (posA !== -1 && posB !== -1) return posA - posB;
      if (posA !== -1 && posB === -1) return -1;
      if (posA === -1 && posB !== -1) return 1;
    }

    // ハイフンを後ろにするための条件
    if (valueA === '-') return 1;
    if (valueB === '-') return -1;

    // 文字列の比較
    const comparison = valueA.localeCompare(valueB, ['en', 'ja'], {
      sensitivity: 'base',
      ignorePunctuation: true,
      numeric: true,
    });

    return order === 'asc' ? comparison : -comparison;
  };

  return rows.slice().sort((a, b) => compareStrings(a, b, order));
};
