import { createSelector } from 'reselect';

import { DataKi, DataKiParams } from '../../domain/dataKi';
import { ClarisApiError } from '../../domain/error';
import { generateInitialResponse } from '../../domain/ki/defaultValues';
import { LoadingState } from '../../domain/schemas';
import { KiTagListOptions } from '../../domain/schemas';
import { ShuOption } from '../../domain/shu';

import { SelectOption } from '../../components/molecules/SingleSelect';
import { ConvertRowsToFilteredRows } from '../../components/organisms/TableHeaderSubMenu/ConvertRowsToFilteredRows';

import { RootState } from '../../store';
import { filterModelName } from '../../utils/filterModelName';
import { getOrderedTableData } from '../../utils/orderedCell';
import { sortBy } from '../../utils/sortBy';
import { modelReportsSettingSelector } from '../ui/modelReportsSetting';

// Action Types

const FETCH_DATA_KI = 'FETCH_DATA_KI' as const;
const FETCH_DATA_KI_REQUEST = 'FETCH_DATA_KI_REQUEST' as const;
const FETCH_DATA_KI_SUCCESS = 'FETCH_DATA_KI_SUCCESS' as const;
const SHOW_DATA_KI_LOADING = 'SHOW_DATA_KI_LOADING' as const;
const HIDE_DATA_KI_LOADING = 'HIDE_DATA_KI_LOADING' as const;
const SELECT_DATA_KI_COLUMNS_ORDER = 'SELECT_DATA_KI_COLUMNS_ORDER' as const;
const CHANGE_DATA_KI_COLUMNS_ORDER = 'CHANGE_DATA_KI_COLUMNS_ORDER' as const;
const RESET_DATA_KI_COLUMNS_ORDER = 'RESET_DATA_KI_COLUMNS_ORDER' as const;
const SELECT_DATA_KI_KI_LIST = 'SELECT_DATA_KI_KI_LIST' as const;
const ADD_DATA_KI_CHECKED_LIST = 'ADD_DATA_KI_CHECKED_LIST' as const;
const ADD_DATA_KI_LIST_CHECKED_LIST = 'ADD_DATA_KI_LIST_CHECKED_LIST' as const;
const REMOVE_DATA_KI_CHECKED_LIST = 'REMOVE_DATA_KI_CHECKED_LIST' as const;
const REMOVE_DATA_KI_LIST_CHECKED_LIST =
  'REMOVE_DATA_KI_LIST_CHECKED_LIST' as const;
const RESET_DATA_KI_CHECKED_LIST = 'RESET_DATA_KI_CHECKED_LIST' as const;
const FETCH_DATA_KI_FILTERED_AVERAGE_REQUEST =
  'FETCH_DATA_KI_FILTERED_AVERAGE_REQUEST' as const;
const FETCH_DATA_KI_FILTERED_AVERAGE_SUCCESS =
  'FETCH_DATA_KI_FILTERED_AVERAGE_SUCCESS' as const;
const RESET_DATA_KI_FILTERED_AVERAGE =
  'RESET_DATA_KI_FILTERED_AVERAGE' as const;
const RENEW_DATA_KI = 'RENEW_DATA_KI' as const;
const SELECT_DATA_KI_FILTERED_KI_TAG_AVERAGE =
  'SELECT_DATA_KI_FILTERED_KI_TAG_AVERAGE' as const;
const RESET_SELECT_DATA_KI_FILTERED_KI_TAG_AVERAGE =
  'RESET_SELECT_DATA_KI_FILTERED_KI_TAG_AVERAGE' as const;
const FETCH_DATA_KI_FILTERED_KI_TAG_AVERAGE_REQUEST =
  'FETCH_DATA_KI_FILTERED_KI_TAG_AVERAGE_REQUEST' as const;
const FETCH_DATA_KI_FILTERED_KI_TAG_AVERAGE_SUCCESS =
  'FETCH_DATA_KI_FILTERED_KI_TAG_AVERAGE_SUCCESS' as const;
const RESET_DATA_KI_FILTERED_KI_TAG_AVERAGE =
  'RESET_DATA_KI_FILTERED_KI_TAG_AVERAGE' as const;
const FETCH_DATA_KI_ONLY_ONE_KI_TAG_AVERAGE_REQUEST =
  'FETCH_DATA_KI_ONLY_ONE_KI_TAG_AVERAGE_REQUEST' as const;
const REMOVE_DATA_KI_ONLY_ONE_KI_TAG_AVERAGE =
  'REMOVE_DATA_KI_ONLY_ONE_KI_TAG_AVERAGE' as const;
const FETCH_DATA_KI_ONLY_ONE_KI_TAG_AVERAGE_SUCCESS =
  'FETCH_DATA_KI_ONLY_ONE_KI_TAG_AVERAGE_SUCCESS' as const;
const RESET_DATA_KI_ONLY_ONE_KI_TAG_AVERAGE =
  'RESET_DATA_KI_ONLY_ONE_KI_TAG_AVERAGE' as const;

export const DataKiActionTypes = {
  FETCH_DATA_KI,
  FETCH_DATA_KI_REQUEST,
  FETCH_DATA_KI_SUCCESS,
  SHOW_DATA_KI_LOADING,
  HIDE_DATA_KI_LOADING,
  SELECT_DATA_KI_COLUMNS_ORDER,
  CHANGE_DATA_KI_COLUMNS_ORDER,
  RESET_DATA_KI_COLUMNS_ORDER,
  SELECT_DATA_KI_KI_LIST,
  ADD_DATA_KI_CHECKED_LIST,
  ADD_DATA_KI_LIST_CHECKED_LIST,
  REMOVE_DATA_KI_CHECKED_LIST,
  REMOVE_DATA_KI_LIST_CHECKED_LIST,
  RESET_DATA_KI_CHECKED_LIST,
  FETCH_DATA_KI_FILTERED_AVERAGE_REQUEST,
  FETCH_DATA_KI_FILTERED_AVERAGE_SUCCESS,
  RESET_DATA_KI_FILTERED_AVERAGE,
  RENEW_DATA_KI,

  SELECT_DATA_KI_FILTERED_KI_TAG_AVERAGE,
  RESET_SELECT_DATA_KI_FILTERED_KI_TAG_AVERAGE,
  FETCH_DATA_KI_FILTERED_KI_TAG_AVERAGE_REQUEST,
  FETCH_DATA_KI_FILTERED_KI_TAG_AVERAGE_SUCCESS,
  RESET_DATA_KI_FILTERED_KI_TAG_AVERAGE,
  FETCH_DATA_KI_ONLY_ONE_KI_TAG_AVERAGE_REQUEST,
  REMOVE_DATA_KI_ONLY_ONE_KI_TAG_AVERAGE,
  FETCH_DATA_KI_ONLY_ONE_KI_TAG_AVERAGE_SUCCESS,
  RESET_DATA_KI_ONLY_ONE_KI_TAG_AVERAGE,
};

// Action Creators

export function fetchDataKiAction(
  params: DataKiParams,
  currentShu: ShuOption | undefined,
  currentHalls?: string[]
) {
  return {
    type: FETCH_DATA_KI,
    payload: { params, currentShu, currentHalls },
  };
}

function fetchDataKiRequestAction() {
  return {
    type: FETCH_DATA_KI_REQUEST,
  };
}

function fetchDataKiSuccessAction(dataKi: DataKi) {
  return {
    type: FETCH_DATA_KI_SUCCESS,
    payload: { dataKi },
  };
}

function showLoadingAction() {
  return {
    type: SHOW_DATA_KI_LOADING,
    payload: { loadingState: 'loading' as const },
  };
}

function hideLoadingAction() {
  return {
    type: HIDE_DATA_KI_LOADING,
    payload: { loadingState: 'loaded' as const },
  };
}

/**
 *
 * 機種集計の並び替え情報を登録する（Sagaで並び替え確定時）
 */
function selectDataKiColumnsOrderAction(columnsOrder: string[]) {
  return {
    type: SELECT_DATA_KI_COLUMNS_ORDER,
    payload: { columnsOrder },
  };
}

/**
 * 機種集計の列の並び替え実行時にSagaで再計算する
 */
function changeDataKiColumnsOrderAction(
  draggedId?: string,
  droppedId?: string
) {
  return {
    type: CHANGE_DATA_KI_COLUMNS_ORDER,
    payload: { draggedId, droppedId },
  };
}

/**
 * 機種集計の並び替え情報をリセットする
 */
function resetDataKiColumnsOrderAction() {
  return {
    type: RESET_DATA_KI_COLUMNS_ORDER,
  };
}

/**
 * 絞り込む機種リストを変更
 *
 * @param kiList 絞り込む機種リスト
 */
function selectDataKiListAction(kiList: string[]) {
  return {
    type: SELECT_DATA_KI_KI_LIST,
    payload: { kiList },
  };
}

/**
 * 機種をチェック済みリストに追加する
 */
function addDataKiCheckedListAction(payload: { group: string; name: string }) {
  return {
    type: ADD_DATA_KI_CHECKED_LIST,
    payload,
  };
}

/**
 * 複数の機種をまとめてチェック済みリストに追加する
 */
function addDataKiListCheckedListAction(
  kiList: { group: string; name: string }[]
) {
  return {
    type: ADD_DATA_KI_LIST_CHECKED_LIST,
    payload: kiList,
  };
}

/**
 * 機種をチェック済みリストから削除する
 */
function removeDataKiCheckedListAction(payload: {
  group: string;
  name: string;
}) {
  return {
    type: REMOVE_DATA_KI_CHECKED_LIST,
    payload,
  };
}

/**
 * 複数の機種をまとめてチェック済みリストから削除する
 */
function removeDataKiListCheckedListAction(
  payload: { group: string; name: string }[]
) {
  return {
    type: REMOVE_DATA_KI_LIST_CHECKED_LIST,
    payload,
  };
}

/**
 * 機種のチェック状態を解除する
 */
function resetDataKiCheckedListAction() {
  return {
    type: RESET_DATA_KI_CHECKED_LIST,
  };
}

function fetchDataKiFilteredAverageRequestAction(
  searchResultSetting: DataKiParams,
  selectedKiList: string[] = []
) {
  return {
    type: FETCH_DATA_KI_FILTERED_AVERAGE_REQUEST,
    payload: {
      params: {
        ...searchResultSetting,
        kiList: selectedKiList,
      },
    },
  };
}

function fetchDataKiFilteredAverageSuccessAction(data: DataKi) {
  return {
    type: FETCH_DATA_KI_FILTERED_AVERAGE_SUCCESS,
    payload: { data },
  };
}

function resetDataKiFilteredAverageAction() {
  return {
    type: RESET_DATA_KI_FILTERED_AVERAGE,
  };
}

function renewDataKi() {
  return {
    type: RENEW_DATA_KI,
  };
}

/**
 * 機種タグ設定 選択されたタグのvalue値登録
 * @param value
 * @returns
 */
function selectDataKiFilteredKiTagAverageAction(value: KiTagListOptions[]) {
  return {
    type: SELECT_DATA_KI_FILTERED_KI_TAG_AVERAGE,
    payload: { value },
  };
}

/**
 * 機種タグ設定 選択されたタグのvalue値リセット処理
 * @param value
 * @returns
 */
function resetSelectDataKiFilteredKiTagAverageAction() {
  return {
    type: RESET_SELECT_DATA_KI_FILTERED_KI_TAG_AVERAGE,
  };
}

/**
 * 機種タグ設定 選択されたタグに紐づく機種を登録
 * @param searchResultSetting
 * @param selectedKiList
 * @returns
 */
function fetchDataKiFilteredKiTagAverageRequestAction(
  searchResultSetting: DataKiParams,
  selectedKiList: (string | undefined)[] = []
) {
  return {
    type: FETCH_DATA_KI_FILTERED_KI_TAG_AVERAGE_REQUEST,
    payload: {
      params: {
        ...searchResultSetting,
        kiList: selectedKiList,
      },
    },
  };
}

/**
 * 機種タグ設定 機種タグに紐づけられた全機種を登録
 * @param data
 * @returns
 */
function fetchDataKiFilteredKiTagAverageSuccessAction(data: DataKi) {
  return {
    type: FETCH_DATA_KI_FILTERED_KI_TAG_AVERAGE_SUCCESS,
    payload: { data },
  };
}

/**
 * 機種タグ設定 選択された機種タグ条件を解除
 * @returns
 */
function resetDataKiFilteredKiTagAverageAction() {
  return {
    type: RESET_DATA_KI_FILTERED_KI_TAG_AVERAGE,
  };
}

/**
 * 機種タグ設定 選択されたタグに紐づく機種を登録（タグ別平均行用）
 * @param searchResultSetting
 * @param selectedKiList
 * @param tagId
 * @returns
 */
function fetchDataKiOnlyOneKiTagAverageRequestAction(
  searchResultSetting: DataKiParams,
  selectedKiList: (string | undefined)[] = [],
  tagId: string
) {
  return {
    type: FETCH_DATA_KI_ONLY_ONE_KI_TAG_AVERAGE_REQUEST,
    payload: {
      params: {
        ...searchResultSetting,
        kiList: selectedKiList,
        tagIds: [tagId],
      },
    },
  };
}

/**
 * 機種タグ設定 選択されたタグに紐づく機種を削除（タグ別平均行用）
 * @param value
 * @returns
 */
function removeDataKiOnlyOneKiTagAverageAction(value: KiTagListOptions[]) {
  return {
    type: REMOVE_DATA_KI_ONLY_ONE_KI_TAG_AVERAGE,
    payload: {
      value,
    },
  };
}

/**
 * 機種タグ設定 機種タグに紐づけられた全機種を登録（タグ別平均行用）
 * @param data
 * @returns
 */
function fetchDataKiOnlyOneKiTagAverageSuccessAction(data: DataKi) {
  return {
    type: FETCH_DATA_KI_ONLY_ONE_KI_TAG_AVERAGE_SUCCESS,
    payload: { data },
  };
}

/**
 * 機種タグ設定 選択された機種タグ条件を解除（タグ別平均行用）
 * @returns
 */
function resetDataKiOnlyOneKiTagAverageAction() {
  return {
    type: RESET_DATA_KI_ONLY_ONE_KI_TAG_AVERAGE,
  };
}

export const DataKiActionCreators = {
  fetchDataKiAction,
  fetchDataKiRequestAction,
  fetchDataKiSuccessAction,
  showLoadingAction,
  hideLoadingAction,
  selectDataKiColumnsOrderAction,
  changeDataKiColumnsOrderAction,
  resetDataKiColumnsOrderAction,
  selectDataKiListAction,
  addDataKiCheckedListAction,
  addDataKiListCheckedListAction,
  removeDataKiCheckedListAction,
  removeDataKiListCheckedListAction,
  resetDataKiCheckedListAction,
  fetchDataKiFilteredAverageRequestAction,
  fetchDataKiFilteredAverageSuccessAction,
  resetDataKiFilteredAverageAction,
  renewDataKi,

  selectDataKiFilteredKiTagAverageAction,
  resetSelectDataKiFilteredKiTagAverageAction,
  fetchDataKiFilteredKiTagAverageRequestAction,
  fetchDataKiFilteredKiTagAverageSuccessAction,
  resetDataKiFilteredKiTagAverageAction,
  fetchDataKiOnlyOneKiTagAverageRequestAction,
  removeDataKiOnlyOneKiTagAverageAction,
  fetchDataKiOnlyOneKiTagAverageSuccessAction,
  resetDataKiOnlyOneKiTagAverageAction,
};

// Actions

export type FetchDataKiAction = ReturnType<typeof fetchDataKiAction>;
type ShowLoadingAction = ReturnType<typeof showLoadingAction>;
type HideLoadingAction = ReturnType<typeof hideLoadingAction>;
type SelectDataKiColumnsOrderAction = ReturnType<
  typeof selectDataKiColumnsOrderAction
>;
export type ChangeDataKiColumnsOrderAction = ReturnType<
  typeof changeDataKiColumnsOrderAction
>;
type SelectDataKiListAction = ReturnType<typeof selectDataKiListAction>;
export type FetchDataKiOnlyOneKiTagAverageRequestAction = ReturnType<
  typeof fetchDataKiOnlyOneKiTagAverageRequestAction
>;

type DataKiAction =
  | FetchDataKiAction
  | ReturnType<typeof fetchDataKiRequestAction>
  | ReturnType<typeof fetchDataKiSuccessAction>
  | ReturnType<typeof resetDataKiColumnsOrderAction>
  | ShowLoadingAction
  | HideLoadingAction
  | SelectDataKiColumnsOrderAction
  | ChangeDataKiColumnsOrderAction
  | SelectDataKiListAction
  | ReturnType<typeof addDataKiCheckedListAction>
  | ReturnType<typeof addDataKiListCheckedListAction>
  | ReturnType<typeof removeDataKiCheckedListAction>
  | ReturnType<typeof removeDataKiListCheckedListAction>
  | ReturnType<typeof resetDataKiCheckedListAction>
  | ReturnType<typeof fetchDataKiFilteredAverageRequestAction>
  | ReturnType<typeof fetchDataKiFilteredAverageSuccessAction>
  | ReturnType<typeof resetDataKiFilteredAverageAction>
  | ReturnType<typeof renewDataKi>
  | ReturnType<typeof selectDataKiFilteredKiTagAverageAction>
  | ReturnType<typeof resetSelectDataKiFilteredKiTagAverageAction>
  | ReturnType<typeof fetchDataKiFilteredKiTagAverageRequestAction>
  | ReturnType<typeof removeDataKiOnlyOneKiTagAverageAction>
  | ReturnType<typeof fetchDataKiFilteredKiTagAverageSuccessAction>
  | ReturnType<typeof resetDataKiFilteredKiTagAverageAction>
  | FetchDataKiOnlyOneKiTagAverageRequestAction
  | ReturnType<typeof fetchDataKiOnlyOneKiTagAverageSuccessAction>
  | ReturnType<typeof resetDataKiOnlyOneKiTagAverageAction>;

// State

type DataKiState = {
  dataKi: DataKi;
  loadingState: LoadingState;
  columnsOrder?: string[];
  /**
   * フィルターされた機種リスト
   * 古いお気に入りデータにはkiListがないためoptionalとしている
   */
  kiList?: string[];

  /**
   * チェック状態にされた機種リスト
   * 種別ごとに別物として扱うため種別情報を付与する
   */
  checkedKiList?: {
    group: string;
    name: string;
  }[];

  filteredAverage: {
    isLoading: boolean;
    error: ClarisApiError | null;
    data: DataKi | null;
  };

  filteredKiTagAverage: {
    isLoading: boolean;
    error: ClarisApiError | null;
    data: DataKi | null;
    value: KiTagListOptions[];
  };
  onlyOneKiTagAverage: {
    isLoading: boolean;
    error: ClarisApiError | null;
    data: DataKi[] | null;
  };
};

export const initialState: DataKiState = {
  dataKi: generateInitialResponse(),
  loadingState: 'prepare',
  columnsOrder: [],
  kiList: [],
  checkedKiList: [],
  filteredAverage: {
    isLoading: false,
    error: null,
    data: null,
  },
  filteredKiTagAverage: {
    isLoading: false,
    error: null,
    data: null,
    value: [],
  },
  onlyOneKiTagAverage: {
    isLoading: false,
    error: null,
    data: null,
  },
};

// Selector

/**
 * 機種集計のテーブルデータすべて取得する
 */
const defaultDataKiSelector = (state: RootState) => {
  return state.dataKi.dataKi;
};

/**
 * 数値フィルターでフィルターされた機種集計のテーブルデータすべて取得する
 * @returns テーブルデータ
 */
export const dataKiSelector = createSelector(
  [defaultDataKiSelector, modelReportsSettingSelector],
  (dataKi, modelReportsSetting) => {
    const modelNameFilter = modelReportsSetting.modelNameFilter;

    const subMenuNumberFilter = modelReportsSetting.subMenuNumberFilter;

    const { columns, rows } = dataKi.data;

    const { filteredRows } = ConvertRowsToFilteredRows(
      subMenuNumberFilter,
      columns,
      rows
    );

    //サブメニュー内の数値でフィルタ
    if (!modelNameFilter) {
      return {
        ...dataKi,
        data: {
          ...dataKi.data,
          rows: filteredRows,
        },
      };
    }

    // 機種名でフィルタ
    const predicate = filterModelName(modelNameFilter);
    return {
      ...dataKi,
      data: {
        ...dataKi.data,
        rows: filteredRows.filter(
          (row) => row.data.length > 0 && predicate(row.data.at(0)?.value ?? '')
        ),
      },
    };
  }
);

/**
 * 機種集計のテーブルの現在の検索条件を取得する
 * @returns 検索条件
 */
export const dataKiParamsSelector = createSelector(
  defaultDataKiSelector,
  (dataKi) => dataKi.setting
);

/**
 * 機種集計のテーブルの現在の検索条件のメインフィールドを取得する
 * @returns メインフィールド
 */
export const dataKiSettingMainFieldSelector = createSelector(
  dataKiParamsSelector,
  (setting) => setting.mainField
);

/**
 * レスポンスの検索条件から種別グループを取得
 */
export const dataKiSettingShuGroupIdSelector = createSelector(
  dataKiParamsSelector,
  (setting) => setting.shuGroupIds?.at(0)
);

/**
 * レスポンスの検索条件から種別を取得
 */
export const dataKiSettingShuIdSelector = createSelector(
  dataKiParamsSelector,
  (setting) => setting.shuList?.at(0)
);

/**
 * レスポンスの検索条件から店舗を取得
 */
export const dataKiSettingHallCodesSelector = createSelector(
  dataKiParamsSelector,
  (setting) => setting.halls ?? []
);

/**
 * レスポンスの検索条件から当日除外を取得
 */
export const dataKiSettingExcludeTodaySelector = createSelector(
  dataKiParamsSelector,
  (setting) => !!setting.excludeToday
);

/**
 * レスポンスの検索条件から検索期間の最終日付を取得
 */
export const dataKiSettingEndDateSelector = createSelector(
  dataKiParamsSelector,
  (setting) => setting.ymdList && setting.ymdList[setting.ymdList.length - 1]
);

/**
 * 機種集計のテーブルデータのデータ部分のみ取得
 * @returns テーブルデータ（検索条件除いた部分）
 */
const dataKiDataSelector = createSelector(dataKiSelector, (dataKi) => {
  return dataKi.data;
});

/**
 * 機種集計のテーブルデータのカラムのみ取得
 * @returns テーブルデータのカラム
 */
export const dataKiDataColumnsSelector = createSelector(
  dataKiDataSelector,
  (data) => {
    return data.columns;
  }
);

/**
 * 機種集計のテーブルデータのデータ部分（データ行）のみ取得
 * @returns テーブルデータのデータ行（columnsを除いた部分）
 */
const dataKiDataRowSelector = createSelector(dataKiDataSelector, (data) => {
  return data.rows;
});

/**
 * フィルターされている機種
 * @returns 現在選択されている種別（undefined時未選択）
 */
export const dataKiSelectedKiListSelector = (state: RootState) => {
  return state.dataKi.kiList;
};

/**
 * 機種集計のテーブルの並び替え情報を取得
 * @returns 並び替え情報
 */
export const dataKiColumnsOrderSelector = (state: RootState) =>
  state.dataKi.columnsOrder;

/**
 * 機種タグで選択した機種で絞り込んだ平均合計を取得
 */
export const dataKiFilteredKiTagAverageSelector = (state: RootState) => {
  return state.dataKi.filteredKiTagAverage;
};

/**
 * 選択した各機種タグの平均合計を取得
 */
const dataKiOnlyOneKiTagAverageSelector = (state: RootState) =>
  state.dataKi.onlyOneKiTagAverage;

/**
 * 機種タグで絞り込んだ平均合計行の結果から絞り込まれた機種コードの一覧を取得する
 */
export const dataKiModelCodesOfSelectedTagsSelector = createSelector(
  dataKiFilteredKiTagAverageSelector,
  ({ data }) => data?.setting.kiList ?? []
);

/**
 * 機種タグで絞り込んだテーブルデータ
 */
const dataKiFilteredByTagsDataSelector = createSelector(
  [dataKiDataSelector, dataKiModelCodesOfSelectedTagsSelector],
  (tableData, modelCodes) => {
    if (!tableData || modelCodes.length === 0) {
      return tableData;
    }

    const filteredRows = tableData.rows.filter(
      (row) =>
        modelCodes.includes(row.queryParameter.value) ||
        !row.queryParameter.value
    );

    return {
      ...tableData,
      rows: filteredRows,
    };
  }
);

/**
 * 機種タグ紐づいた機種リストとレスポンスdataKiで絞り込んだテーブルデータ
 */
const defaultDataKiFilteredByTagsDataSelector = createSelector(
  [
    defaultDataKiSelector,
    dataKiModelCodesOfSelectedTagsSelector,
    defaultDataKiSelector,
  ],
  ({ data: defaultTableData }, modelCodes) => {
    if (!defaultTableData || modelCodes.length === 0) {
      return defaultTableData;
    }

    const filteredKiTagRows = defaultTableData.rows.filter(
      (row) =>
        modelCodes.includes(row.queryParameter.value) ||
        !row.queryParameter.value
    );

    return {
      ...defaultTableData,
      rows: filteredKiTagRows,
    };
  }
);

/**
 * 機種集計のテーブルの並び替え後のテーブルデータを取得する
 * @returns 並び替え後のテーブルデータ
 */
export const dataKiOrderedDataSelector = createSelector(
  [
    dataKiFilteredByTagsDataSelector,
    dataKiColumnsOrderSelector,
    dataKiSelectedKiListSelector,
  ],
  (tableData, ordered, kiList) => {
    // テーブルが空の場合はそのまま戻す
    if (tableData === undefined) {
      return { columns: [], rows: [] };
    }

    // 並べ替え後の項目数と、テーブルデータの項目数が一致しない場合、データ取得中
    if (ordered && tableData.columns.length !== ordered.length) {
      return { columns: [], rows: [] };
    }

    const rows =
      !kiList || kiList.length === 0
        ? tableData.rows
        : tableData.rows.filter((row) => {
            return row.queryParameter.value === null
              ? true
              : row.queryParameter.name === 'kiList'
              ? kiList.includes(row.queryParameter.value)
              : false;
          });

    if (!ordered) {
      return { ...tableData, rows };
    } else {
      return getOrderedTableData(ordered, tableData.columns, rows);
    }
  }
);

/**
 * 取得したテーブルデータを元に選択可能な機種の一覧を取得
 * @returns 選択可能な機種一覧
 */
export const dataKiSelectOptionsSelector = createSelector(
  dataKiDataRowSelector,
  (rows): SelectOption[] => {
    // 平均合計行を取り除く
    const filteredRows = rows.filter(
      (row) => row.queryParameter.value !== null
    );

    // SelectOptionの形に整形する
    return filteredRows.map((row) => ({
      name: row.data.at(0)?.value ?? '',
      value: row.queryParameter.value,
      type: row.queryParameter.name,
    }));
  }
);

/**
 * 現在のチェック済みのリストを取得する
 */
export const dataKiCheckedListSelector = (state: RootState) => {
  const checkedKiList = state.dataKi.checkedKiList
    ? state.dataKi.checkedKiList
    : [];
  const dataKi = state.dataKi.dataKi;

  const group = dataKi.setting.shuList
    ? dataKi.setting.shuList[0]
    : dataKi.setting.shuGroupIds
    ? dataKi.setting.shuGroupIds[0]
    : '';

  const modelNumbers = dataKi.data.rows.map(({ queryParameter }) =>
    queryParameter.name === 'kiList' ? queryParameter.value : null
  );

  return checkedKiList
    .filter((item) => item.group === group && modelNumbers.includes(item.name))
    .map((item) => item.name);
};

/**
 * 現在のチェック済みのリストを種別付きで取得する
 */
export const dataKiRawCheckedListSelector = (state: RootState) => {
  return state.dataKi.checkedKiList;
};

/**
 * 現在のローディング状態のみ取得
 * @returns ローディング状態（ローディング時true）
 */
export const dataKiLoadingSelector = (state: RootState) => {
  return state.dataKi.loadingState;
};

/**
 * チェックした機種で絞り込んだ平均合計を取得
 */
const dataKiFilteredAverageSelector = (state: RootState) => {
  return state.dataKi.filteredAverage;
};

/**
 * チェックした機種で絞り込んだ平均合計のローディング状態を取得
 */
export const dataKiFilteredAverageLoadingSelector = createSelector(
  dataKiFilteredAverageSelector,
  (data) => data.isLoading
);

/**
 * チェックした機種で絞り込んだ平均合計行のデータを取得
 */
export const dataKiOrderedFilteredAverageSelector = createSelector(
  [
    dataKiFilteredAverageSelector,
    dataKiColumnsOrderSelector,
    dataKiSelectedKiListSelector,
  ],
  ({ data }, ordered, filteredKiList) => {
    // データがない場合、チェックで絞り混んでいない場合はnullを返す
    if (!data || !data.data.rows[0] || !filteredKiList) {
      return null;
    }
    const { columns, rows } = data.data;

    // 並べ替え後の項目数と、テーブルデータの項目数が一致しない場合nullを返す
    if (ordered && columns.length !== ordered.length) {
      return null;
    }

    const firstRow = rows.at(0);

    if (!firstRow) {
      return { columns, rows: [] };
    }

    // 一列目の文字列を加工する
    const updatedRows = [
      {
        ...rows[0],
        data: [
          {
            ...firstRow.data[0],
            value: `チェック機種 平均/合計(${filteredKiList.length}機種)`,
          },
          ...firstRow.data.slice(1),
        ],
      },
    ];

    if (!ordered) {
      return { columns, rows: updatedRows };
    } else {
      return getOrderedTableData(ordered, columns, updatedRows);
    }
  }
);

/**
 * 機種タグで選択されたvalue値を取得
 */
export const dataKiFilteredKiTagAverageTagsValueSelector = createSelector(
  dataKiFilteredKiTagAverageSelector,
  (filteredKiTagAverage) => {
    const sortedValue = sortBy(
      filteredKiTagAverage.value,
      (object) => object.viewOrder
    );
    return sortedValue;
  }
);

/**
 * 機種タグで選択した機種で絞り込んだ平均合計のローディング状態を取得
 */
export const dataKiFilteredKiTagAverageLoadingSelector = createSelector(
  dataKiFilteredKiTagAverageSelector,
  (data) => data.isLoading
);

/**
 * 機種タグで選択した機種で絞り込んだ平均合計行のデータを取得
 */
export const dataKiOrderedFilteredKiTagAverageSelector = createSelector(
  [
    dataKiFilteredKiTagAverageSelector,
    dataKiColumnsOrderSelector,
    defaultDataKiFilteredByTagsDataSelector,
  ],

  ({ data }, ordered, { rows: filteredKiTagRows }) => {
    if (!data || !data.data.rows[0] || !data.setting.kiList) {
      return undefined;
    }

    const { columns, rows } = data.data;

    const firstRow = rows.at(0);

    if (!firstRow) {
      return { ...rows[0], data: [] };
    }

    // 一列目の文字列を加工する
    const updatedRows = [
      {
        ...rows[0],
        data: [
          {
            ...firstRow.data[0],
            value: `タグ絞り込み 平均/合計(${
              filteredKiTagRows.length - 1
            }機種)`,
          },
          ...firstRow.data.slice(1),
        ],
      },
    ];

    if (!ordered) {
      return updatedRows[0];
    } else {
      const reordered = getOrderedTableData(ordered, columns, updatedRows);
      return reordered.rows[0];
    }
  }
);

/**
 * 選択した各機種タグの平均合計行のローディング状態を取得
 */
export const dataKiOnlyOneKiTagAverageLoadingSelector = createSelector(
  dataKiOnlyOneKiTagAverageSelector,
  (data) => data.isLoading
);

/**
 * 選択した各機種タグの平均合計行のデータを取得
 * @deprecated 通常は使用しません。
 * 'src/redux/server/dataKiSortedAverage/dataKiSortedByKiTagListAverageSelector'にて
 * タグ一覧順に並び替えられたデータを取得、使用してください。
 * (dataKi.ts、settingsOptionsKi.ts間の二重参照を避けるため別ファイルに定義しています)
 */
export const dataKiOrderedOnlyOneKiTagAverageSelector = createSelector(
  [dataKiColumnsOrderSelector, dataKiOnlyOneKiTagAverageSelector],
  (ordered, onlyOneKiTagAverage) => {
    const { data } = onlyOneKiTagAverage;
    if (!data) {
      return undefined;
    }

    // data.data.rowsが[]の場合はそこのdataを削除する
    const filteredData = data.filter((item) => item.data.rows.length > 0);

    const kiTagAverageEveryTag = filteredData.map((item) => {
      const firstRow = item.data.rows.at(0);

      if (!firstRow) {
        return {
          ...item.data.rows[0],
          data: [],
          setting: {
            ...item.setting,
          },
        };
      }

      return {
        ...item.data.rows[0],
        data: [
          {
            ...firstRow.data[0],
            value: `平均/合計(${item.setting.kiList?.length}機種)`,
          },
          ...firstRow.data.slice(1),
        ],
        setting: {
          ...item.setting,
        },
      };
    });

    if (!ordered) {
      return kiTagAverageEveryTag;
    } else {
      const columns = filteredData.at(0)?.data?.columns ?? [];
      const reordered = kiTagAverageEveryTag.map((item) => {
        const excludeSettingKiTagAverageEveryTag = [
          { data: item.data, queryParameter: item.queryParameter },
        ];

        const orderedKiTagAverageEveryTag = getOrderedTableData(
          ordered,
          columns,
          excludeSettingKiTagAverageEveryTag
        );
        return {
          data: orderedKiTagAverageEveryTag.rows.at(0)?.data ?? [],
          queryParameter: item.queryParameter,
          setting: item.setting,
        };
      });
      return reordered;
    }
  }
);

/**
 * 機種タグでローディング状態かどうかを取得
 */
export const dataKiTagIsLoadingSelector = createSelector(
  [
    dataKiFilteredKiTagAverageLoadingSelector,
    dataKiOnlyOneKiTagAverageLoadingSelector,
  ],
  (isAvgLoading, isTagLoading) => isAvgLoading || isTagLoading
);

/**
 * レスポンスの検索条件からエリア一覧を取得する
 */
export const dataKiSettingAreasSelector = createSelector(
  dataKiParamsSelector,
  ({ areas }) => areas
);

// Reducer

export function dataKiReducer(
  state = initialState,
  action: DataKiAction
): DataKiState {
  switch (action.type) {
    case FETCH_DATA_KI_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_DATA_KI_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        dataKi: action.payload.dataKi,
      };
    case SHOW_DATA_KI_LOADING:
      return {
        ...state,
        loadingState: action.payload.loadingState,
      };
    case HIDE_DATA_KI_LOADING:
      return {
        ...state,
        loadingState: action.payload.loadingState,
      };
    case SELECT_DATA_KI_COLUMNS_ORDER:
      return {
        ...state,
        columnsOrder: action.payload.columnsOrder,
      };
    case RESET_DATA_KI_COLUMNS_ORDER:
      return {
        ...state,
        columnsOrder: initialState.columnsOrder,
      };
    case SELECT_DATA_KI_KI_LIST:
      return {
        ...state,
        kiList: action.payload.kiList,
      };
    case ADD_DATA_KI_CHECKED_LIST: {
      const checkedKiList = state.checkedKiList ? state.checkedKiList : [];

      // 追加する対象が重複していないかを確認する
      if (
        checkedKiList.some(
          (item) =>
            item.group === action.payload.group &&
            item.name === action.payload.name
        )
      ) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        checkedKiList: [...checkedKiList, action.payload],
      };
    }
    case ADD_DATA_KI_LIST_CHECKED_LIST: {
      const checkedKiList = state.checkedKiList ? state.checkedKiList : [];
      return {
        ...state,
        checkedKiList: [...checkedKiList, ...action.payload],
      };
    }
    case REMOVE_DATA_KI_CHECKED_LIST: {
      const checkedKiList = state.checkedKiList ? state.checkedKiList : [];

      return {
        ...state,
        checkedKiList: checkedKiList.filter(
          (item) =>
            !(
              item.group === action.payload.group &&
              item.name === action.payload.name
            )
        ),
      };
    }
    case REMOVE_DATA_KI_LIST_CHECKED_LIST: {
      const checkedKiList = (state.checkedKiList ?? []).filter(
        (ki) =>
          !action.payload.some(
            ({ group, name }) => group === ki.group && name === ki.name
          )
      );

      return { ...state, checkedKiList };
    }
    case RESET_DATA_KI_CHECKED_LIST:
      return {
        ...state,
        checkedKiList: [],
      };
    case FETCH_DATA_KI_FILTERED_AVERAGE_REQUEST: {
      return {
        ...state,
        filteredAverage: {
          isLoading: true,
          error: null,
          data: null,
        },
      };
    }
    case FETCH_DATA_KI_FILTERED_AVERAGE_SUCCESS: {
      return {
        ...state,
        filteredAverage: {
          isLoading: false,
          error: null,
          data: action.payload.data,
        },
      };
    }
    case RESET_DATA_KI_FILTERED_AVERAGE: {
      return {
        ...state,
        filteredAverage: initialState.filteredAverage,
      };
    }
    case RENEW_DATA_KI: {
      return initialState;
    }
    case SELECT_DATA_KI_FILTERED_KI_TAG_AVERAGE: {
      return {
        ...state,
        filteredKiTagAverage: {
          ...state.filteredKiTagAverage,
          value: action.payload.value,
        },
      };
    }
    case RESET_SELECT_DATA_KI_FILTERED_KI_TAG_AVERAGE: {
      return {
        ...state,
        filteredKiTagAverage: {
          ...state.filteredKiTagAverage,
          value: initialState.filteredKiTagAverage.value,
        },
      };
    }
    case FETCH_DATA_KI_FILTERED_KI_TAG_AVERAGE_REQUEST: {
      return {
        ...state,
        filteredKiTagAverage: {
          ...state.filteredKiTagAverage,
          isLoading: true,
          error: null,
          data: null,
        },
      };
    }
    case FETCH_DATA_KI_FILTERED_KI_TAG_AVERAGE_SUCCESS: {
      return {
        ...state,
        filteredKiTagAverage: {
          ...state.filteredKiTagAverage,
          isLoading: false,
          error: null,
          data: action.payload.data,
        },
      };
    }
    case RESET_DATA_KI_FILTERED_KI_TAG_AVERAGE: {
      return {
        ...state,
        filteredKiTagAverage: initialState.filteredKiTagAverage,
      };
    }
    case FETCH_DATA_KI_ONLY_ONE_KI_TAG_AVERAGE_REQUEST: {
      return {
        ...state,
        onlyOneKiTagAverage: {
          ...state.onlyOneKiTagAverage,
          isLoading: true,
          error: null,
          data: state.onlyOneKiTagAverage.data,
        },
      };
    }
    case REMOVE_DATA_KI_ONLY_ONE_KI_TAG_AVERAGE: {
      const removedDataKiOnlyOneKiTagAverage =
        state.onlyOneKiTagAverage.data?.filter((item) =>
          action.payload.value.some(
            ({ tagId }) => tagId.toString() === item.setting.tagIds?.[0]
          )
        );
      return {
        ...state,
        onlyOneKiTagAverage: {
          ...state.onlyOneKiTagAverage,
          data: removedDataKiOnlyOneKiTagAverage ?? null,
        },
      };
    }
    case FETCH_DATA_KI_ONLY_ONE_KI_TAG_AVERAGE_SUCCESS: {
      return {
        ...state,
        onlyOneKiTagAverage: {
          ...state.onlyOneKiTagAverage,
          isLoading: false,
          error: null,
          data: state.onlyOneKiTagAverage.data
            ? [...state.onlyOneKiTagAverage.data, action.payload.data]
            : [action.payload.data],
        },
      };
    }
    case RESET_DATA_KI_ONLY_ONE_KI_TAG_AVERAGE: {
      return {
        ...state,
        onlyOneKiTagAverage: initialState.onlyOneKiTagAverage,
      };
    }
    default:
      return state;
  }
}
