import { Option } from '../schemas';
import { Category } from '../shu';

/**
 * カテゴリに応じてフィールドをフィルタリングする
 * @param fields フィールドリスト
 * @param category カテゴリ
 * @returns フィルタリングされたフィールドリスト
 */
export const filterFieldsByCategory = (
  fields: Option[],
  category: 'all' | Category
): Option[] => {
  const categories: Record<'all' | Category, string[]> = {
    all: ['all'],
    SHU_CATEGORY_PACHINKO: ['ps', 'p', 'all'],
    SHU_CATEGORY_SLOT: ['ps', 's', 'all'],
  };

  const validShuTypes = categories[category];

  return fields.filter(
    (field) =>
      field.shuType !== undefined && validShuTypes.includes(field.shuType)
  );
};
