import { FavoriteItem } from '../domain/favorites';

/**
 * お気に入りから遷移するURLを戻す
 */
export const favoriteToPath = (favorite: FavoriteItem) => {
  switch (favorite.pageName) {
    case 'チェーン店レポート':
      switch (favorite.pageSetting?.chainStoreReports?.selectedTabId) {
        case 'chainStoreShu':
          return '/report/chain';
        case 'chainStoreKi':
          return '/report/chain/ki';
        default:
          return '/report/chain';
      }
    case '店舗レポート':
      switch (favorite.pageSetting?.hallReports?.selectedTabId) {
        case 'hallReportsShu':
          return '/report/hall';
        case 'hallReportsMks':
          return '/report/hall/mks';
        case 'hallReportsKi':
          return '/report/hall/ki';
        default:
          return '/report/hall';
      }
    case '機種集計':
      return '/report/model';
    case '台別データ一覧':
      return '/report/dai';
    case '導入後推移':
      return '/report/transitionAfterIntroduction';
    case '期間推移':
      return '/report/terminalTransition';
    case '機種別推移':
      return '/report/modelTransition';
    case 'PPMシェア一覧':
      return '/report/ppmShare';
    case '予定進捗推移':
      return '/report/planProgressTransition';
    case 'モード別集計':
      return '/report/mode';
    case 'SIS機種レポート':
      return '/report/sis';
    case '機種別商圏データ':
      return '/report/kiSyoken';
    default:
      throw new Error('ページ設定がされていません');
  }
};
