import { sortNumber } from '../../utils/sort/sortNumber';
import { sortNumberOrString } from '../../utils/sort/sortNumberOrString';
import { sortNumberWithPrefix } from '../../utils/sort/sortNumberWithPrefix';
import { sortString } from '../../utils/sort/sortString';
import { OrderType } from '../schemas';
import { DataSis } from './types';

let sortRows: DataSis['data']['rows'];

export const sortSis = (
  dataSis: DataSis,
  order: OrderType,
  sort: string
): DataSis => {
  if (!dataSis) {
    return dataSis;
  }
  // sortの文字列とdata?.data.columnsのcodeが一致するindexを取得
  const sortIndex = dataSis?.data.columns.findIndex((column) => {
    return column.code === sort;
  });

  // sortの文字列とdata?.data.columnsのcodeが一致するtypeを取得
  const sortType = dataSis?.data.columns.find((column) => {
    return column.code === sort;
  });

  if (sortIndex == null || sortType == null) {
    return dataSis;
  }

  switch (sortType.type) {
    case 'string':
      sortRows = sortString(dataSis.data.rows, sortIndex, order);
      break;
    case 'number':
      sortRows = sortNumber(dataSis.data.rows, sortIndex, order);
      break;
    case 'numberWithPrefix':
      sortRows = sortNumberWithPrefix(dataSis.data.rows, sortIndex, order);
      break;
    case 'numberOrString':
      sortRows = sortNumberOrString(dataSis.data.rows, sortIndex, order);
      break;
    default:
      return dataSis;
  }

  return {
    ...dataSis,
    // rowsをsortedRowsに変更
    data: {
      ...dataSis.data,
      rows: sortRows,
    },
  };
};
