import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { FC, PropsWithChildren } from 'react';
import { Provider } from 'react-redux';
import { ScrollRestoration } from 'react-router-dom';
import {
  Navigate,
  Outlet,
  OutletProps,
  createBrowserRouter,
  useLocation,
} from 'react-router-dom';

import { ErrorContextProvider } from './contexts/ApiErrorContext';
import { AuthProvider } from './contexts/AuthProvider';
import { HeaderContextProvider } from './contexts/HeaderContext';
import { useObserveWindowSize } from './contexts/HeightContext';
import { HeightContextProvider } from './contexts/HeightContext';

import { ErrorCatcher } from './components/atoms/ErrorCatcher/ErrorCatcher';
import { QueryStringHandler } from './components/atoms/QueryStringHandler/QueryStringHandler';
import { GenericLayout } from './components/layouts/GenericLayout';
import { ErrorPage } from './components/pages/ErrorPage/ErrorPage';
import { HashPage } from './components/pages/HashPage';
import { LoadingPage } from './components/pages/LoadingPage';
import { LoginPage } from './components/pages/LoginPage/LoginPage';
import { NotFoundPage } from './components/pages/NotFoundPage';

import { useAuth } from './hooks/useAuth';
import { useGoogleAnalytics } from './hooks/useGoogleAnalytics';
import { useParseUserData } from './hooks/useParseUserData';
import { useRole } from './hooks/useRole';

import { createFinalStore } from './store';
import { theme } from './theme';
import { lazyImportPage } from './utils/lazyImportPage';
import { queryClient } from './utils/queryClient';

const ShuGroupSettingsPage = lazyImportPage(
  () => import('./components/pages/ShuGroupSettingsPage/ShuGroupSettingsPage')
);
const KiTagsSettingsPage = lazyImportPage(
  () => import('./components/pages/KiTagsSettingsPage/KiTagsSettingsPage')
);
const PlanSettingsPage = lazyImportPage(
  () => import('./components/pages/PlanSettingsPage/PlanSettingsPage')
);
const HallSettingsPage = lazyImportPage(
  () => import('./components/pages/HallSettingsPage/HallSettingsPage')
);
const KiLinkSettingsPage = lazyImportPage(
  () => import('./components/pages/KiLinkSettingsPage/KiLinkSettingsPage')
);
const PlanProgressTransitionReportsPage = lazyImportPage(
  () =>
    import(
      './components/pages/PlanProgressTransitionReportsPage/PlanProgressTransitionReportsPage'
    )
);
const TransitionAfterIntroductionPage = lazyImportPage(
  () =>
    import(
      './components/pages/TransitionAfterIntroductionPage/TransitionAfterIntroductionPage'
    )
);
const UnitModelSettingsPage = lazyImportPage(
  () => import('./components/pages/UnitModelSettingsPage/UnitModelSettingsPage')
);
const ExpenseSettingsPage = lazyImportPage(
  () => import('./components/pages/ExpenseSettingsPage/ExpenseSettingsPage')
);
const HallReportsPage = lazyImportPage(
  () => import('./components/pages/HallReportsPage/HallReportsPage')
);
const ModelTransitionPage = lazyImportPage(
  () => import('./components/pages/ModelTransitionPage/ModelTransitionPage')
);
const TerminalTransitionPage = lazyImportPage(
  () =>
    import('./components/pages/TerminalTransitionPage/TerminalTransitionPage')
);
const ModelReportsPage = lazyImportPage(
  () => import('./components/pages/ModelReportsPage/ModelReportsPage')
);
const PpmShareReportsPage = lazyImportPage(
  () => import('./components/pages/PpmShareReportsPage/PpmShareReportsPage')
);
const UserSettingsPage = lazyImportPage(
  () => import('./components/pages/UserSettingsPage/UserSettingsPage')
);
const HomePage = lazyImportPage(
  () => import('./components/pages/HomePage/HomePage')
);
const ChainStoreReportsPage = lazyImportPage(
  () => import('./components/pages/ChainStoreReportsPage/ChainStoreReportsPage')
);
const CommonSettingsPage = lazyImportPage(
  () => import('./components/pages/CommonSettingsPage/CommonSettingsPage')
);
const UnitDataListPage = lazyImportPage(
  () => import('./components/pages/UnitDataListPage/UnitDataListPage')
);

const ModePage = lazyImportPage(
  () => import('./components/pages/ModePage/ModePage')
);

const ModesSettingsPage = lazyImportPage(
  () => import('./components/pages/ModesSettingsPage/ModesSettingsPage')
);

const DataDeletionSettingsPage = lazyImportPage(
  () =>
    import(
      './components/pages/DataDeletionSettingsPage/DataDeletionSettingsPage'
    )
);
const SisPage = lazyImportPage(
  () => import('./components/pages/SisPage/SisPage')
);
const EventsSettingsPage = lazyImportPage(
  () => import('./components/pages/EventsSettingsPage/EventsSettingsPage')
);

const DaiCostsSettingsPage = lazyImportPage(
  () => import('./components/pages/DaiCostsSettingsPage/DaiCostsSettingsPage')
);

const DepreciationPage = lazyImportPage(
  () => import('./components/pages/DepreciationPage/DepreciationPage')
);

const KiSyokenPage = lazyImportPage(
  () => import('./components/pages/KiSyokenPage/KiSyokenPage')
);

const ParseUser: FC<OutletProps> = (props) => {
  useParseUserData();
  return <Outlet {...props} />;
};

const Protected: FC<OutletProps> = (props) => {
  const { isAuthenticated, isLoading, error } = useAuth();

  const { pathname, search } = useLocation();

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!isAuthenticated) {
    return <Navigate to={`/login?from=${pathname}${search}`} />;
  }

  if (error) {
    return <Navigate to="/login" />;
  }

  return <ParseUser {...props} />;
};

const Admin: FC = () => {
  const { isAdmin, isLoading } = useRole();

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!isAdmin) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

const AdminOrMaintenanceUser: FC = () => {
  const { isAdmin, isMaintenanceUser, isLoading } = useRole();

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!isAdmin && !isMaintenanceUser) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const Wrapper = ({ children }: PropsWithChildren) => {
  useObserveWindowSize();
  useGoogleAnalytics();

  return <>{children}</>;
};

const store = createFinalStore();

const RootProviders = () => {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
      <Provider store={store}>
        <ErrorContextProvider>
          <HeaderContextProvider>
            <HeightContextProvider>
              <QueryClientProvider client={queryClient}>
                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <AuthProvider />
                    <ScrollRestoration />
                  </ThemeProvider>
                </StyledEngineProvider>
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </HeightContextProvider>
          </HeaderContextProvider>
        </ErrorContextProvider>
      </Provider>
    </Sentry.ErrorBoundary>
  );
};

export const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <RootProviders />,
    children: [
      {
        element: (
          <Wrapper>
            <ErrorCatcher />
          </Wrapper>
        ),
        children: [
          {
            element: <Protected />,
            children: [
              {
                element: <QueryStringHandler />,
                children: [
                  {
                    element: <GenericLayout />,
                    children: [
                      {
                        index: true,
                        element: <HomePage />,
                      },
                      {
                        path: 'report',
                        children: [
                          {
                            path: 'chain',
                            children: [
                              {
                                index: true,
                                element: <ChainStoreReportsPage />,
                              },
                              {
                                path: 'ki',
                                element: <ChainStoreReportsPage />,
                              },
                            ],
                          },
                          {
                            path: 'hall',
                            children: [
                              {
                                index: true,
                                element: <HallReportsPage />,
                              },
                              {
                                path: 'mks',
                                element: <HallReportsPage />,
                              },
                              {
                                path: 'ki',
                                element: <HallReportsPage />,
                              },
                            ],
                          },
                          {
                            path: 'planProgressTransition',
                            element: <PlanProgressTransitionReportsPage />,
                          },
                          {
                            path: 'ppmShare',
                            element: <PpmShareReportsPage />,
                          },
                          {
                            path: 'depreciation',
                            element: <DepreciationPage />,
                          },
                          {
                            path: 'model',
                            element: <ModelReportsPage />,
                          },
                          {
                            path: 'dai',
                            element: <UnitDataListPage />,
                          },
                          {
                            path: 'transitionAfterIntroduction',
                            element: <TransitionAfterIntroductionPage />,
                          },
                          {
                            path: 'terminalTransition',
                            element: <TerminalTransitionPage />,
                          },
                          {
                            path: 'modelTransition',
                            element: <ModelTransitionPage />,
                          },
                          {
                            path: 'mode',
                            element: <ModePage />,
                          },
                          {
                            path: 'sis',
                            element: <SisPage />,
                          },
                          {
                            path: 'kiSyoken',
                            element: <KiSyokenPage />,
                          },
                        ],
                      },
                      {
                        path: 'settings',
                        children: [
                          {
                            path: 'kiTags',
                            element: <KiTagsSettingsPage />,
                          },
                          {
                            path: 'plan',
                            element: <PlanSettingsPage />,
                          },
                          {
                            path: 'expense',
                            element: <ExpenseSettingsPage />,
                          },
                          {
                            path: 'modes',
                            element: <ModesSettingsPage />,
                          },
                          {
                            path: 'ki-link',
                            element: <KiLinkSettingsPage />,
                          },
                          {
                            path: 'unit-model',
                            element: <UnitModelSettingsPage />,
                          },
                          {
                            path: 'daiCosts',
                            element: <DaiCostsSettingsPage />,
                          },
                          {
                            element: <AdminOrMaintenanceUser />,
                            children: [
                              {
                                path: 'hall',
                                element: <HallSettingsPage />,
                              },
                              {
                                path: 'shu-group',
                                element: <ShuGroupSettingsPage />,
                              },
                              {
                                path: 'dataDeletion',
                                element: <DataDeletionSettingsPage />,
                              },
                              {
                                path: 'commonSetting',
                                element: <CommonSettingsPage />,
                              },
                              {
                                path: 'event',
                                element: <EventsSettingsPage />,
                              },
                            ],
                          },
                          {
                            element: <Admin />,
                            children: [
                              {
                                path: 'user',
                                element: <UserSettingsPage />,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: 'login',
            element: <LoginPage />,
          },
          {
            path: '*',
            element: <NotFoundPage />,
          },
        ],
      },
      {
        //  リリース時のハッシュ値の確認ページのため、メンテナンスモードを無視する
        path: '_hash',
        element: <HashPage />,
      },
    ],
  },
]);
