import { call, fork, put, select, take, takeEvery } from 'redux-saga/effects';

import { FavoriteItem } from '../domain/favorites';
import { KiTagListOptions, LoadingState } from '../domain/schemas';
import { makeSisFavoriteDate } from '../domain/sis/date';
import {
  DataSis,
  GraphSis,
  SettingsOptionsSis,
  SisDateRange,
  SisFavorite,
} from '../domain/sis/types';

import {
  DataSisActionCreators,
  DataSisActionTypes,
  dataColumnsSwappedSelector,
  dataSisDataSelector,
  dataSisLoadingSelector,
} from '../redux/server/dataSis';
import {
  DataSisGraphActionCreators,
  dataSisGraphSelectedKiListSelector,
  dataSisGraphSettingSelector,
} from '../redux/server/dataSisGraph';
import {
  SettingsOptionsSisActionCreators,
  SettingsOptionsSisActionTypes,
  settingsOptionsSisFieldsSelector,
  settingsOptionsSisLoadingStateSelector,
  settingsOptionsSisSearchConditionSelector,
} from '../redux/server/settingsOptionsSis';
import { ShortenedUrlActionCreators } from '../redux/server/shortenedUrl';
import { SettingsFavoritesActionCreators } from '../redux/ui/settingsFavorites';
import {
  ChangeSisDateTypeAction,
  ChangeSisFavoriteAction,
  CreateSisShortenedUrlAction,
  SaveAsSisFavoriteAction,
  SaveSisFavoriteAction,
  SearchSisAction,
  SearchSisFieldAction,
  SisSettingState,
  SisSettingsActionCreators,
  SisSettingsActionTypes,
  TriggerSwapFieldsAction,
  sisColumnsOrderSelector,
  sisFieldsFilterSelector,
  sisKiListFilterSelector,
  sisKiTagListSelector,
  sisNameFilterSelector,
  sisSearchParamsSelector,
  sisSelectCheckedKiListSelector,
  sisSelectGraphNumberLabelSelector,
  sisSelectedDateRangeSelector,
  sisSelectedFavoriteDataSelector,
  sisSelectedFavoritePageSettingSelector,
  sisSelectedFavoriteSelector,
  sisShowKiTagLabelSelector,
  sisSubMenuNumberFilterSelector,
} from '../redux/ui/sisSettings';
import { compressToEncodedURIComponent } from '../utils/compressToEncodedURIComponent';
import { recalcColumnOrder } from '../utils/orderedCell';

/**
 * 初回データ取得
 */
function* initFetchSisDataSaga() {
  const loadingStateSis: LoadingState = yield select(dataSisLoadingSelector);

  if (loadingStateSis !== 'prepare') {
    return;
  }

  yield put(DataSisActionCreators.fetchDataSisAction());

  // 初回のレスポンスを初期値として設定する
  yield take(DataSisActionTypes.FETCH_DATA_SIS_SUCCESS);
  const dataSis: DataSis = yield select(dataSisDataSelector);
  yield put(SisSettingsActionCreators.selectSisFormCondition(dataSis.setting));
}

/**
 * 検索後のクリア処理
 */
function* clearDataSisSaga() {
  yield put(SisSettingsActionCreators.clearSisKiListFilterAction());
  yield put(DataSisGraphActionCreators.renewDataSisGraphAction());
}

/**
 * SisSettingsとGraphのデータを破棄
 */
function* clearAllDataSisSaga() {
  yield put(SisSettingsActionCreators.clearAllSisAction());
  yield put(DataSisGraphActionCreators.renewDataSisGraphAction());
}

/**
 * SIS機種レポートのリセットボタン押下
 */
function* searchResetSisSaga() {
  const favoriteId: number | undefined = yield select(
    sisSelectedFavoriteSelector
  );

  // お気に入りが選択されていない場合はデフォルト値でリクエストする
  if (favoriteId == null) {
    yield put(DataSisActionCreators.renewDataSis());
    yield fork(clearAllDataSisSaga);
    yield fork(initFetchSisDataSaga);
    return;
  }

  yield put(SisSettingsActionCreators.changeSisFavoriteAction(favoriteId));
}

/**
 * 変更後の検索フォームでデータを取得する
 */
function* searchDataSisSaga(action: SearchSisAction) {
  const searchParams: SisSettingState['searchParams'] = yield select(
    sisSearchParamsSelector
  );

  yield fork(clearDataSisSaga);

  yield put(
    DataSisActionCreators.fetchDataSisAction({
      ...(searchParams ? searchParams : {}),
      sisStartDate: action.payload.sisStartDate,
      sisDateRangeType: action.payload.sisDateRangeType,
    })
  );
}

/**
 * 変更後の種別でデータを取得する
 */
function* changeSisDateTypeSaga(action: ChangeSisDateTypeAction) {
  const searchParams: SisSettingState['searchParams'] = yield select(
    sisSearchParamsSelector
  );

  yield fork(clearDataSisSaga);

  yield put(
    DataSisActionCreators.fetchDataSisAction({
      ...(searchParams ? searchParams : {}),
      sisDataType: action.payload.sisDataType,
    })
  );
}

/**
 * 変更後の表示項目でデータを取得する
 */
function* searchFieldsSaga(action: SearchSisFieldAction) {
  const fields = action.payload.fields.map((item) => item.code);

  const searchParams: SisSettingState['searchParams'] = yield select(
    sisSearchParamsSelector
  );
  yield put(DataSisGraphActionCreators.renewDataSisGraphAction());

  // 表示項目の変更
  yield put(SisSettingsActionCreators.selectSisFieldsAction(fields));

  yield put(
    DataSisActionCreators.fetchDataSisAction({
      ...(searchParams ? searchParams : {}),
      fields,
    })
  );

  // 新規追加された表示項目は一律前に追加する
  const sisData: DataSis | undefined = yield select(dataColumnsSwappedSelector);
  if (sisData == null) {
    throw new Error('DataSis is not found');
  }
  const columnsOrder = sisData.data.columns
    .map((column) => column.code)
    .filter((code) => code != null) as string[];
  const difference =
    fields?.filter((field) => !columnsOrder.includes(field)) ?? [];

  // fieldsはユーザーの選択順になっているため、表示順に並び替える
  const allFields: SettingsOptionsSis['fields']['ki'] | undefined =
    yield select(settingsOptionsSisFieldsSelector);
  if (allFields == null) {
    throw new Error('settingsOptions is not found');
  }
  const desiredOrder = allFields.map(({ code }) => code);
  const sortedDifference = [...difference].sort((a, b) => {
    if (desiredOrder == null) {
      return 0;
    }
    return desiredOrder.indexOf(a) < desiredOrder.indexOf(b) ? -1 : 1;
  });

  const [kiTushoMei, ...rest] = columnsOrder;
  //fieldsが[]の時にデフォルトの表示項目が表示されるよう、空の配列を入れる
  const resultOrder = fields.length
    ? [kiTushoMei, ...sortedDifference, ...rest]
    : [];

  yield put(SisSettingsActionCreators.selectSisColumnsOrderAction(resultOrder));

  yield take(DataSisActionTypes.FETCH_DATA_SIS_SUCCESS);

  const dataSis: DataSis = yield select(dataSisDataSelector);
  if (
    dataSis.setting.sort !== undefined &&
    dataSis.setting.order !== undefined
  ) {
    // ソートラベルの状態などフロントでソートしている関係上UI側の設定を見ているのでUIの情報を更新
    yield put(
      SisSettingsActionCreators.triggerSisSortAction(
        dataSis.setting.sort,
        dataSis.setting.order
      )
    );
  }
}

function* triggerSisSwapFieldsSaga(action: TriggerSwapFieldsAction) {
  const { draggedId, droppedId } = action.payload;

  const sisData: DataSis | undefined = yield select(dataSisDataSelector);
  if (sisData == null) {
    return;
  }

  const currentOrder: string[] = yield select(sisColumnsOrderSelector);

  const ordered = recalcColumnOrder(
    currentOrder.length === 0
      ? sisData.data.columns
      : currentOrder.map((code) => ({ code })),
    draggedId,
    droppedId
  );

  yield put(SisSettingsActionCreators.selectSisColumnsOrderAction(ordered));
}

/**
 * お気に入りに保存するデータの生成
 */
function* setPageSetting() {
  // 選択されている検索条件
  const searchParams: DataSis['setting'] | undefined = yield select(
    sisSearchParamsSelector
  );

  // サブメニュー内数値フィルタ
  const subMenuNumberFilter: {
    [field: string]: {
      minimumNumber: number | undefined;
      maximumNumber: number | undefined;
    };
  } = yield select(sisSubMenuNumberFilterSelector);

  // 機種名フィルタ
  const nameFilter: string = yield select(sisNameFilterSelector);

  // テーブルの非表示項目一覧
  const fieldsFilter: string[] = yield select(sisFieldsFilterSelector);

  // 機種絞り込み
  const kiListFilter: string[] = yield select(sisKiListFilterSelector);

  // 表示項目の列順
  const columnsOrder: string[] = yield select(sisColumnsOrderSelector);

  // 選択している期間のタイプ
  const selectedDateRange: SisDateRange = yield select(
    sisSelectedDateRangeSelector
  );

  // タグ絞込で選択されているタグリスト
  const sisKiTagList: KiTagListOptions[] = yield select(sisKiTagListSelector);

  // 機種行への機種タグ表示機能フラグ
  const sisShowKiTagLabel: boolean = yield select(sisShowKiTagLabelSelector);

  // テーブルのチェックボックスで選択されている機種
  const checkedKiList: {
    dataType: string;
    ki: string;
  }[] = yield select(sisSelectCheckedKiListSelector);

  // 推移グラフの検索条件
  const sisGraphSearchParams: GraphSis['setting'] = yield select(
    dataSisGraphSettingSelector
  );

  // 推移グラフで選択中の機種
  const sisGraphSelectKiList: string[] = yield select(
    dataSisGraphSelectedKiListSelector
  );

  // 推移グラフの数値表示
  const showGraphNumberLabel: boolean = yield select(
    sisSelectGraphNumberLabelSelector
  );

  const result: FavoriteItem['pageSetting'] = {
    sis: {
      searchParams,
      subMenuNumberFilter,
      nameFilter,
      fieldsFilter,
      kiListFilter,
      columnsOrder,
      selectedDateRange,
      sisKiTagList,
      sisShowKiTagLabel,
      checkedKiList,
      dataSisGraph: {
        searchParams: sisGraphSearchParams,
        showGraphNumberLabel,
        selectKi: sisGraphSelectKiList,
      },
    },
  };

  return result;
}

/**
 * お気に入り選択時、検索条件に反映する
 */
function* applyFavoriteByIdSaga(action: ChangeSisFavoriteAction) {
  const favoriteItem: FavoriteItem | undefined = yield select(
    sisSelectedFavoriteDataSelector
  );
  const selectedFavoriteId = action.payload.favoriteId;

  // デフォルトのお気に入りを選択した場合、初回取得と同様の動作を行う
  if (selectedFavoriteId == null || favoriteItem == null) {
    yield put(DataSisActionCreators.renewDataSis());
    yield fork(clearAllDataSisSaga);
    yield put(SisSettingsActionCreators.initSisAction());
    return;
  }

  yield put(DataSisGraphActionCreators.renewDataSisGraphAction());

  const favorite: SisFavorite | undefined = yield select(
    sisSelectedFavoritePageSettingSelector
  );

  if (favorite == null) {
    throw new Error('該当するお気に入りが見つかりません');
  }

  const lastUpdateAt = favoriteItem.updatedAt ?? favoriteItem.createdAt;

  if (lastUpdateAt == null) {
    throw new Error('お気に入りの作成日が存在しません');
  }

  yield fork(applySisFavoriteSaga, favorite);
}

/**
 * SisFavoriteの内容を実際に反映させる
 */
export function* applySisFavoriteSaga(favorite: SisFavorite) {
  const loadingState: LoadingState = yield select(
    settingsOptionsSisLoadingStateSelector
  );

  // settingsOptionsが取得されていない場合は、取得する
  if (loadingState === 'prepare') {
    yield put(SettingsOptionsSisActionCreators.fetchSettingsOptionsSisAction());

    yield take(
      SettingsOptionsSisActionTypes.FETCH_SETTINGS_OPTIONS_SIS_SUCCESS
    );
  }

  const searchCondition: SettingsOptionsSis['searchCondition'] = yield select(
    settingsOptionsSisSearchConditionSelector
  );

  // 検索条件の期間部分を再計算
  const newSisStartDate = makeSisFavoriteDate(
    searchCondition,
    favorite.selectedDateRange,
    favorite.searchParams?.sisStartDate
  );

  // 検索条件の反映
  yield put(
    SisSettingsActionCreators.selectSisFormCondition({
      ...favorite.searchParams,
      sisStartDate: newSisStartDate,
    })
  );

  // サブメニュー内数値フィルタの反映
  yield put(
    SisSettingsActionCreators.selectSisSubMenuNumberFilterAction(
      favorite.subMenuNumberFilter ?? {}
    )
  );

  // 機種名フィルタの反映
  yield put(
    SisSettingsActionCreators.selectSisNameFilterAction(
      favorite.nameFilter ?? ''
    )
  );

  // 非表示項目の反映
  yield put(
    SisSettingsActionCreators.applySisTableFilterAction(
      favorite.fieldsFilter ?? []
    )
  );

  // 機種絞り込みの反映
  yield put(
    SisSettingsActionCreators.selectSisKiListFilterAction(
      favorite.kiListFilter ?? []
    )
  );

  // 表示項目の列順の反映
  yield put(
    SisSettingsActionCreators.selectSisColumnsOrderAction(
      favorite.columnsOrder ?? []
    )
  );

  // テーブルのチェックボックスで選択されている機種の反映
  yield put(
    SisSettingsActionCreators.selectSisFavoriteCheckedAllKiListAction(
      favorite.checkedKiList ?? []
    )
  );

  // SisDateSelectで選択している期間のタイプの反映
  yield put(
    SisSettingsActionCreators.selectSisDateSelectAction(
      favorite.selectedDateRange
    )
  );

  // タグ絞込で選択されているタグリストの反映
  yield put(
    SisSettingsActionCreators.selectSisKiTagListAction(
      favorite.sisKiTagList ?? []
    )
  );

  // 機種行への機種タグ表示機能フラグの反映
  yield put(
    SisSettingsActionCreators.selectSisShowKiTagLabelAction(
      favorite.sisShowKiTagLabel ?? false
    )
  );

  // テーブルのデータ取得
  yield put(
    DataSisActionCreators.fetchDataSisAction({
      ...favorite.searchParams,
      sisStartDate: newSisStartDate,
    })
  );

  // 推移グラフの検索条件の反映
  if (favorite.dataSisGraph?.searchParams != null) {
    yield put(
      DataSisGraphActionCreators.fetchDataSisGraphAction(
        favorite.dataSisGraph.searchParams
      )
    );
    yield put(
      DataSisGraphActionCreators.changeDataSisGraphKiListAction(
        favorite.dataSisGraph.selectKi
      )
    );
    yield put(
      SisSettingsActionCreators.selectSisGraphNumberLabelAction(
        favorite.dataSisGraph.showGraphNumberLabel
      )
    );
  }
}

/**
 * お気に入り新規保存時の処理
 */
function* saveAsFavoriteSaga(action: SaveAsSisFavoriteAction) {
  const pageSetting: FavoriteItem['pageSetting'] = yield call(setPageSetting);

  const favorite: FavoriteItem = {
    name: action.payload.name,
    isShared: action.payload.isShared,
    pageName: 'SIS機種レポート',
    pageSetting,
    memo: action.payload.memo,
    privatelySharedUsers: action.payload.sharedUser,
  };

  yield put(
    SettingsFavoritesActionCreators.postSettingsFavoritesAction([favorite], {})
  );
}

/**
 * お気に入り上書き保存時の処理
 */
function* saveFavoriteSaga(action: SaveSisFavoriteAction) {
  const selectedFavoriteId: number | undefined = yield select(
    sisSelectedFavoriteSelector
  );
  const selectedFavorite: FavoriteItem | undefined = yield select(
    sisSelectedFavoriteDataSelector
  );

  const pageSetting: FavoriteItem['pageSetting'] = yield call(setPageSetting);

  let favorite: FavoriteItem = {};

  // デフォルトの時は動作しない
  if (selectedFavoriteId == null) return;

  if (action.payload.mode === 'memo') {
    // メモのみ更新モードでの変更後のお気に入りデータ
    favorite = {
      ...selectedFavorite,
      memo: action.payload.memo,
    };
  } else {
    // 変更後のお気に入りデータ
    favorite = {
      name: action.payload.name,
      isShared: action.payload.isShared,
      pageName: 'SIS機種レポート',
      pageSetting,
      memo: action.payload.memo,
      privatelySharedUsers: action.payload.sharedUser,
    };
  }

  yield put(
    SettingsFavoritesActionCreators.patchSettingsFavoritesAction(
      selectedFavoriteId,
      favorite
    )
  );
}

/**
 * 画面共有用短縮URL作成の処理
 */
function* createShortenedUrlSaga(action: CreateSisShortenedUrlAction) {
  const pageSetting: FavoriteItem['pageSetting'] = yield call(setPageSetting);

  const compressed = compressToEncodedURIComponent(
    action.payload.pageName,
    pageSetting ?? {}
  );
  const originalUrl = `${action.payload.locationUrl}?q=${compressed}`;

  yield put(ShortenedUrlActionCreators.postShortenedUrlAction({ originalUrl }));
}

/**
 * お気に入り選択中または未選択時のデフォルト値にする
 */
function* handleSearchResetSaga() {
  yield takeEvery(SisSettingsActionTypes.SEARCH_RESET_SIS, searchResetSisSaga);
}

/**
 * 検索フォーム変更時、データを取得する
 */
function* handleChangeSearchShuSaga() {
  yield takeEvery(SisSettingsActionTypes.SEARCH_SIS, searchDataSisSaga);
}

/**
 * 種別変更時、データを取得する
 */
function* handleChangeSisDataTypeSaga() {
  yield takeEvery(
    SisSettingsActionTypes.CHANGE_SIS_DATA_TYPE,
    changeSisDateTypeSaga
  );
}

/**
 * 表示項目の変更
 */
function* handleChangeFieldsSaga() {
  yield takeEvery(SisSettingsActionTypes.SEARCH_SIS_FIELDS, searchFieldsSaga);
}

/**
 * 初回表示時のデータ取得
 */
function* handleInitFetchSaga() {
  yield takeEvery(SisSettingsActionTypes.INIT_SIS, initFetchSisDataSaga);
}

/**
 * 表示項目並び順の変更
 */
function* handleFieldOrderSaga() {
  yield takeEvery(
    SisSettingsActionTypes.TRIGGER_SIS_SWAP_FIELDS,
    triggerSisSwapFieldsSaga
  );
}

/**
 * お気に入り関連のタスクを実行する
 */
function* handleFavoriteSaga() {
  yield takeEvery(
    SisSettingsActionTypes.CHANGE_SIS_FAVORITE,
    applyFavoriteByIdSaga
  );
  yield takeEvery(
    SisSettingsActionTypes.SAVE_AS_SIS_FAVORITE,
    saveAsFavoriteSaga
  );
  yield takeEvery(SisSettingsActionTypes.SAVE_SIS_FAVORITE, saveFavoriteSaga);
}

// 画面共有用短縮URL作成の処理
function* handleShortenedUrlSaga() {
  yield takeEvery(
    SisSettingsActionTypes.CREATE_SIS_SHORTENED_URL,
    createShortenedUrlSaga
  );
}

export function* sisSettingSaga() {
  yield fork(handleInitFetchSaga);
  yield fork(handleSearchResetSaga);
  yield fork(handleChangeSearchShuSaga);
  yield fork(handleChangeSisDataTypeSaga);
  yield fork(handleChangeFieldsSaga);
  yield fork(handleFieldOrderSaga);
  yield fork(handleFavoriteSaga);
  yield fork(handleShortenedUrlSaga);
}
