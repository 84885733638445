// APIを繋ぎ込む際に使用するのでコメントアウトしています
// import { AxiosResponse } from 'axios';
// import { RagEvaluationApiResponse } from '../domain/rag/types';
// import { call, fork, put, takeEvery } from 'redux-saga/effects';
// import { Api, buildConfig } from '../utils/api';
import { fork, put, takeEvery } from 'redux-saga/effects';

import {
  type PostRagEvaluationRagAction,
  RagEvaluationActionTypes,
  RagEvaluationActions,
} from '../redux/ui/dataRagEvaluation';
import { Api } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

/**
 * 評価用APIのリクエストを送信するSaga
 * @param api
 * @param action
 */
function* postRagEvaluationSaga(api: Api, action: PostRagEvaluationRagAction) {
  try {
    const { evaluation } = action.payload;

    // 評価をしたチャットの情報をstoreに保存する存
    yield put(
      RagEvaluationActions.postRagEvaluationRagRequestAction(evaluation)
    );

    // レスポンスのテストのために1秒待機、API繋ぎ込み時には削除
    yield new Promise((resolve) => setTimeout(resolve, 1000));

    // APIリクエスト
    // const response: AxiosResponse<RagEvaluationApiResponse> = yield call(api.post, `/rag/evaluation`,evaluation, buildConfig());

    // 成功時に該当の評価情報を更新しにいく
    yield put(
      RagEvaluationActions.postRagEvaluationRagSuccessAction(evaluation)
    );
  } catch (error: unknown) {
    const { evaluation } = action.payload;
    yield put(
      RagEvaluationActions.postRagEvaluationRagFailureAction(evaluation)
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handlePostRagEvaluationSaga(api: Api) {
  yield takeEvery(
    RagEvaluationActionTypes.POST_EVALUATION_RAG,
    postRagEvaluationSaga,
    api
  );
}

export function* ragEvaluationSagas(context: { api: Api }) {
  yield fork(handlePostRagEvaluationSaga, context.api);
}
