import { removeSpecialString } from '../../utils/string';
import { Column, ColumnForDataTable } from '../schemas';
import { DataKiSyokenResponse } from './types';

type SubMenuNumberFilter = {
  [field: string]: {
    minimumNumber: number | undefined;
    maximumNumber: number | undefined;
  };
};

type Columns =
  | Column[]
  | {
      name: string;
      isSisField: boolean | null;
      code: string | null;
      type: 'string' | 'number' | 'numberWithPrefix' | 'numberOrString' | null;
      backgroundColor: string | null;
      color: string | null;
      description: string | null;
    }[];

/**
 * 指定されたフィルタ条件に基づいて行データをフィルタリングします。
 *
 * @template T - DataKiSyokenResponse の 'data' プロパティの 'rows' 型を拡張する型。
 *
 * @param {SubMenuNumberFilter} subMenuNumberFilter - フィルタ条件を含むオブジェクト。
 * @param {Columns | ColumnForDataTable[]} columns - 列情報の配列。ネストされた列も展開されます。
 * @param {T} rows - フィルタリング対象の行データ。
 *
 * @returns {{ filteredRows: T }} フィルタリングされた行データを含むオブジェクト。
 *
 * @remarks
 * この関数は、指定されたフィルタ条件に基づいて行データをフィルタリングします。フィルタ条件には、最大値および最小値が含まれます。
 * 'KiSyoken' が含まれる列に対して特別な処理が行われます。列情報がネストされている場合、展開して処理されます。
 * 数値の{0}は{-(ハイフン)}としてレスポンスから返却されているため、フィルタリングする際は-を0に変換してから比較します。
 */
export const KiSyokenConvertRowsToFilteredRows = <
  T extends DataKiSyokenResponse['data']['rows']
>(
  subMenuNumberFilter: SubMenuNumberFilter,
  columns: Columns | ColumnForDataTable[],
  rows: T
) => {
  const subMenuNumberFilterProperty = Object.keys(subMenuNumberFilter);

  //モード別台数＆モード別シェアなどcolumnsの中にcolumnsがある場合は展開する
  const expandColumns = columns.flatMap((column) => {
    if ('columns' in column) {
      return [...(column.columns ?? [])] as Columns;
    }
    return [column];
  });

  /**
   * フィルタしている項目のIndex番号を全て抜き出したもの
   */
  const columnIndexNumbers = expandColumns
    .map((column, i) =>
      subMenuNumberFilterProperty.includes(column.code ?? '') ? i : ''
    )
    .filter(String);

  let filteredRows = [...rows];

  /**
   * 共通のフィルタリング処理
   * @param columnIndex 列のインデックス
   * @param isKiSyoken KiSyoken列かどうか
   * @param minValue 最小値
   * @param maxValue 最大値
   * @returns フィルタリングされた行データ
   */
  const filterRowsByNumberRange = (
    columnIndex: number,
    isKiSyoken: boolean,
    // 最小値と最大値が指定されていない場合はundefined
    minValue: number | undefined,
    maxValue: number | undefined
  ) => {
    /**
     * filterdRowsの各行に対してフィルタリングを実行
     * 最小値と最大値の条件を満たす行のみを残す
     */
    return filteredRows.filter((row) => {
      // 平均/合計の行は常に含める（フィルタリング対象外なのでreturnする）
      if (row.data.at(0)?.value === '平均/合計') {
        return true;
      }

      // データがない場合は除外
      if (row.data.length <= 0) {
        return false;
      }

      // セルの値を取得
      const cellValue = row.data.at(columnIndex)?.value;

      // セルの値がない場合は除外
      if (cellValue === undefined) {
        return false;
      }

      // KiSyoken列の場合、'-'を'0'に変換（数値の0は'-'として表示されているため）
      const normalizedValue = isKiSyoken && cellValue === '-' ? '0' : cellValue;

      // 文字列から数値に変換（カンマや単位などの特殊文字を除去）
      const numValue = Number(removeSpecialString(normalizedValue));

      /**
       * 最小値と最大値の条件チェック
       * 各valueがそれぞれundefinedの場合（フィルター対象ではない）は条件を満たすとみなす
       * 各valueが存在している場合は、最小値以上、最大値以下の場合のみtrue
       */
      const passesMinFilter = minValue === undefined || numValue >= minValue;
      const passesMaxFilter = maxValue === undefined || numValue <= maxValue;

      // 両方の条件を満たす場合のみtrue
      return passesMinFilter && passesMaxFilter;
    });
  };

  // 各フィルタ条件に対して処理を実行
  for (let i = 0; i < columnIndexNumbers.length; i++) {
    const code = expandColumns[Number(columnIndexNumbers[i])].code;

    if (code == null) {
      continue; // コードが存在しない場合はスキップ
    }
    // フィルタ条件を取得
    const target = subMenuNumberFilter[code];
    const columnIndex = Number(columnIndexNumbers.at(i));
    // KiSyoken列かどうかを判定
    const isKiSyoken = code.includes('KiSyoken');
    // 最小値、最大値を取得、存在する場合は数値に変換
    const minValue =
      target.minimumNumber !== undefined
        ? Number(target.minimumNumber)
        : undefined;
    const maxValue =
      target.maximumNumber !== undefined
        ? Number(target.maximumNumber)
        : undefined;

    // フィルタ条件が指定されている場合のみフィルタリングを実行
    if (minValue !== undefined || maxValue !== undefined) {
      filteredRows = filterRowsByNumberRange(
        columnIndex,
        isKiSyoken,
        minValue,
        maxValue
      );
    }
  }

  return {
    filteredRows: filteredRows,
  };
};
