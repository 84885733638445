import { createSelector } from 'reselect';

import { LoadingState } from '../../domain/schemas';
import { dataFilteredByKiTagList } from '../../domain/sis/dataFilteredByKTagiList';
import { dataFilteredByKiList } from '../../domain/sis/dataFilteredByKiList';
import { dataFilteredByName } from '../../domain/sis/dataFilteredByName';
import { dataFilteredByNumber } from '../../domain/sis/dataFilteredByNumber';
import { dataHiddenByField } from '../../domain/sis/dataHiddenByField';
import { dataSwapColumnsOrder } from '../../domain/sis/dataSwapColumnsOrder';
import { dateRangeTypeToSisDateUnit, makeSisDate } from '../../domain/sis/date';
import { sortSis } from '../../domain/sis/sortSis';
import { DataSis } from '../../domain/sis/types';

import { RootState } from '../../store';
import {
  sisColumnsOrderSelector,
  sisFieldsFilterSelector,
  sisKiListFilterSelector,
  sisKiTagListSelector,
  sisNameFilterSelector,
  sisOrderSelector,
  sisSortSelector,
  sisSubMenuNumberFilterSelector,
} from '../ui/sisSettings';
import { settingsOptionsSisSelector } from './settingsOptionsSis';

/* ---------------------------------------------------------------
 * Action Types
 */

const FETCH_DATA_SIS = 'FETCH_DATA_SIS' as const;
const FETCH_DATA_SIS_REQUEST = 'FETCH_DATA_SIS_REQUEST' as const;
const FETCH_DATA_SIS_SUCCESS = 'FETCH_DATA_SIS_SUCCESS' as const;
const RENEW_DATA_SIS = 'RENEW_DATA_SIS' as const;

export const DataSisActionTypes = {
  FETCH_DATA_SIS,
  FETCH_DATA_SIS_REQUEST,
  FETCH_DATA_SIS_SUCCESS,
  RENEW_DATA_SIS,
};

/* ---------------------------------------------------------------
 * Action Creators
 */

function fetchDataSisAction(params?: DataSis['setting']) {
  return {
    type: FETCH_DATA_SIS,
    payload: { params },
  };
}

function fetchDataSisRequestAction() {
  return {
    type: FETCH_DATA_SIS_REQUEST,
  };
}

function fetchDataSisSuccessAction(dataSis: DataSis) {
  return {
    type: FETCH_DATA_SIS_SUCCESS,
    payload: { dataSis },
  };
}

function renewDataSis() {
  return {
    type: RENEW_DATA_SIS,
  };
}

export const DataSisActionCreators = {
  fetchDataSisAction,
  fetchDataSisRequestAction,
  fetchDataSisSuccessAction,
  renewDataSis,
};

/* ---------------------------------------------------------------
 * Actions
 */

export type FetchDataSisAction = ReturnType<typeof fetchDataSisAction>;
type FetchDataSisRequestAction = ReturnType<typeof fetchDataSisRequestAction>;
type FetchDataSisSuccessAction = ReturnType<typeof fetchDataSisSuccessAction>;
type RenewDataSisAction = ReturnType<typeof renewDataSis>;

type DataSisAction =
  | FetchDataSisAction
  | FetchDataSisRequestAction
  | FetchDataSisSuccessAction
  | RenewDataSisAction;

/* ---------------------------------------------------------------
 * State
 */

type DataSisState = {
  loadingState: LoadingState;
  dataSis: DataSis | undefined;
};

const initialState: DataSisState = {
  loadingState: 'prepare',
  dataSis: undefined,
};

/**
 * [SIS機種レポート] 全てのデータ
 */
const dataSisSelector = (state: RootState) => state.dataSis;

/**
 * [SIS機種レポート] レスポンス
 */
export const dataSisDataSelector = createSelector(
  dataSisSelector,
  ({ dataSis }) => dataSis
);

/**
 * [SIS機種レポート] レスポンスのローディング状態
 */
export const dataSisLoadingSelector = createSelector(
  dataSisSelector,
  ({ loadingState }) => loadingState
);

/**
 * [SIS機種レポート] テーブルの行データを取得する
 */
const dataSisRowsSelector = createSelector(
  dataSisSelector,
  (data) => data.dataSis?.data.rows
);

/**
 * [SIS機種レポート] codeとnameをcodeをkeyとした形で取得する
 */
export const dataSisKiOptionsSelector = createSelector(
  dataSisRowsSelector,
  (data) => {
    const kiOptions =
      data?.map((row) => {
        return [row.queryParameter.kiList?.at(0), row.data.at(0)?.value];
      }) ?? [];

    return Object.fromEntries(kiOptions) as { [key: string]: string };
  }
);

/**
 * [SIS機種レポート] ソート処理によって整形されたデータ (ソートラベル・サブメニュー時)
 *
 */
const dataSisSortedSelector = createSelector(
  [dataSisDataSelector, sisSortSelector, sisOrderSelector],
  (dataSisData, sisSort, sisOrder) => {
    if (sisSort == null || sisOrder == null || dataSisData == null) {
      return dataSisData;
    }
    const sortedDataSis = sortSis(dataSisData, sisOrder, sisSort);
    return sortedDataSis;
  }
);

/**
 * [SIS機種レポート] フィルターによって行がフィルタリングされたデータ
 */
const dataRowsFilteredSelector = createSelector(
  [
    dataSisSortedSelector,
    sisNameFilterSelector,
    sisSubMenuNumberFilterSelector,
    sisKiListFilterSelector,
    sisKiTagListSelector,
  ],
  (dataSis, nameFilter, numberFilter, kiListFilter, kiTagList) => {
    if (dataSis == null) {
      return dataSis;
    }

    const filteredByName = dataFilteredByName(dataSis, nameFilter);
    const filteredByNameAndNumber = dataFilteredByNumber(
      filteredByName,
      numberFilter
    );
    const filteredByNameAndNumberAndKiList = dataFilteredByKiList(
      filteredByNameAndNumber,
      kiListFilter
    );

    const filteredByKiTagList = dataFilteredByKiTagList(
      filteredByNameAndNumberAndKiList,
      kiTagList
    );

    return filteredByKiTagList;
  }
);

/**
 * [SIS機種レポート] 通常は使用しません dataResultSelector を使用してください
 *
 * 表示項目の新規項目追加時に、columnsOrderの更新が必要になりますが
 * 一度もDnDをしていない場合にはcolumnsOrderが存在していないため実テーブルのデータを使用します
 * そのため通常は使用しません。最終的に表示させたいデータを取得したい場合にはdataResultSelectorを使用してください
 */
export const dataColumnsSwappedSelector = createSelector(
  [dataRowsFilteredSelector, sisColumnsOrderSelector],
  (filteredDataSis, columnsOrder) => {
    if (filteredDataSis == null) {
      return filteredDataSis;
    }

    const swappedFieldsOrder = dataSwapColumnsOrder(
      filteredDataSis,
      columnsOrder
    );

    return swappedFieldsOrder;
  }
);

/**
 * [SIS機種レポート] フィルターやスワップ、列非表示によって整形されたデータ
 */
export const dataResultSelector = createSelector(
  [dataColumnsSwappedSelector, sisFieldsFilterSelector],
  (swappedDataSis, fieldsFilter) => {
    if (swappedDataSis == null) {
      return swappedDataSis;
    }

    const hiddenByField = dataHiddenByField(swappedDataSis, fieldsFilter);

    return hiddenByField;
  }
);

/**
 * [SIS機種レポート] 日付関連データ、選択日付と選択限界日付の取得
 */
export const dataSisDateSelector = createSelector(
  [dataSisDataSelector, settingsOptionsSisSelector],
  (sisData, settingsOptions) => {
    const dateUnit = dateRangeTypeToSisDateUnit(
      sisData?.setting.sisDateRangeType
    );

    if (
      sisData?.setting.sisStartDate == null ||
      dateUnit == null ||
      settingsOptions == null
    ) {
      return undefined;
    }

    return makeSisDate(
      sisData.setting.sisStartDate,
      dateUnit,
      settingsOptions.searchCondition
    );
  }
);

/* ---------------------------------------------------------------
 * Reducer
 */

export function dataSisReducer(
  state = initialState,
  action: DataSisAction
): DataSisState {
  switch (action.type) {
    case FETCH_DATA_SIS_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_DATA_SIS_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        dataSis: action.payload.dataSis,
      };
    case RENEW_DATA_SIS: {
      return initialState;
    }
    default:
      return state;
  }
}
