import { fork, put, select, take, takeEvery } from 'redux-saga/effects';

import { LoadingState } from '../domain/schemas';
import { CreateShortenedUrlResponse } from '../domain/shortenedUrl';

import { DataChainStoreKiActionCreators } from '../redux/server/dataChainStoreKi';
import { DataChainStoreShuActionCreators } from '../redux/server/dataChainStoreShu';
import { DataHallMksActionCreators } from '../redux/server/dataHallMks';
import { DataHallShuActionCreators } from '../redux/server/dataHallShu';
import { DataKiSyokenKasidamaActionCreators } from '../redux/server/dataKiSyokenKasidama';
import {
  ShortenedUrlOriginalHashActionCreators,
  ShortenedUrlOriginalHashActionTypes,
  originalHashSelector,
} from '../redux/server/shortendUrlOriginalHash';
import { shortenedUrlLoadingStateSelector } from '../redux/server/shortenedUrl';
import { ChainStoreSettingActionCreators } from '../redux/ui/chainStoreReportsSetting';
import {
  HomeActionCreators,
  HomeActionTypes,
  SelectHomeFavoriteAction,
  SelectPageSettingAction,
  SelectPageSettingWithOriginalHashAction,
} from '../redux/ui/favorite';
import { HallReportsSettingActionCreators } from '../redux/ui/hallReportsSetting';
import { KiSyokenSettingActionCreators } from '../redux/ui/kiSyokenSettings';
import { ModeSettingActionCreators } from '../redux/ui/modeSettings';
import { ModelReportsSettingActionCreators } from '../redux/ui/modelReportsSetting';
import { ModelTransitionReportsSettingActionCreators } from '../redux/ui/modelTransitionReportsSetting';
import { PlanProgressTransitionReportsSettingActionCreators } from '../redux/ui/planProgressTransitionReportsSetting';
import { PpmShareReportsSettingActionCreators } from '../redux/ui/ppmShareReportsSetting';
import { SisSettingsActionCreators } from '../redux/ui/sisSettings';
import { TerminalTransitionReportsSettingActionCreators } from '../redux/ui/terminalTransitionReportsSetting';
import { TransitionAfterIntroductionReportsSettingActionCreators } from '../redux/ui/transitionAfterIntroductionReportsSetting';
import { UnitDataListReportsSettingActionCreators } from '../redux/ui/unitDataListReportsSetting';
import { decompressFromEncodedURIComponent } from '../utils/decompressFromEncodedURIComponent';
import { applyFavoriteSaga as applyChainStoreReportsFavoriteSaga } from './chainStoreReportsSettingSaga';
import { applyFavoriteSaga as applyHallReportsFavoriteSaga } from './hallReportsSettingSaga';
import { applyKiSyokenFavoriteSaga } from './kiSyokenSettingSaga';
import { applyFavoriteSaga } from './modeSagas';
import {
  applyFavoriteSaga as applyModelReportsFavoriteSaga,
  initModelReportsDataSaga,
} from './modelReportsSettingSaga';
import { applyFavoriteSaga as applyModelTransitionReportsSaga } from './modelTransitionReportsSagas';
import { applyFavoriteSaga as applyPlanProgressTransitionFavoriteSaga } from './planProgressTransitionReportsSettingSagas';
import { applyFavoriteSaga as applyPpmShareReportsFavoriteSaga } from './ppmShareReportsSettingSaga';
import { applySisFavoriteSaga } from './sisSettingSaga';
import { applyFavoriteSaga as applyTerminalTransitionReportsSaga } from './terminalTransitionReportsSettingSaga';
import { applyFavoriteSaga as applyTransitionAfterIntroductionReportsSaga } from './transitionAfterIntroductionReportsSagas';
import { applyFavoriteSaga as applyUnitDataListReportsFavoriteSaga } from './unitDataListReportsSettingSagas';

function* applyFavoriteByIdSaga(action: SelectHomeFavoriteAction) {
  const { favoriteId, pageName } = action.payload;
  switch (pageName) {
    case 'チェーン店レポート':
      yield put(
        ChainStoreSettingActionCreators.changeChainStoreReportsFavoriteAction(
          favoriteId
        )
      );
      break;
    case '店舗レポート':
      yield put(
        HallReportsSettingActionCreators.changeHallReportsFavoriteAction(
          favoriteId
        )
      );
      break;
    case '機種集計':
      yield put(
        ModelReportsSettingActionCreators.changeModelReportsFavoriteAction(
          favoriteId
        )
      );
      break;
    case '台別データ一覧':
      yield put(
        UnitDataListReportsSettingActionCreators.changeUnitDataListReportsFavoriteAction(
          favoriteId
        )
      );
      break;
    case '導入後推移':
      yield put(
        TransitionAfterIntroductionReportsSettingActionCreators.changeTransitionAfterIntroductionReportsFavoriteAction(
          favoriteId
        )
      );
      break;
    case '期間推移':
      yield put(
        TerminalTransitionReportsSettingActionCreators.changeTerminalTransitionReportsFavoriteAction(
          favoriteId
        )
      );
      break;
    case '機種別推移':
      yield put(
        ModelTransitionReportsSettingActionCreators.changeModelTransitionReportsFavoriteAction(
          favoriteId
        )
      );
      break;
    case 'PPMシェア一覧':
      yield put(
        PpmShareReportsSettingActionCreators.changePpmShareReportsFavoriteAction(
          favoriteId
        )
      );
      break;
    case 'モード別集計':
      yield put(ModeSettingActionCreators.changeModeFavoriteAction(favoriteId));
      break;
    case '予定進捗推移':
      yield put(
        PlanProgressTransitionReportsSettingActionCreators.changePlanProgressTransitionReportsFavoriteAction(
          favoriteId
        )
      );
      break;
    case 'SIS機種レポート':
      yield put(SisSettingsActionCreators.changeSisFavoriteAction(favoriteId));
      break;
    case '機種別商圏データ':
      yield put(
        KiSyokenSettingActionCreators.changeKiSyokenFavoriteAction(favoriteId)
      );
      break;
    default:
      throw new Error('ページ設定がされていません');
  }
}

/**
 * TODO:
 * 初期化処理を入れているがここで初期化をすることが適切かどうか確認が必要。
 * お気に入り適用直前にいれるべきのような気がします。
 */
function* applyPageSettingSaga(action: SelectPageSettingAction) {
  const { pageName, pageSetting, createdAt } = action.payload;

  switch (pageName) {
    case 'チェーン店レポート': {
      if (pageSetting.chainStoreReports == null) {
        throw new Error('pageSettingが設定されていません');
      }
      switch (pageSetting.chainStoreReports.selectedTabId) {
        case 'chainStoreShu':
          yield put(
            DataChainStoreShuActionCreators.renewDataChainStoreShuAction()
          );
          break;
        case 'chainStoreKi':
          yield put(DataChainStoreKiActionCreators.renewDataChainStoreKi());
          break;
      }
      yield put(
        ChainStoreSettingActionCreators.resetChainStoreReportsFavoriteToDefaultAction()
      );
      yield fork(
        applyChainStoreReportsFavoriteSaga,
        pageSetting.chainStoreReports,
        undefined
      );
      return;
    }
    case '店舗レポート': {
      if (pageSetting.hallReports == null) {
        throw new Error('pageSettingが設定されていません');
      }
      switch (pageSetting.hallReports.selectedTabId) {
        case 'hallReportsShu':
          yield put(DataHallShuActionCreators.renewDataHallShuAction());
          break;
        case 'hallReportsMks':
          yield put(DataHallMksActionCreators.renewDataHallMks());
          break;
        case 'hallReportsKi':
          yield put(DataChainStoreKiActionCreators.renewDataChainStoreKi());
          break;
      }
      yield put(
        HallReportsSettingActionCreators.resetHallReportsFavoriteToDefaultAction()
      );
      yield fork(applyHallReportsFavoriteSaga, pageSetting.hallReports);
      return;
    }
    case '予定進捗推移': {
      if (pageSetting.planProgressTransition == null) {
        throw new Error('pageSettingが設定されていません');
      }
      yield fork(
        applyPlanProgressTransitionFavoriteSaga,
        pageSetting.planProgressTransition
      );
      return;
    }
    case 'PPMシェア一覧': {
      if (pageSetting.ppmShareReports == null) {
        throw new Error('pageSettingが設定されていません');
      }
      yield fork(
        applyPpmShareReportsFavoriteSaga,
        pageSetting.ppmShareReports,
        createdAt
      );
      return;
    }
    case '機種集計': {
      if (pageSetting.modelReports == null) {
        throw new Error('pageSettingが設定されていません');
      }
      yield fork(initModelReportsDataSaga);
      yield fork(
        applyModelReportsFavoriteSaga,
        pageSetting.modelReports,
        createdAt
      );
      yield put(
        ModelReportsSettingActionCreators.resetModelReportsFavoriteToDefaultAction()
      );
      return;
    }
    case '台別データ一覧': {
      if (pageSetting.unitDataListReports == null) {
        throw new Error('pageSettingが設定されていません');
      }
      yield fork(
        applyUnitDataListReportsFavoriteSaga,
        pageSetting.unitDataListReports
      );
      return;
    }
    case '導入後推移': {
      if (pageSetting.transitionAfterIntroduction == null) {
        throw new Error('pageSettingが設定されていません');
      }
      yield fork(
        applyTransitionAfterIntroductionReportsSaga,
        pageSetting.transitionAfterIntroduction,
        createdAt
      );
      return;
    }
    case '期間推移': {
      if (pageSetting.terminalTransition == null) {
        throw new Error('pageSettingが設定されていません');
      }
      yield fork(
        applyTerminalTransitionReportsSaga,
        pageSetting.terminalTransition
      );
      return;
    }
    case '機種別推移': {
      if (pageSetting.modelTransition == null) {
        throw new Error('pageSettingが設定されていません');
      }
      yield fork(
        applyModelTransitionReportsSaga,
        pageSetting.modelTransition,
        createdAt
      );
      return;
    }
    case 'モード別集計': {
      if (pageSetting.mode == null) {
        throw new Error('pageSettingが設定されていません');
      }
      yield fork(applyFavoriteSaga, pageSetting.mode, createdAt);
      return;
    }
    case 'SIS機種レポート': {
      if (pageSetting.sis == null) {
        throw new Error('pageSettingが設定されていません');
      }
      yield fork(applySisFavoriteSaga, pageSetting.sis);
      return;
    }
    case '機種別商圏データ': {
      if (pageSetting.kiSyoken == null) {
        throw new Error('pageSettingが設定されていません');
      }
      yield put(
        DataKiSyokenKasidamaActionCreators.hideDataKiSyokenKasidamaAction()
      ); // 貸玉データテーブルを非表示にする(店舗レポートからの遷移時に必要)
      yield fork(applyKiSyokenFavoriteSaga, pageSetting.kiSyoken);
      return;
    }
    default:
      throw new Error('ページ設定がされていません');
  }
}

function* applyPageSettingWithOriginalHashSaga(
  action: SelectPageSettingWithOriginalHashAction
) {
  const { shortenedHash, pageName } = action.payload;
  yield put(
    ShortenedUrlOriginalHashActionCreators.fetchShortenedUrlOriginalHashAction({
      shortenedHash,
    })
  );
  const shortenedUrlLoadingState: LoadingState = yield select(
    shortenedUrlLoadingStateSelector
  );
  if (shortenedUrlLoadingState !== 'loaded') {
    yield take(
      ShortenedUrlOriginalHashActionTypes.FETCH_SHORTENED_URL_ORIGINAL_HASH_SUCCESS
    );
  }
  const originalHash: CreateShortenedUrlResponse['shortenedUrl'] | undefined =
    yield select(originalHashSelector);

  if (originalHash == null) {
    throw new Error('originalHashが取得できませんでした');
  }

  const {
    pageName: pageNameFromQs,
    pageSetting,
    createdAt,
  } = decompressFromEncodedURIComponent(originalHash);

  // MEMO:
  // タブが存在していないページのチェックはここで完了していますが、
  // タブが存在しているページについてはそれぞれのページでチェックする必要があります
  // チェックにはuseIsTabEnabledを使用してください
  if (pageName !== pageNameFromQs) {
    throw new Error('クエリ文字列の指定ページと現ページが異なります。');
  }
  yield put(
    HomeActionCreators.selectPageSettingAction({
      pageName,
      pageSetting,
      createdAt,
    })
  );
}

function* handleApplyFavoriteSaga() {
  yield takeEvery(HomeActionTypes.SELECT_HOME_FAVORITE, applyFavoriteByIdSaga);

  yield takeEvery(HomeActionTypes.SELECT_PAGE_SETTING, applyPageSettingSaga);

  yield takeEvery(
    HomeActionTypes.SELECT_PAGE_SETTING_WITH_ORIGINAL_HASH,
    applyPageSettingWithOriginalHashSaga
  );
}

export function* favoriteSagas() {
  yield fork(handleApplyFavoriteSaga);
}
