import { createSelector } from 'reselect';

import { SettingsOptionsKiSyokenResponse } from '../../domain/kiSyoken/types';
import { LoadingState } from '../../domain/schemas';

import { RootState } from '../../store';

/* ---------------------------------------------------------------
 * Action Types
 */

const FETCH_SETTINGS_OPTIONS_KISYOKEN =
  'FETCH_SETTINGS_OPTIONS_KISYOKEN' as const;
const FETCH_SETTINGS_OPTIONS_KISYOKEN_REQUEST =
  'FETCH_SETTINGS_OPTIONS_KISYOKEN_REQUEST' as const;
const FETCH_SETTINGS_OPTIONS_KISYOKEN_SUCCESS =
  'FETCH_SETTINGS_OPTIONS_KISYOKEN_SUCCESS' as const;
const RENEW_SETTINGS_OPTIONS_KISYOKEN =
  'RENEW_SETTINGS_OPTIONS_KISYOKEN' as const;

export const SettingsOptionsKiSyokenActionTypes = {
  FETCH_SETTINGS_OPTIONS_KISYOKEN,
  FETCH_SETTINGS_OPTIONS_KISYOKEN_REQUEST,
  FETCH_SETTINGS_OPTIONS_KISYOKEN_SUCCESS,
  RENEW_SETTINGS_OPTIONS_KISYOKEN,
};

/* ---------------------------------------------------------------
 * Action Creators
 */

function fetchSettingsOptionsKiSyokenAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_KISYOKEN,
  };
}

function fetchSettingsOptionsKiSyokenRequestAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_KISYOKEN_REQUEST,
  };
}

function fetchSettingsOptionsKiSyokenSuccessAction(
  settingsOptionsKiSyoken: SettingsOptionsKiSyokenResponse
) {
  return {
    type: FETCH_SETTINGS_OPTIONS_KISYOKEN_SUCCESS,
    payload: { settingsOptionsKiSyoken },
  };
}

function renewSettingsOptionsKiSyokenAction() {
  return {
    type: RENEW_SETTINGS_OPTIONS_KISYOKEN,
  };
}

export const SettingsOptionsKiSyokenActionCreators = {
  fetchSettingsOptionsKiSyokenAction,
  fetchSettingsOptionsKiSyokenRequestAction,
  fetchSettingsOptionsKiSyokenSuccessAction,
  renewSettingsOptionsKiSyokenAction,
};

/* ---------------------------------------------------------------
 * Actions
 */

type FetchSettingsOptionsKiSyokenAction = ReturnType<
  typeof fetchSettingsOptionsKiSyokenAction
>;
type FetchSettingsOptionsKiSyokenRequestAction = ReturnType<
  typeof fetchSettingsOptionsKiSyokenRequestAction
>;
type FetchSettingsOptionsKiSyokenSuccessAction = ReturnType<
  typeof fetchSettingsOptionsKiSyokenSuccessAction
>;
type RenewSettingsOptionsKiSyokenAction = ReturnType<
  typeof renewSettingsOptionsKiSyokenAction
>;

type SettingsOptionsKiSyokenAction =
  | FetchSettingsOptionsKiSyokenAction
  | FetchSettingsOptionsKiSyokenRequestAction
  | FetchSettingsOptionsKiSyokenSuccessAction
  | RenewSettingsOptionsKiSyokenAction;

/* ---------------------------------------------------------------
 * State
 */

export type SettingsOptionsKiSyokenState = {
  loadingState: LoadingState;
  settingsOptionsKiSyoken?: SettingsOptionsKiSyokenResponse;
};

const initialState: SettingsOptionsKiSyokenState = {
  loadingState: 'prepare',
  settingsOptionsKiSyoken: undefined,
};

/* ---------------------------------------------------------------
 * Selector
 */

/**
 * [機種別商圏] settingsOptionsの全ての状態を取得する
 */
export function settingsOptionsKiSyokenSelector(state: RootState) {
  return state.settingsOptionsKiSyoken;
}

/**
 * [機種別商圏] settingsOptionsのローディング状態を取得する
 */
export const settingsOptionsKiSyokenLoadingStateSelector = createSelector(
  settingsOptionsKiSyokenSelector,
  ({ loadingState }) => loadingState
);

/**
 * [機種別商圏] データを取得する
 *
 */
export const settingsOptionsKiSyokenDataSelector = createSelector(
  settingsOptionsKiSyokenSelector,
  ({ settingsOptionsKiSyoken }) => settingsOptionsKiSyoken
);

/**
 * [機種別商圏] searchConditionを取得する
 */
export const settingsOptionsKiSyokenSearchConditionSelector = createSelector(
  settingsOptionsKiSyokenSelector,
  ({ settingsOptionsKiSyoken }) => {
    return settingsOptionsKiSyoken?.searchCondition;
  }
);

/**
 * [機種別商圏 fieldsを取得する
 */
export const settingsOptionsKiSyokenFieldsSelector = createSelector(
  settingsOptionsKiSyokenSelector,
  ({ settingsOptionsKiSyoken }) => {
    return settingsOptionsKiSyoken?.fields;
  }
);

/**
 * [機種別商圏] genresを取得する
 */
export const settingsOptionsKiSyokenGenresSelector = createSelector(
  settingsOptionsKiSyokenSelector,
  ({ settingsOptionsKiSyoken }) => {
    return settingsOptionsKiSyoken?.genres;
  }
);

/**
 * [機種別商圏] fields.kiからisNewがtrueのものがあるかどうかを取得する
 */
export const settingsOptionsKiSyokenFieldsKiIsNewSelector = createSelector(
  settingsOptionsKiSyokenFieldsSelector,
  (fields) => {
    return fields?.ki.some((item) => item.isNew);
  }
);

/**
 * [機種別商圏] チェーン店一覧のfieldsを取得する
 */
export const settingsOptionsKiSyokenKasidamaFieldsSelector = createSelector(
  settingsOptionsKiSyokenSelector,
  ({ settingsOptionsKiSyoken }) => {
    return settingsOptionsKiSyoken?.fields.kasidama;
  }
);

/**
 * [機種別商圏] チェーン店一覧のfieldsのisNewがtrueのものがあるかどうかを取得する
 */
export const settingsOptionsKiSyokenKasidamaFieldsIsNewSelector =
  createSelector(
    settingsOptionsKiSyokenKasidamaFieldsSelector,
    (fieldsKashidama) => {
      if (!fieldsKashidama) {
        return false;
      }
      return fieldsKashidama.some((item) => item.isNew);
    }
  );

/* ---------------------------------------------------------------
 * Reducer
 */

export function settingsOptionsKiSyokenReducer(
  state = initialState,
  action: SettingsOptionsKiSyokenAction
): SettingsOptionsKiSyokenState {
  switch (action.type) {
    case FETCH_SETTINGS_OPTIONS_KISYOKEN_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_SETTINGS_OPTIONS_KISYOKEN_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        settingsOptionsKiSyoken: action.payload.settingsOptionsKiSyoken,
      };
    case RENEW_SETTINGS_OPTIONS_KISYOKEN:
      return initialState;
    default:
      return state;
  }
}
