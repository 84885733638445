import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { addWeeks, format, parse } from 'date-fns';
import { useEffect } from 'react';

import {
  useHeaderState,
  useSetHeaderState,
} from '../../../contexts/HeaderContext';

import { useRole } from '../../../hooks/useRole';

import {
  displayWeeksForNewIcon,
  displayWeeksForUpdateIcon,
} from '../../../utils/noticeDisplayWeeks';
import { Base64MenuIcon } from '../../atoms/Icon/Base64Icon/Base64MenuIcon';
import { Tooltip } from '../../atoms/Tooltip/Tooltip';
import { WithNewIcon } from '../../atoms/WithNewIcon/WithNewIcon';
import { WithUpdateIcon } from '../../atoms/WithUpdateIcon/WithUpdateIcon';
import { GlobalNavigationSettingCollapseItem } from './GlobalNavigationSettingCollapseItem';

const name = 'システム設定' as const;

/**
 * システム設定のIcon表示タイミングを設定する箇所
 * 表示期間はそれぞれNewIconは{displayWeeksForNewIcon}週間後,
 * UpdateIconは{displayWeeksForUpdateIcon}週間後までとなっています
 *
 * 【注意点】
 * ・NewIconとUpdateIconの表示タイミングが被っているときはNewIconが優先的に表示されます
 */
const releaseDates = {
  // 管理者とメンテナンスユーザー向け
  adminAndMaintenance: {
    newIcon: '',
    updateIcon: '',
  },
  // 一般ユーザーを含めた全ユーザー向け
  allUsers: {
    newIcon: '',
    updateIcon: '',
  },
};

/**
 * ログインユーザーの権限やリリース日に応じてアイコンの表示有無を決定する
 * @param isAdmin 管理者かどうか
 * @param isMaintenanceUser メンテナンスユーザーかどうか
 * @param current 現在の日付
 * @returns アイコンコンポーネント
 */
export const getDisplayIcon = (
  isAdmin: boolean | undefined,
  isMaintenanceUser: boolean | undefined,
  current: Date,
  releaseDates: {
    adminAndMaintenance: { newIcon: string; updateIcon: string };
    allUsers: { newIcon: string; updateIcon: string };
  }
) => {
  const baseIcon = <Base64MenuIcon pageName={name} sx={{ opacity: 0.5 }} />;

  const prdReleaseDateNewIconForAdminAndMaintenance = addWeeks(
    parse(releaseDates.adminAndMaintenance.newIcon, 'yyyy-MM-dd', new Date()),
    displayWeeksForNewIcon
  );
  const prdReleaseDateUpdateIconForAdminAndMaintenance = addWeeks(
    parse(
      releaseDates.adminAndMaintenance.updateIcon,
      'yyyy-MM-dd',
      new Date()
    ),
    displayWeeksForUpdateIcon
  );
  const prdReleaseDateNewIconForAll = addWeeks(
    parse(releaseDates.allUsers.newIcon, 'yyyy-MM-dd', new Date()),
    displayWeeksForNewIcon
  );
  const prdReleaseDateUpdateIconForAll = addWeeks(
    parse(releaseDates.allUsers.updateIcon, 'yyyy-MM-dd', new Date()),
    displayWeeksForUpdateIcon
  );

  if (isAdmin || isMaintenanceUser) {
    if (prdReleaseDateNewIconForAdminAndMaintenance >= current) {
      return (
        <WithNewIcon
          icon={baseIcon}
          sx={{ bottom: -4, right: -4 }}
          prdReleaseDate={releaseDates.adminAndMaintenance.newIcon}
        />
      );
    } else if (prdReleaseDateNewIconForAll >= current) {
      return (
        <WithNewIcon
          icon={baseIcon}
          sx={{ bottom: -4, right: -4 }}
          prdReleaseDate={releaseDates.allUsers.newIcon}
        />
      );
    } else if (prdReleaseDateUpdateIconForAdminAndMaintenance >= current) {
      return (
        <WithUpdateIcon
          sx={{ bottom: -4, right: -4 }}
          prdReleaseDate={releaseDates.adminAndMaintenance.updateIcon}
        >
          {baseIcon}
        </WithUpdateIcon>
      );
    } else if (prdReleaseDateUpdateIconForAll >= current) {
      return (
        <WithUpdateIcon
          sx={{ bottom: -4, right: -4 }}
          prdReleaseDate={releaseDates.allUsers.updateIcon}
        >
          {baseIcon}
        </WithUpdateIcon>
      );
    }
  } else if (prdReleaseDateNewIconForAll >= current) {
    return (
      <WithNewIcon
        icon={baseIcon}
        sx={{ bottom: -4, right: -4 }}
        prdReleaseDate={releaseDates.allUsers.newIcon}
      />
    );
  } else {
    return (
      <WithUpdateIcon
        sx={{ bottom: -4, right: -4 }}
        prdReleaseDate={releaseDates.allUsers.updateIcon}
      >
        {baseIcon}
      </WithUpdateIcon>
    );
  }
  return <Base64MenuIcon pageName={name} sx={{ opacity: 0.5 }} />;
};

export const GlobalNavigationSettingItem = () => {
  const setIsOpenMenu = useSetHeaderState();
  const { isOpenMenu, isOpenMenuChild } = useHeaderState();
  const { isAdmin, isMaintenanceUser } = useRole();

  // Drawerが閉じると、設定メニューのコンテンツも非表示にしなければ
  // 設定メニューのコンテンツがはみ出てしまうので、メニューをとじたら設定メニューも閉じる
  useEffect(() => {
    if (!isOpenMenu) {
      setIsOpenMenu((prev) => ({ ...prev, isOpenMenuChild: false }));
    }
  }, [isOpenMenu, setIsOpenMenu]);

  // 本日の日付を取得
  const today = format(new Date(), 'yyyy-MM-dd');
  const current = parse(today, 'yyyy-MM-dd', new Date());

  return (
    <>
      <Tooltip
        title={isOpenMenu ? '' : name}
        arrow
        placement="right"
        disableTouchListener
      >
        <ListItem
          button
          onClick={() =>
            setIsOpenMenu((prev) => ({
              ...prev,
              isOpenMenu: true,
              isOpenMenuChild: !prev.isOpenMenuChild,
            }))
          }
        >
          <ListItemIcon sx={{ minWidth: '40px' }}>
            {getDisplayIcon(isAdmin, isMaintenanceUser, current, releaseDates)}
          </ListItemIcon>
          <ListItemText primary={name} />
          {isOpenMenuChild ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      </Tooltip>

      <Collapse in={isOpenMenuChild} timeout="auto" unmountOnExit>
        {(isAdmin || isMaintenanceUser) && (
          <GlobalNavigationSettingCollapseItem
            name="共通設定"
            href="/settings/commonSetting"
          />
        )}
        {(isAdmin || isMaintenanceUser) && (
          <GlobalNavigationSettingCollapseItem
            name="店舗設定"
            href="/settings/hall"
          />
        )}
        {(isAdmin || isMaintenanceUser) && (
          <GlobalNavigationSettingCollapseItem
            name="種別グループ設定"
            href="/settings/shu-group"
          />
        )}
        <GlobalNavigationSettingCollapseItem
          name="機種タグ設定"
          href="/settings/kiTags"
          decorationIcon={{ type: 'update', prdReleaseDate: '2025-01-15' }}
        />
        <GlobalNavigationSettingCollapseItem
          name="予定登録"
          href="/settings/plan"
        />
        <GlobalNavigationSettingCollapseItem
          name="機械代金登録"
          href="/settings/daiCosts"
          decorationIcon={{ type: 'new', prdReleaseDate: '2024-12-18' }}
        />
        <GlobalNavigationSettingCollapseItem
          name="経費登録"
          href="/settings/expense"
        />
        <GlobalNavigationSettingCollapseItem
          name="モード登録"
          href="/settings/modes"
        />
        <GlobalNavigationSettingCollapseItem
          name="機種統一設定"
          href="/settings/ki-link"
        />
        <GlobalNavigationSettingCollapseItem
          name="台番機種設定"
          href="/settings/unit-model"
        />
        {isAdmin && (
          <GlobalNavigationSettingCollapseItem
            name="アカウント設定"
            href="/settings/user"
          />
        )}
        {(isAdmin || isMaintenanceUser) && (
          <GlobalNavigationSettingCollapseItem
            name="操作履歴"
            href="/settings/event"
            decorationIcon={{ type: 'new', prdReleaseDate: '2024-07-10' }}
          />
        )}
        {(isAdmin || isMaintenanceUser) && (
          <GlobalNavigationSettingCollapseItem
            name="営業データ削除"
            href="/settings/dataDeletion"
          />
        )}
      </Collapse>
    </>
  );
};
