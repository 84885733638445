import { ChainReportsFormConditions } from '../chainReportsFormConditions';
import { ChainStoreReportsSettingDateRangeParams } from '../chainStoreReportsSettingDateRangeParams';
import { DataChainStoreKiParams } from '../dataChainStoreKi';
import {
  DataChainStoreShuParams,
  DataChainStoreShuSummaryParams,
} from '../dataChainStoreShu';

/**
 * 自由選択以外の時、ymdListをstart,endDateに変換する
 * @param dateRangeParams - 期間の種類
 * @param ymdList - 選択された期間のリスト
 * @param ymdComparisonList - 選択された比較期間のリスト
 * @returns - 自由選択の時はymdList(ymdComparisonList)を、
 *            それ以外はstartDate,endDate(startComparisonDate,endComparisonDate)をAPIに渡すようにするための関数
 */
export const getDateParams = ({
  dateRangeParams,
  ymdList,
  ymdComparisonList = null,
}: {
  dateRangeParams: ChainStoreReportsSettingDateRangeParams['dateUnit'];
  ymdList?: string[];
  ymdComparisonList?: string[] | null;
}):
  | {
      startDate: string | undefined;
      endDate: string | undefined;
    }
  | {
      ymdList: string[] | undefined;
      ymdComparisonList: string[];
    }
  | {
      startDate: string | undefined;
      endDate: string | undefined;
      startComparisonDate: string;
      endComparisonDate: string;
    }
  | {
      ymdList: string[] | undefined;
    } => {
  if (dateRangeParams === '自由選択') {
    return ymdComparisonList
      ? {
          ymdList,
          ymdComparisonList,
        }
      : {
          ymdList,
        };
  }

  return ymdComparisonList
    ? {
        startDate: ymdList?.[0],
        endDate: ymdList?.slice(-1)[0],
        startComparisonDate: ymdComparisonList?.[0],
        endComparisonDate: ymdComparisonList?.slice(-1)[0],
      }
    : {
        startDate: ymdList?.[0],
        endDate: ymdList?.slice(-1)[0],
      };
};

/**
 * settingsから日付周りのパラメータを除外する関数
 * 検索実行時に使用されるのはpayload側の情報であり、
 * settings内のstartDate, endDate, startComparisonDate, endComparisonDate,
 * ymdList, ymdComparisonListは必要ないため、これらをsettingsから除外する
 * 同じ処理を複数箇所で行う必要があるため、その処理を共通化している
 * @param settings - 検索条件
 */
export const getExcludeDates = (
  settings:
    | ChainReportsFormConditions
    | DataChainStoreKiParams
    | DataChainStoreShuParams
) => {
  // 比較期間を持つの場合の処理
  const {
    startDate,
    endDate,
    startComparisonDate,
    endComparisonDate,
    ymdList,
    ymdComparisonList,
    ...restParams
  } = settings;
  return restParams;
};

/**
 * settingsから日付周りのパラメータを除外する関数
 * 検索実行時に使用されるのはpayload側の情報であり、
 * settings内の startDate, endDate, ymdListは必要ないため、これらをsettingsから除外する
 * 同じ処理を複数箇所で行う必要があるため、その処理を共通化している
 * @param settings - 検索条件
 */
export const getExcludeDatesWithoutComparision = (
  settings: DataChainStoreShuSummaryParams
) => {
  const { startDate, endDate, ymdList, ...restParams } = settings;
  return restParams;
};
