import { createSelector } from 'reselect';

import { dataKiSyokenKasidamaSwapColumnsOrder } from '../../domain/kiSyoken/dataKiSyokenKasidamaSwapColumnsOrder';
import { DataKiSyokenKasidamaResponse } from '../../domain/kiSyoken/types';

import { kiSyokenKasidamaColumnsOrderSelector } from '../ui/kiSyokenSettings';
import { dataKiSyokenKasidamaDataSelector } from './dataKiSyokenKasidama';

/**
 * [機種別償却] 最終的にテーブルに表示させるデータ
 *
 * 循環参照を防ぐために、ファイルを切り出している
 */
export const dataColumnsSwappedSelector = createSelector(
  [dataKiSyokenKasidamaDataSelector, kiSyokenKasidamaColumnsOrderSelector],
  (responseData, columnsOrder): DataKiSyokenKasidamaResponse | undefined => {
    if (responseData == null) {
      return responseData;
    }

    const swappedFieldsOrder = dataKiSyokenKasidamaSwapColumnsOrder(
      responseData,
      columnsOrder
    );

    return swappedFieldsOrder;
  }
);
