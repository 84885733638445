/**
 * pathからお気に入りで使用するタイトルを生成する
 */
export const pathToFavoritePageName = (path: string | undefined) => {
  switch (path) {
    case '/report/chain':
    case '/report/chain/ki':
      return 'チェーン店レポート';
    case '/report/hall':
    case '/report/hall/mks':
    case '/report/hall/ki':
      return '店舗レポート';
    case '/report/ppmShare':
      return 'PPMシェア一覧';
    case '/report/model':
      return '機種集計';
    case '/report/dai':
      return '台別データ一覧';
    case '/report/transitionAfterIntroduction':
      return '導入後推移';
    case '/report/terminalTransition':
      return '期間推移';
    case '/report/modelTransition':
      return '機種別推移';
    case '/report/mode':
      return 'モード別集計';
    case '/report/planProgressTransition':
      return '予定進捗推移';
    case '/report/sis':
      return 'SIS機種レポート';
    case '/report/kiSyoken':
      return '機種別商圏データ';
  }

  throw new Error('pathが不正です');
};
