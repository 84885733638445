import { createSelector } from 'reselect';

import { dataKiSyokenHiddenByMksHall } from '../../domain/kiSyoken/dataKiSyokenFilteredByMksHall';
import { dataKiSyokenFilteredByName } from '../../domain/kiSyoken/dataKiSyokenFilteredByName';
import { dataKiSyokenFilteredByNumber } from '../../domain/kiSyoken/dataKiSyokenFilteredByNumber';
import { dataKiSyokenHiddenByField } from '../../domain/kiSyoken/dataKiSyokenHiddenByField';
import { dataKiSyokenSwapColumnsOrder } from '../../domain/kiSyoken/dataKiSyokenSwapColumnsOrder';
import { removeKyakuSuKiSyoken } from '../../domain/kiSyoken/removeKyakusuKiSyoken';
import { sortKiSyoken } from '../../domain/kiSyoken/sortKiSyoken';

import {
  kiSyokenColumnsOrderSelector,
  kiSyokenMksHallCodesSelector,
  kiSyokenNameFilterSelector,
  kiSyokenOrderSelector,
  kiSyokenSelectedTableFilterSelector,
  kiSyokenSortSelector,
  kiSyokenSubMenuNumberFilterSelector,
} from '../ui/kiSyokenSettings';
import { dataKiSyokenDataSelector } from './dataKiSyoken';

/**
 * [機種別商圏] dataのrowsから不要な単位を削除したデータ
 *
 */
const dataKiSyokenRemovedSelector = createSelector(
  [dataKiSyokenDataSelector],
  (dataKiSyokenData) => {
    if (dataKiSyokenData == null) {
      return dataKiSyokenData;
    }

    // 人の単位を削除
    const removedDataKiSyoken = removeKyakuSuKiSyoken(dataKiSyokenData);

    return removedDataKiSyoken;
  }
);

/**
 * [機種別商圏] ソート処理によって整形されたデータ (ソートラベル・サブメニュー時)
 *
 */
const dataKiSyokenSortedSelector = createSelector(
  [dataKiSyokenRemovedSelector, kiSyokenSortSelector, kiSyokenOrderSelector],
  (dataKiSyokenData, kiSyokenSort, kiSyokenOrder) => {
    if (
      kiSyokenSort == null ||
      kiSyokenOrder == null ||
      dataKiSyokenData == null
    ) {
      return dataKiSyokenData;
    }

    const sortedDataKiSyoken = sortKiSyoken(
      dataKiSyokenData,
      kiSyokenOrder,
      kiSyokenSort
    );
    return sortedDataKiSyoken;
  }
);

/**
 * [機種別商圏] フィルターによって行がフィルタリングされたデータ
 */
const dataRowsFilteredSelector = createSelector(
  [
    dataKiSyokenSortedSelector,
    kiSyokenNameFilterSelector,
    kiSyokenSubMenuNumberFilterSelector,
    // kiSyokenKiTagListSelector,
  ],
  (dataKiSyoken, nameFilter, numberFilter /*, kiTagList*/) => {
    if (dataKiSyoken == null) {
      return dataKiSyoken;
    }

    const filteredByName = dataKiSyokenFilteredByName(dataKiSyoken, nameFilter);

    const filteredByNameAndNumber = dataKiSyokenFilteredByNumber(
      filteredByName,
      numberFilter
    );

    // ToDo: 機種タグ実装時に実装
    // const filteredByNameAndNumberAndKiListAndKiTagList =
    //   dataKiSyokenFilteredByKiTagsList(
    //     filteredByNameAndNumberAndKiList,
    //     kiTagList
    //   );

    return filteredByNameAndNumber;
  }
);

/**
 * [機種別商圏] 通常は使用しません dataKiSyokenResultSelector  を使用してください
 *
 * 表示項目の新規項目追加時に、columnsOrderの更新が必要になりますが
 * 一度もDnDをしていない場合にはcolumnsOrderが存在していないため実テーブルのデータを使用します
 * そのため通常は使用しません。最終的に表示させたいデータを取得したい場合にはdataKiSyokenResultSelectorを使用してください
 */
export const dataColumnsSwappedSelector = createSelector(
  [dataRowsFilteredSelector, kiSyokenColumnsOrderSelector],
  (filteredDataKiSyoken, columnsOrder) => {
    if (filteredDataKiSyoken == null) {
      return filteredDataKiSyoken;
    }

    const swappedFieldsOrder = dataKiSyokenSwapColumnsOrder(
      filteredDataKiSyoken,
      columnsOrder
    );

    return swappedFieldsOrder;
  }
);

/**
 * [機種別商圏] フィルターやスワップ、列非表示によって整形されたデータ
 */
export const dataKiSyokenResultSelector = createSelector(
  [
    dataColumnsSwappedSelector,
    kiSyokenMksHallCodesSelector,
    kiSyokenSelectedTableFilterSelector,
  ],
  (swappedDataKiSyoken, mkshallsFilter, fieldsFilter) => {
    if (swappedDataKiSyoken == null) {
      return swappedDataKiSyoken;
    }

    const hiddenByMksHall = dataKiSyokenHiddenByMksHall(
      swappedDataKiSyoken,
      mkshallsFilter
    );

    const hiddenByField = dataKiSyokenHiddenByField(
      hiddenByMksHall,
      mkshallsFilter,
      fieldsFilter
    );

    return hiddenByField;
  }
);
