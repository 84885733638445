import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { addWeeks, format, parse, subDays } from 'date-fns';
import { FC, ReactNode } from 'react';

import { displayWeeksForNewIcon } from '../../../utils/noticeDisplayWeeks';
import { Base64Icon } from '../Icon/Base64Icon/Base64Icon';

type Props = {
  icon: ReactNode;
  /**
   * PRD環境へのリリース予定日(yyyy-MM-dd)
   * PRDリリースから4週間（4週間後当日も含む）の間Newアイコンが表示されるようになります
   */
  prdReleaseDate: string;
  /**
   * Newアイコン表示のためのsx props
   */
  sx?: SxProps<Theme>;
};

/**
 * iconに指定したアイコンに付随してNewアイコンを表示する
 * `prdReleaseDate` に基づいて、指定された期間（`displayWeeksForNewIcon`週間）内で「New」アイコンを表示し続けます
 *
 * 新規画面がない場合にはどのコンポーネントからも使用されないことがあるため、削除しないでください
 *
 * 本Newアイコンは、新規画面構築のときのみ配置します
 * https://classmethod.slack.com/archives/C026YMFKL8L/p1713162476961899?thread_ts=1712881255.776329&cid=C026YMFKL8L
 */
export const WithNewIcon: FC<Props> = (props) => {
  const { icon, prdReleaseDate, sx } = props;

  const today = format(new Date(), 'yyyy-MM-dd');
  const current = parse(today, 'yyyy-MM-dd', new Date());

  const parsePrdReleaseDate = parse(prdReleaseDate, 'yyyy-MM-dd', new Date());
  const end = addWeeks(parsePrdReleaseDate, displayWeeksForNewIcon);

  // NEWアイコンを目立たせる施策の為表示最終日から1日引く
  // https://www.notion.so/update-New-1-1c0a4e41249f81a694b1d8f4782a3e2c
  const endDate = subDays(end, 1);

  if (endDate >= current) {
    return (
      <Box sx={{ display: 'inline-block', position: 'relative' }}>
        {icon}
        <Base64Icon
          name="newRed"
          sx={{
            position: 'absolute',
            transform: 'translate(50%, 0)',
            ...sx,
          }}
        />
      </Box>
    );
  }

  return <>{icon}</>;
};
