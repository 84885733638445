import { call, fork, put, select, take, takeEvery } from 'redux-saga/effects';

import { FavoriteItem } from '../domain/favorites';
import {
  makeKiSyokenDateFromDateRange,
  makeKiSyokenDateFromDateRangeWithoutCustom,
} from '../domain/kiSyoken/date';
import { defaultDateRangeParams } from '../domain/kiSyoken/defaultValue';
import {
  DataKiSyokenResponse,
  KiSyokenDateRangeParams,
  KiSyokenFavorite,
  KiSyokenSearchParams,
  SettingsOptionsKiSyokenResponse,
} from '../domain/kiSyoken/types';
// import { FavoriteItem } from '../domain/favorites';
import { LoadingState } from '../domain/schemas';
import { ShuOption } from '../domain/shu';
import { User } from '../domain/user';

import {
  DataKiSyokenActionCreators,
  DataKiSyokenActionTypes,
  dataKiSyokenDataSelector,
  dataKiSyokenLoadingStateSelector,
} from '../redux/server/dataKiSyoken';
import { dataColumnsSwappedSelector } from '../redux/server/dataKiSyokenFilterData';
import { DataKiSyokenKasidamaActionCreators } from '../redux/server/dataKiSyokenKasidama';
import {
  SettingsOptionsKiSyokenActionCreators,
  SettingsOptionsKiSyokenActionTypes,
  settingsOptionsKiSyokenFieldsSelector,
  settingsOptionsKiSyokenLoadingStateSelector,
  settingsOptionsKiSyokenSearchConditionSelector,
} from '../redux/server/settingsOptionsKiSyoken';
import { ShortenedUrlActionCreators } from '../redux/server/shortenedUrl';
import {
  SaveAsKiSyokenFavoriteAction,
  SaveKiSyokenFavoriteAction,
  kiSyokenKasidamaColumnsOrderSelector,
  kiSyokenMksHallCodesSelector,
  kiSyokenNameFilterSelector,
  kiSyokenSelectedFavoritePageSettingSelector,
  kiSyokenSelectedFavoriteSelector,
  kiSyokenSelectedTableFilterSelector,
  kiSyokenSettingSelector,
  kiSyokenSubMenuNumberFilterSelector,
} from '../redux/ui/kiSyokenSettings';
// import { ShortenedUrlActionCreators } from '../redux/server/shortenedUrl';
import {
  ChangeKiSyokenFavoriteAction,
  ChangeKiSyokenFieldsAction,
  CreateKiSyokenShortenedUrlAction,
  KiSyokenSettingActionCreators,
  KiSyokenSettingActionTypes,
  SearchKiSyokenSettingsAction,
  TriggerKiSyokenSwapFieldsAction,
  kiSyokenColumnsOrderSelector,
  kiSyokenCurrentShuSelector,
  kiSyokenSearchParamsSelector,
  kiSyokenSelectedDateRangeParamsSelector,
  kiSyokenSelectedFavoriteDataSelector,
} from '../redux/ui/kiSyokenSettings';
import { SettingsFavoritesActionCreators } from '../redux/ui/settingsFavorites';
import { UserActionTypes, userSelector } from '../redux/ui/user';
import { compressToEncodedURIComponent } from '../utils/compressToEncodedURIComponent';
import { customSelect } from '../utils/customSelect';
import { recalcColumnOrder } from '../utils/orderedCell';
import { selectShu2SearchCondition } from '../utils/shu';

/**
 * テーブルデータfetch用のラッパー関数。fetchしかつsearchParamsに値を設定する
 */
function* fetchDataKiSyokenSaga(searchParams: KiSyokenSearchParams) {
  yield put(
    DataKiSyokenKasidamaActionCreators.hideDataKiSyokenKasidamaAction()
  );
  yield put(DataKiSyokenActionCreators.fetchDataKiSyokenAction(searchParams));

  yield take(DataKiSyokenActionTypes.FETCH_DATA_KISYOKEN_SUCCESS);
  const dataKiSyoken: DataKiSyokenResponse = yield select(
    dataKiSyokenDataSelector
  );

  yield put(
    KiSyokenSettingActionCreators.selectKiSyokenSearchParamsAction(
      dataKiSyoken.setting
    )
  );
}

/**
 * 初回データ取得
 */
function* initFetchKiSyokenDataSaga() {
  const loadingStateKiSyoken: LoadingState = yield select(
    dataKiSyokenLoadingStateSelector
  );

  if (loadingStateKiSyoken !== 'prepare') {
    return;
  }

  // NOTE: 初期選択の店舗コードは、ユーザー情報または検索条件から取得する
  const user: User | undefined = yield select(userSelector);

  // ユーザがセットされていない場合は、初回を待機する
  if (!user?.userId) {
    yield take(UserActionTypes.SET_USER);
  }
  const { hallCodeInCharge }: User = yield select(userSelector);

  const settingsOptionsLoadingState: LoadingState = yield select(
    settingsOptionsKiSyokenLoadingStateSelector
  );

  // 検索条件が取得されるまで待機する
  if (
    settingsOptionsLoadingState === 'prepare' ||
    settingsOptionsLoadingState === 'loading'
  ) {
    yield take(
      SettingsOptionsKiSyokenActionTypes.FETCH_SETTINGS_OPTIONS_KISYOKEN_SUCCESS
    );
  }
  const { startDate, startComparisonDate, endDate, endComparisonDate } =
    makeKiSyokenDateFromDateRangeWithoutCustom({
      dateRangeParams: defaultDateRangeParams,
    });

  const searchCondition: SettingsOptionsKiSyokenResponse['searchCondition'] =
    yield select(settingsOptionsKiSyokenSearchConditionSelector);

  const defaultHallCode =
    hallCodeInCharge && hallCodeInCharge.length > 0
      ? hallCodeInCharge
      : searchCondition.halls.at(0)?.code ?? '';

  // 店舗セレクターに初期値を設定
  yield put(
    KiSyokenSettingActionCreators.selectKiSyokenCurrentHallsAction([
      defaultHallCode,
    ])
  );
  // 商圏店舗セレクターに初期値を設定
  yield put(
    KiSyokenSettingActionCreators.selectKiSyokenMksHallCodesAction([
      'mksAll',
      ...(searchCondition.halls
        .find((h) => h.code === defaultHallCode)
        ?.hallsOfMarketArea?.map((h) => h.code) ?? []),
    ])
  );

  const defaultShuGroupList =
    searchCondition.shuGroupList.at(0)?.id ?? undefined;

  // 初期選択の店舗・期間・種別でデータを取得する
  yield fork(fetchDataKiSyokenSaga, {
    halls: [defaultHallCode],
    startDate: startDate,
    endDate: endDate,
    startComparisonDate: startComparisonDate,
    endComparisonDate: endComparisonDate,
    shuGroupIds: defaultShuGroupList,
  } as KiSyokenSearchParams);
}

function* triggerKiSyokenSwapFieldsSaga(
  action: TriggerKiSyokenSwapFieldsAction
) {
  const { draggedId, droppedId } = action.payload;

  const KiSyokenData: DataKiSyokenResponse | undefined = yield select(
    dataKiSyokenDataSelector
  );
  if (KiSyokenData == null) {
    return;
  }

  const currentOrder: string[] = yield select(kiSyokenColumnsOrderSelector);

  const ordered = recalcColumnOrder(
    currentOrder.length === 0
      ? KiSyokenData.data.columns
      : currentOrder.map((code) => ({ code })),
    draggedId,
    droppedId
  );

  yield put(
    KiSyokenSettingActionCreators.selectKiSyokenColumnsOrderAction(ordered)
  );
}

/**
 * 検索用のクエリの日付を生成する、平日/土日祝のクエリの場合にはデータがAPIへリクエストする
 */
function makeSearchParams(
  dateRangeParams: KiSyokenDateRangeParams,
  searchParams: KiSyokenSearchParams
) {
  const {
    // 日付関連パラメータはdateRangeParamsで追加されるためすべて除外する
    startDate,
    endDate,
    startComparisonDate: _0,
    endComparisonDate: _1,
    ymdList,
    ymdComparisonList,
    ...params
  } = searchParams;

  return {
    ...params,
    ...(dateRangeParams.dateUnit === '自由選択'
      ? { ymdList, ymdComparisonList }
      : makeKiSyokenDateFromDateRange({
          dateRangeParams,
          searchParams,
        })),
  };
}

/**
 * 検索ボタンクリック
 */
function* searchActionSaga(action: SearchKiSyokenSettingsAction) {
  const { params, dateRangeParams, selectedKiList } = action.payload;

  // 選択中の種別・種別グループを取得する
  const currentShu = yield* customSelect(kiSyokenCurrentShuSelector);

  // 現在の検索条件を取得
  const searchParams = yield* customSelect(kiSyokenSearchParamsSelector);

  const mergedSearchParams: KiSyokenSearchParams = yield call(
    makeSearchParams,
    dateRangeParams,
    {
      kiList: selectedKiList,
      kiListForSijiritu: selectedKiList,
      makersForSijiritu: params.makers,
      sisTypesForSijiritu: params.sisTypes,
      ...{ ...searchParams, ...params },
    }
  );

  // 商圏セレクタの選択状態の変更
  yield fork(selectKiSyokenMksHallCodesActionSaga, mergedSearchParams);

  // 期間をStoreに格納する
  yield put(
    KiSyokenSettingActionCreators.selectKiSyokenDateRangeParamsAction(
      dateRangeParams
    )
  );
  // 検索
  yield fork(fetchDataKiSyokenSaga, {
    ...mergedSearchParams,
    ...selectShu2SearchCondition(currentShu),
  });
}

/**
 * 商圏セレクタの変更処理
 */
function* selectKiSyokenMksHallCodesActionSaga(
  searchParams: KiSyokenSearchParams
) {
  const dataKiSyoken: DataKiSyokenResponse | undefined = yield select(
    dataKiSyokenDataSelector
  );
  // 検索した店舗が前回の店舗と異なるかどうかをチェック
  const isDifferentHall =
    dataKiSyoken?.setting?.halls?.[0] !== searchParams.halls?.[0];

  // 商圏セレクタの選択状態の変更(同一店舗の場合は処理スキップ)
  if (isDifferentHall) {
    const searchCondition: SettingsOptionsKiSyokenResponse['searchCondition'] =
      yield select(settingsOptionsKiSyokenSearchConditionSelector);
    // 商圏店舗が表示されるようにする
    const targetHall = searchCondition.halls.find(
      (h) => h.code === searchParams.halls?.[0]
    );

    yield put(
      KiSyokenSettingActionCreators.selectKiSyokenMksHallCodesAction([
        'mksAll',
        ...(targetHall?.hallsOfMarketArea?.map((h) => h.code) ?? []),
      ])
    );
  }

  return;
}

/**
 * 機種別商圏のリセットボタン押下
 */
function* resetSearchActionSaga() {
  const favoriteId = yield* customSelect(kiSyokenSelectedFavoriteSelector);

  // お気に入りが選択されていない場合はデフォルト値でリクエストする
  if (favoriteId == null) {
    yield put(DataKiSyokenActionCreators.renewDataKiSyokenAction());
    yield fork(clearAllDataKiSyokenSaga);
    yield fork(initFetchKiSyokenDataSaga);
    return;
  }

  yield put(
    KiSyokenSettingActionCreators.changeKiSyokenFavoriteAction(favoriteId)
  );
}

/**
 * 変更後の店舗でデータを取得する
 */
function* changeCurrentHallsSaga(
  action: ReturnType<
    typeof KiSyokenSettingActionCreators.changeKiSyokenCurrentHallsAction
  >
) {
  // 店舗が選択されていない場合は処理を中断
  if (action.payload.halls === undefined) return;

  // 現在の検索条件を取得
  const searchParams: KiSyokenSearchParams = yield select(
    kiSyokenSearchParamsSelector
  );

  // 商圏セレクタの選択状態の変更
  yield fork(selectKiSyokenMksHallCodesActionSaga, searchParams);

  // 現在の種別を取得
  const currentShu: ShuOption = yield select(kiSyokenCurrentShuSelector);

  // 変更後の店舗でデータを取得する
  yield fork(fetchDataKiSyokenSaga, {
    ...searchParams,
    ...selectShu2SearchCondition(currentShu),
    halls: action.payload.halls,
  });
}

/**
 * 変更後の種別でデータを取得する
 */
function* changeCurrentShuSaga(
  action: ReturnType<
    typeof KiSyokenSettingActionCreators.changeKiSyokenCurrentShuAction
  >
) {
  // 種別が選択されていない場合は処理を中断
  if (action.payload.shu === undefined) return;

  // 現在の検索条件を取得
  const searchParams: KiSyokenSearchParams = yield select(
    kiSyokenSearchParamsSelector
  );

  // 変更後の種別でデータを取得する
  yield fork(fetchDataKiSyokenSaga, {
    ...searchParams,
    ...selectShu2SearchCondition(action.payload.shu),
  });
}

/**
 * 表示項目変更時のリクエスト
 */
function* changeKiSyokenFieldsSaga(action: ChangeKiSyokenFieldsAction) {
  const { notSyokenFields, isSyokenFields } = action.payload;

  const searchParams: KiSyokenSearchParams = yield select(
    kiSyokenSearchParamsSelector
  );

  // notSyokenFieldsとisSyokenFieldsを一つのオブジェクトにまとめる
  const combinedFields: string[] = [...notSyokenFields, ...isSyokenFields];

  yield put(
    KiSyokenSettingActionCreators.selectKiSyokenFieldsAction(combinedFields)
  );

  yield fork(fetchDataKiSyokenSaga, {
    ...searchParams,
    fields: combinedFields,
  });

  // 以下：新規追加された表示項目は一律前に追加処理
  const dataSyoken: DataKiSyokenResponse = yield select(
    dataColumnsSwappedSelector
  );

  const allFields: SettingsOptionsKiSyokenResponse['fields'] | undefined =
    yield select(settingsOptionsKiSyokenFieldsSelector);
  if (dataSyoken == null) {
    throw new Error('dataSyoken is not found');
  }
  if (allFields == null) {
    throw new Error('settingsOptions is not found');
  }

  const columnsOrder = dataSyoken.data.columns
    .map((column) => column.code)
    .filter((code) => code != null) as string[];
  const difference =
    combinedFields?.filter((field) => !columnsOrder.includes(field)) ?? [];

  const desiredOrder = allFields.ki.map(({ code }) => code);
  const sortedDifference = [...difference].sort((a, b) => {
    if (desiredOrder == null) {
      return 0;
    }
    return desiredOrder.indexOf(a) < desiredOrder.indexOf(b) ? -1 : 1;
  });

  const [kiTushoMei, ...rest] = columnsOrder;
  //fieldsが[]の時にデフォルトの表示項目が表示されるよう、空の配列を入れる
  const resultOrder = combinedFields.length
    ? [kiTushoMei, ...sortedDifference, ...rest]
    : [];

  yield put(
    KiSyokenSettingActionCreators.selectKiSyokenColumnsOrderAction(resultOrder)
  );
}

// 期間スライドコンポーネントがクリックされた時に検索期間を設定して再検索を実行する
function* searchDateRangeSlideSaga(
  action: ReturnType<
    typeof KiSyokenSettingActionCreators.searchKiSyokenDateRangeSlideAction
  >
) {
  const {
    dateRange,
    startDate,
    endDate,
    startComparisonDate,
    endComparisonDate,
  } = action.payload;
  // 現在の検索条件を取得
  const searchParams = yield* customSelect(kiSyokenSearchParamsSelector);

  // 現在の期間を取得
  const selectedDateRangeParams = yield* customSelect(
    kiSyokenSelectedDateRangeParamsSelector
  );

  // 期間をStoreに格納する
  yield put(
    KiSyokenSettingActionCreators.selectKiSyokenDateRangeParamsAction({
      ...selectedDateRangeParams,
      dateRange,
    })
  );
  // ローディング中の期間表示の整合性を保つために、フェッチ前に期間をStoreに格納する
  yield put(
    KiSyokenSettingActionCreators.selectKiSyokenSearchParamsAction({
      ...searchParams,
      startDate,
      endDate,
      startComparisonDate,
      endComparisonDate,
    })
  );

  yield fork(fetchDataKiSyokenSaga, {
    ...searchParams,
    startDate,
    endDate,
    startComparisonDate,
    endComparisonDate,
  });
}

// 検索ボタンクリック時にデータを取得する
function* handleSearchSaga() {
  yield takeEvery(
    KiSyokenSettingActionTypes.SEARCH_KI_SYOKEN_SETTINGS,
    searchActionSaga
  );
}

/**
 * 初回表示時のデータ取得
 */
function* handleInitFetchSaga() {
  yield takeEvery(
    KiSyokenSettingActionTypes.INIT_KISYOKEN_SETTING,
    initFetchKiSyokenDataSaga
  );
}
/**
 * お気に入り選択中または未選択時のデフォルト値にする
 */
function* handleSearchResetSaga() {
  yield takeEvery(
    KiSyokenSettingActionTypes.SEARCH_RESET_KI_SYOKEN_SEARCH_PARAMS,
    resetSearchActionSaga
  );
}

/**
 * 表示項目変更時のデータ取得
 */
function* handleChangeFieldSaga() {
  yield takeEvery(
    KiSyokenSettingActionTypes.CHANGE_KISYOKEN_FIELDS,
    changeKiSyokenFieldsSaga
  );
}

/**
 * 表示項目並び順の変更
 */
function* handleFieldOrderSaga() {
  yield takeEvery(
    KiSyokenSettingActionTypes.TRIGGER_KISYOKEN_SWAP_FIELDS,
    triggerKiSyokenSwapFieldsSaga
  );
}

/**
 * 選択中の店舗変更時、データを取得する
 */
function* handleChangeCurrentHallsSaga() {
  yield takeEvery(
    KiSyokenSettingActionTypes.CHANGE_KISYOKEN_CURRENT_HALLS,
    changeCurrentHallsSaga
  );
}

/**
 * 選択中の種別変更時、データを取得する
 */
function* handleChangeCurrentShuSaga() {
  yield takeEvery(
    KiSyokenSettingActionTypes.CHANGE_KISYOKEN_CURRENT_SHU,
    changeCurrentShuSaga
  );
}

/**
 * 期間スライドコンポーネントがクリックされた時に実行する処理
 */
function* handleDateRangeSlideSaga() {
  yield takeEvery(
    KiSyokenSettingActionTypes.SEARCH_KISYOKEN_DATE_RANGE_SLIDE,
    searchDateRangeSlideSaga
  );
}

/**
 * Settingsとチェーン店のデータを破棄
 */
function* clearAllDataKiSyokenSaga() {
  yield put(KiSyokenSettingActionCreators.renewKiSyokenSettingAction());
  yield put(
    DataKiSyokenKasidamaActionCreators.renewDataKiSyokenKasidamaAction()
  );
}

/**
 * お気に入り選択時、検索条件に反映する
 * @param action Action
 */
function* applyFavoriteByIdSaga(action: ChangeKiSyokenFavoriteAction) {
  const favoriteItem = yield* customSelect(
    kiSyokenSelectedFavoriteDataSelector
  );

  // 選択されたお気に入りID
  const selectedFavoriteId = action.payload.favoriteId;

  // デフォルトのお気に入りを選択した場合、初回取得と同様の動作を行う
  if (selectedFavoriteId === undefined || favoriteItem === undefined) {
    yield put(DataKiSyokenActionCreators.renewDataKiSyokenAction());
    yield fork(clearAllDataKiSyokenSaga);
    yield put(KiSyokenSettingActionCreators.initKiSyokenSettingAction());
    return;
  }

  /** お気に入り選択時、チェーン店の情報を初期化する */
  yield put(
    DataKiSyokenKasidamaActionCreators.renewDataKiSyokenKasidamaAction()
  );

  /** 現在選択中のお気に入りデータを取得 */
  const favorite = yield* customSelect(
    kiSyokenSelectedFavoritePageSettingSelector
  );
  if (favorite == null) {
    throw new Error('該当するお気に入りが見つかりません');
  }

  const lastUpdateAt = favoriteItem.updatedAt ?? favoriteItem.createdAt;

  if (lastUpdateAt == null) {
    throw new Error('お気に入りの作成日が存在しません');
  }

  yield fork(applyKiSyokenFavoriteSaga, favorite);
}

/** Favoriteの内容を実際に反映させる */
export function* applyKiSyokenFavoriteSaga(favorite: KiSyokenFavorite) {
  const loadingState = yield* customSelect(dataKiSyokenLoadingStateSelector);
  /** 画面リンクなどから直接アクセスされた場合、settingsOptionsが取得されていない場合があるため、取得する */
  if (loadingState === 'prepare') {
    yield put(
      SettingsOptionsKiSyokenActionCreators.fetchSettingsOptionsKiSyokenAction()
    );

    yield take(
      SettingsOptionsKiSyokenActionTypes.FETCH_SETTINGS_OPTIONS_KISYOKEN_SUCCESS
    );
  }

  /** 検索条件の期間部分を再計算 */
  const calcDateRange = makeKiSyokenDateFromDateRange({
    dateRangeParams: favorite.selectedDateRangeParams,
    searchParams: favorite.searchParams,
  });

  /**
   * containsAverageについて
   * お気に入り実装時には平均合計行が実装されておらず、その時点で作成したお気に入りは全てcontainsAverageがfalseとして保存されてしまう。
   * しかし、平均合計行が実装された場合には、過去のお気に入りでも平均合計行を表示させるようにしたい。
   * そのため、searchParamsからcontainsAverageを除いたものを取得し、searchParamsへの反映時にcontainsAverageがfalseの場合には省略するようにする。
   *
   * 現在平均合計行はユーザー操作による明示的な表示切り替えがないためこれでも良いが、今後そう言った要件が増える場合にはここの処理の書き換えが必要
   */
  const { containsAverage, ...searchParamsExcludeContainsAverage } =
    favorite.searchParams;

  /** 検索条件の反映 */
  yield put(
    KiSyokenSettingActionCreators.selectKiSyokenSearchParamsAction({
      ...searchParamsExcludeContainsAverage,
      /** お気に入り実装前の平均合計行を考慮
       * 1. 平均合計行が実装前のお気に入りはcontainsAverage = falseなので、その場合はcontainsAverageを省略する
       *   1-a. 平均合計行が実装された場合は、ここでは反映されずメインのdata Response側のcontainsAverageが参照される
       * 2. 平均合計行が実装されたお気に入りは、そのままの値を反映する
       */
      ...(containsAverage && { containsAverage }),
      ...calcDateRange,
    })
  );

  /** 期間を反映 */
  yield put(
    KiSyokenSettingActionCreators.selectKiSyokenDateRangeParamsAction(
      favorite.selectedDateRangeParams
    )
  );

  /** 商圏店舗を選択 */
  yield put(
    KiSyokenSettingActionCreators.selectKiSyokenMksHallCodesAction(
      favorite.selectedMksHallCodes ?? []
    )
  );

  /** サブメニュー内数値フィルタの反映 */
  yield put(
    KiSyokenSettingActionCreators.selectKiSyokenSubMenuNumberFilterAction(
      favorite.subMenuNumberFilter ?? {
        minimumNumber: undefined,
        maximumNumber: undefined,
      }
    )
  );
  /** 機種名フィルタの反映 */
  yield put(
    KiSyokenSettingActionCreators.selectKiSyokenNameFilterAction(
      favorite.nameFilter
    )
  );

  /** 非表示項目の反映 */
  yield put(
    KiSyokenSettingActionCreators.applyKiSyokenTableFilterAction(
      favorite.tableFilterItems
    )
  );

  /** 表示項目の列順の反映 */
  yield put(
    KiSyokenSettingActionCreators.selectKiSyokenColumnsOrderAction(
      favorite.columnsOrder
    )
  );

  /** テーブルのデータ取得 */
  yield put(
    DataKiSyokenActionCreators.fetchDataKiSyokenAction({
      ...favorite.searchParams,
      ...calcDateRange,
    })
  );

  if (Object.keys(favorite.kasidamaTable.searchParams).length !== 0) {
    /** チェーン店一覧の検索条件を変更 */
    yield put(
      KiSyokenSettingActionCreators.selectKiSyokenKasidamaSearchParamsAction(
        favorite.kasidamaTable.searchParams
      )
    );

    /** チェーン店一覧のデータ取得 */
    yield put(
      DataKiSyokenKasidamaActionCreators.fetchDataKiSyokenKasidamaAction({
        ...favorite.kasidamaTable.searchParams,
        ...calcDateRange,
      })
    );
    /**チェーン店の並び順を変更 */
    yield put(
      KiSyokenSettingActionCreators.selectKiSyokenKasidamaColumnsOrderAction(
        favorite.kasidamaTable.columnsOrder
      )
    );
  }
}

/**
 * お気に入り上書き保存時の処理
 */
function* saveFavoriteSaga(action: SaveKiSyokenFavoriteAction) {
  /** 選択中のお気に入りID */
  const selectedFavoriteId = yield* customSelect(
    kiSyokenSelectedFavoriteSelector
  );
  /** 選択中のお気に入り */
  const selectedFavorite = yield* customSelect(
    kiSyokenSelectedFavoriteDataSelector
  );

  /** 現在の機種別商圏の設定すべて */
  const pageSetting = yield* customSelect(kiSyokenSettingSelector);

  /** 機種名フィルタ */
  const modelNameFilter = yield* customSelect(kiSyokenNameFilterSelector);

  /** サブメニュー内数値フィルタ*/
  const subMenuNumberFilter = yield* customSelect(
    kiSyokenSubMenuNumberFilterSelector
  );

  /** テーブルフィルターで選択中の項目 */
  const tableFilterSelectedItems = yield* customSelect(
    kiSyokenSelectedTableFilterSelector
  );

  /** 商圏コード */
  const selectedMksHallCodes = yield* customSelect(
    kiSyokenMksHallCodesSelector
  );

  /** チェーン店一覧の列順を取得する */
  const selectedKasidamaColumnsOrder = yield* customSelect(
    kiSyokenKasidamaColumnsOrderSelector
  );

  /** デフォルトの時は動作しない */
  if (selectedFavoriteId === undefined) return;

  let favorite: FavoriteItem = {};
  if (action.payload.mode === 'memo') {
    /** メモのみ更新モードでの変更後のお気に入りデータ */
    favorite = {
      ...selectedFavorite,
      memo: action.payload.memo,
    };
  } else {
    /** 変更後のお気に入りデータ */
    favorite = {
      name: action.payload.name,
      isShared: action.payload.isShared,
      pageSetting: {
        kiSyoken: {
          searchParams: pageSetting.searchParams,
          kasidamaTable: {
            searchParams: pageSetting.kasidamaTable?.searchParams,
            columnsOrder: selectedKasidamaColumnsOrder,
          },
          selectedDateRangeParams: pageSetting.selectedDateRangeParams,
          columnsOrder: pageSetting.columnsOrder,
          nameFilter: modelNameFilter,
          subMenuNumberFilter: subMenuNumberFilter,
          tableFilterItems: tableFilterSelectedItems,
          selectedMksHallCodes: selectedMksHallCodes,
        },
      },
      memo: action.payload.memo,
      privatelySharedUsers: action.payload.sharedUser,
    };
  }

  /** お気に入りを上書き */
  yield put(
    SettingsFavoritesActionCreators.patchSettingsFavoritesAction(
      selectedFavoriteId,
      favorite,
      { pageName: '機種別商圏データ' }
    )
  );
}

/**
 * お気に入りに保存するデータの生成
 */
function* setPageSetting() {
  /** 現在の機種別商圏データの設定すべて */
  const pageSetting = yield* customSelect(kiSyokenSettingSelector);

  /** 機種名フィルタ */
  const nameFilter = yield* customSelect(kiSyokenNameFilterSelector);

  /** サブメニュー内数値フィルタ */
  const subMenuNumberFilter = yield* customSelect(
    kiSyokenSubMenuNumberFilterSelector
  );

  /** テーブルフィルターで選択中の項目 */
  const tableFilterSelectedItems = yield* customSelect(
    kiSyokenSelectedTableFilterSelector
  );

  /** 商圏コード */
  const selectedMksHallCodes = yield* customSelect(
    kiSyokenMksHallCodesSelector
  );

  /** チェーン店一覧の列順を取得する */
  const selectedKasidamaColumnsOrder = yield* customSelect(
    kiSyokenKasidamaColumnsOrderSelector
  );

  const result: FavoriteItem['pageSetting'] = {
    kiSyoken: {
      searchParams: pageSetting.searchParams,
      kasidamaTable: {
        searchParams: pageSetting.kasidamaTable.searchParams,
        columnsOrder: selectedKasidamaColumnsOrder,
      },
      selectedDateRangeParams: pageSetting.selectedDateRangeParams,
      columnsOrder: pageSetting.columnsOrder,
      nameFilter: nameFilter,
      subMenuNumberFilter: subMenuNumberFilter,
      tableFilterItems: tableFilterSelectedItems,
      selectedMksHallCodes: selectedMksHallCodes,
    },
  };
  return result;
}

/**
 * お気に入り新規保存時の処理
 */
function* saveAsFavoriteSaga(action: SaveAsKiSyokenFavoriteAction) {
  const pageSetting: FavoriteItem['pageSetting'] = yield call(setPageSetting);

  // 変更後のお気に入りデータ
  const favorite: FavoriteItem = {
    name: action.payload.name,
    isShared: action.payload.isShared,
    pageName: '機種別商圏データ',
    pageSetting,
    memo: action.payload.memo,
    privatelySharedUsers: action.payload.sharedUser,
  };

  // お気に入りを新規保存
  yield put(
    SettingsFavoritesActionCreators.postSettingsFavoritesAction([favorite], {})
  );
}

/**
 * お気に入り関連の処理
 */
function* handleFavoriteSaga() {
  // お気に入り適用
  yield takeEvery(
    KiSyokenSettingActionTypes.CHANGE_KISYOKEN_FAVORITE,
    applyFavoriteByIdSaga
  );
  // お気に入り上書き保存
  yield takeEvery(
    KiSyokenSettingActionTypes.SAVE_KISYOKEN_FAVORITE,
    saveFavoriteSaga
  );
  // お気に入り新規保存
  yield takeEvery(
    KiSyokenSettingActionTypes.SAVE_AS_KISYOKEN_FAVORITE,
    saveAsFavoriteSaga
  );
}

// 画面共有用短縮URL作成の処理
function* handleShortenedUrlSaga() {
  yield takeEvery(
    KiSyokenSettingActionTypes.CREATE_KISYOKEN_SHORTENED_URL,
    createShortenedUrlSaga
  );
}

/**
 * 画面共有用短縮URL作成の処理
 */
export function* createShortenedUrlSaga(
  action: CreateKiSyokenShortenedUrlAction
) {
  const pageSetting: FavoriteItem['pageSetting'] = yield call(setPageSetting);

  const compressed = compressToEncodedURIComponent(
    action.payload.pageName,
    pageSetting ?? {}
  );
  const originalUrl = `${action.payload.locationUrl}?q=${compressed}`;

  yield put(ShortenedUrlActionCreators.postShortenedUrlAction({ originalUrl }));
}

export function* kiSyokenSettingSaga() {
  yield fork(handleInitFetchSaga);
  yield fork(handleChangeFieldSaga);
  yield fork(handleFieldOrderSaga);
  yield fork(handleChangeCurrentHallsSaga);
  yield fork(handleChangeCurrentShuSaga);
  yield fork(handleDateRangeSlideSaga);
  yield fork(handleSearchSaga);
  yield fork(handleSearchResetSaga);
  yield fork(handleFavoriteSaga);
  yield fork(handleShortenedUrlSaga);
}
