import { ShuGroupList } from './schemas';

// 種別グループ・種別の選択肢（詳細検索）
export type ShuOption = {
  code: string;
  name: string;
  group: string;
  type: string;
};

export const SHU_CATEGORY_PACHINKO = 'SHU_CATEGORY_PACHINKO' as const;
export const SHU_CATEGORY_SLOT = 'SHU_CATEGORY_SLOT' as const;
export type Category = typeof SHU_CATEGORY_PACHINKO | typeof SHU_CATEGORY_SLOT;

/**
 * shu および shuGroupList がパチンコかスロットかを判定する
 */
const predicate = (firstCharacter: string) => (x: string | string[]) =>
  typeof x === 'string'
    ? x.startsWith(firstCharacter)
    : x.every((x: string) => x.startsWith(firstCharacter));
export const isPachinko = predicate('P');
export const isSlot = predicate('S');

/**
 * shuOptionがパチンコかスロットのどちらかを判定する
 */
export const detectCategoryFromShuOption = (
  shuOption: ShuOption | undefined,
  shuGroupList: ShuGroupList[]
): Category => {
  if (!shuOption) {
    throw new Error('currentTableShu is undefined');
  }
  if (shuOption.type === 'shuList') {
    if (isPachinko(shuOption.code)) return SHU_CATEGORY_PACHINKO;
    if (isSlot(shuOption.code)) return SHU_CATEGORY_SLOT;
    throw new Error(`cannot detect category of ${shuOption.code}`);
  }

  const shuGroup = shuGroupList.find((x) => x.id === shuOption.code);
  if (shuGroup && isPachinko(shuGroup.shuList)) return SHU_CATEGORY_PACHINKO;
  if (shuGroup && isSlot(shuGroup.shuList)) return SHU_CATEGORY_SLOT;
  throw new Error(`cannot detect category of ${shuGroup}`);
};

const filterPredicate = (category: Category) =>
  category === SHU_CATEGORY_PACHINKO
    ? (xs: string | string[]) => isPachinko(xs)
    : (xs: string | string[]) => isSlot(xs);

/**
 * shuListをパチンコかスロットのいずれかに絞る
 */
export const filterShuListArrayByCategory = (
  xs: string[],
  category: Category
): string[] => xs.filter(filterPredicate(category));

/**
 * shuGroupListをパチンコかスロットのいずれかに絞る
 */
export const filterShuGroupListArrayByCategory = (
  xs: ShuGroupList[],
  category: Category
): ShuGroupList[] => {
  const f = filterPredicate(category);
  return xs.filter((x) => f(x.shuList));
};
