import { fork, put, take, takeEvery } from 'redux-saga/effects';

import {
  KiSyokenKasidamaSearchParams,
  KiSyokenSearchParams,
} from '../domain/kiSyoken/types';

import {
  DataKiSyokenKasidamaActionCreators,
  DataKiSyokenKasidamaActionTypes,
  dataKiSyokenKasidamaSettingSelector,
} from '../redux/server/dataKiSyokenKasidama';
import { dataColumnsSwappedSelector } from '../redux/server/dataKiSyokenKasidamaColumnsSwappedData';
import { settingsOptionsKiSyokenKasidamaFieldsSelector } from '../redux/server/settingsOptionsKiSyoken';
import {
  ChangeKiSyokenKasidamaFieldsAction,
  ChangeKiSyokenKasidamaShuAction,
  ChangeKiSyokenKasidamaSortAction,
  KiSyokenSettingActionCreators,
  KiSyokenSettingActionTypes,
  SearchKiSyokenKasidamaAction,
  TriggerKiSyokenKasidamaSwapFieldsAction,
  kiSyokenKasidamaColumnsOrderSelector,
  kiSyokenKasidamaSearchParamsSelector,
  kiSyokenSearchParamsSelector,
} from '../redux/ui/kiSyokenSettings';
import { customSelect } from '../utils/customSelect';
import { recalcColumnOrder } from '../utils/orderedCell';
import { selectShu2SearchCondition } from '../utils/shu';

/**
 * テーブルデータfetch用のラッパー関数。fetchしかつsearchParamsに値を設定する
 */
function* fetchDataKiSyokenKasidamaSaga(
  searchParams: KiSyokenKasidamaSearchParams
) {
  yield put(
    DataKiSyokenKasidamaActionCreators.fetchDataKiSyokenKasidamaAction(
      searchParams
    )
  );
  yield take(
    DataKiSyokenKasidamaActionTypes.FETCH_DATA_KISYOKEN_KASIDAMA_SUCCESS
  );
  const setting = yield* customSelect(dataKiSyokenKasidamaSettingSelector);
  yield put(
    KiSyokenSettingActionCreators.selectKiSyokenKasidamaSearchParamsAction(
      setting ?? {}
    )
  );
}

/**
 * メインテーブルの検索条件を元にチェーン店一覧の検索条件を生成する
 *
 * 種別はデフォルトで「種別全体」とするためshuList,shuGroupIdsは指定しない
 */
function generateKasidamaSearchParams(
  kiCode: string,
  searchParams: KiSyokenSearchParams,
  kasidamaSearchParams: KiSyokenKasidamaSearchParams
): KiSyokenKasidamaSearchParams {
  return {
    // fields、sort、orderは前回の情報を引き継ぐ
    fields: kasidamaSearchParams.fields,
    sort: kasidamaSearchParams.sort,
    order: kasidamaSearchParams.order,
    // それ以外は初回はメインテーブルの情報を利用する
    days: kasidamaSearchParams.days ?? searchParams.days,
    dayType: kasidamaSearchParams.dayType ?? searchParams.dayType,
    dateSuffixes:
      kasidamaSearchParams.dateSuffixes ?? searchParams.dateSuffixes,
    dates: kasidamaSearchParams.dates ?? searchParams.dates,
    makers: kasidamaSearchParams.makers ?? searchParams.makers,
    makersForSijiritu:
      kasidamaSearchParams.makersForSijiritu ?? searchParams.makersForSijiritu,
    sisTypes: kasidamaSearchParams.sisTypes ?? searchParams.sisTypes,
    sisTypesForSijiritu:
      kasidamaSearchParams.sisTypesForSijiritu ??
      searchParams.sisTypesForSijiritu,
    // 機種は毎回指定する
    kiList: [kiCode],
    kiListForSijiritu:
      kasidamaSearchParams.kiListForSijiritu ?? searchParams.kiListForSijiritu,
    ...(searchParams.startDate && {
      startDate: searchParams.startDate,
    }),
    ...(searchParams.endDate && {
      endDate: searchParams.endDate,
    }),
    ...(searchParams.startComparisonDate && {
      startComparisonDate: searchParams.startComparisonDate,
    }),
    ...(searchParams.endComparisonDate && {
      endComparisonDate: searchParams.endComparisonDate,
    }),
    ...(searchParams.ymdList && {
      ymdList: searchParams.ymdList,
    }),
    ...(searchParams.ymdComparisonList && {
      ymdComparisonList: searchParams.ymdComparisonList,
    }),
    excludeToday:
      kasidamaSearchParams.excludeToday ?? searchParams.excludeToday,

    // TODO: SIS対応前に以下を実装していても問題ないが念の為SIS対応後にコメントアウトを外す。

    // SISが実装されるまではバックエンドデフォルト値が存在しないためパラメータに付与されない。
    // sisDateRangeType:
    //   kasidamaSearchParams.sisDateRangeType ?? searchParams.sisDateRangeType,
    // sisStartDate:
    //   kasidamaSearchParams.sisStartDate ?? searchParams.sisStartDate,
    // sisEndDate: kasidamaSearchParams.sisEndDate ?? searchParams.sisEndDate,
    // sisFieldTypes:
    //   kasidamaSearchParams.sisFieldTypes ?? searchParams.sisFieldTypes,
    // sisBreakEvenForPachinko:
    //   kasidamaSearchParams.sisBreakEvenForPachinko ??
    //   searchParams.sisBreakEvenForPachinko,
    // sisBreakEvenForPachislo:
    //   kasidamaSearchParams.sisBreakEvenForPachislo ??
    //   searchParams.sisBreakEvenForPachislo,
    // sisRank: kasidamaSearchParams.sisRank ?? searchParams.sisRank,
    // sisArea: kasidamaSearchParams.sisArea ?? searchParams.sisArea,

    // containsSisAverageはKiSyokenSearchParamsには存在しない。
    // お気に入り保存後にバックエンドデフォルト値がfalseからtrueに切り替わった時に対応できるようにfalseの場合はパラメータを付与しない様にしている。
    // ...(kasidamaSearchParams.containsSisAverage && {
    //   containsSisAverage: kasidamaSearchParams.containsSisAverage,
    // }),
  };
}

/**
 * メインテーブルからの機種選択(初回取得含む)、機種セレクタで機種変更時のデータ取得
 */
function* searchKiSyokenKasidamaDataSaga(action: SearchKiSyokenKasidamaAction) {
  const { kiCode } = action.payload;
  const kiSyokenSearchParams = yield* customSelect(
    kiSyokenSearchParamsSelector
  );
  const kiSyokenKasidamaSearchParams = yield* customSelect(
    kiSyokenKasidamaSearchParamsSelector
  );

  const generatedSearchParams = generateKasidamaSearchParams(
    kiCode,
    kiSyokenSearchParams,
    kiSyokenKasidamaSearchParams
  );
  yield fork(fetchDataKiSyokenKasidamaSaga, {
    ...generatedSearchParams,
  });
}

/**
 * 種別変更時のデータ取得
 */
function* changeKiSyokenKasidamaShuSagas(
  action: ChangeKiSyokenKasidamaShuAction
) {
  // 現在の検索条件
  const searchParams = yield* customSelect(
    kiSyokenKasidamaSearchParamsSelector
  );
  // 変更後の種別で再取得
  yield fork(fetchDataKiSyokenKasidamaSaga, {
    ...searchParams,
    ...selectShu2SearchCondition(action.payload.currentShu),
  });
}

/**
 * 表示項目変更時のデータ取得
 */
function* changeKiSyokenKasidamaFieldsSagas(
  action: ChangeKiSyokenKasidamaFieldsAction
) {
  const fields = action.payload.fields.map((item) => item.code);

  // 現在の検索条件
  const searchParams = yield* customSelect(
    kiSyokenKasidamaSearchParamsSelector
  );

  // 変更後の表示項目で再取得
  yield fork(fetchDataKiSyokenKasidamaSaga, {
    ...searchParams,
    fields: fields,
  });

  // 新規追加された表示項目はスティッキー項目を除いて一律前に追加する処理
  const swappedData = yield* customSelect(dataColumnsSwappedSelector);
  const allFields = yield* customSelect(
    settingsOptionsKiSyokenKasidamaFieldsSelector
  );
  if (swappedData == null) {
    throw new Error('data is not found');
  }
  if (allFields == null) {
    throw new Error('settingsOptions is not found');
  }

  const columnsOrder = swappedData.data.columns
    .map((column) => column.code)
    .filter((code) => code != null) as string[];
  const difference =
    fields?.filter((field) => !columnsOrder.includes(field)) ?? [];

  const desiredOrder = allFields.map(({ code }) => code);
  const sortedDifference = [...difference].sort((a, b) => {
    if (desiredOrder == null) {
      return 0;
    }
    return desiredOrder.indexOf(a) < desiredOrder.indexOf(b) ? -1 : 1;
  });

  // 種別とホール名はコンポーネント側でswapを制限しているため常に先頭に存在する
  const [shu, hlMei, ...rest] = columnsOrder;
  //fieldsが[]の時にデフォルトの表示項目が表示されるよう、空の配列を入れる
  const resultOrder = fields.length
    ? [shu, hlMei, ...sortedDifference, ...rest]
    : [];

  yield put(
    KiSyokenSettingActionCreators.selectKiSyokenKasidamaColumnsOrderAction(
      resultOrder
    )
  );
}

/**
 * ソートボタン押下時のデータ取得
 */
function* changeKiSyokenKasidamaSortSagas(
  action: ChangeKiSyokenKasidamaSortAction
) {
  // 現在の検索条件
  const searchParams = yield* customSelect(
    kiSyokenKasidamaSearchParamsSelector
  );

  // 変更後のソートで再取得
  yield fork(fetchDataKiSyokenKasidamaSaga, {
    ...searchParams,
    sort: action.payload.sort,
    order: action.payload.order,
  });
}

/**
 * 表示項目の並び順変更時のステート更新（データ取得はしない）
 */
function* triggerKiSyokenKasidamaSwapFieldsSaga(
  action: TriggerKiSyokenKasidamaSwapFieldsAction
) {
  const { draggedId, droppedId } = action.payload;

  const responseData = yield* customSelect(dataColumnsSwappedSelector);
  if (responseData == null) {
    return;
  }

  const currentOrder = yield* customSelect(
    kiSyokenKasidamaColumnsOrderSelector
  );

  const ordered = recalcColumnOrder(
    currentOrder.length === 0
      ? responseData.data.columns
      : currentOrder.map((code) => ({ code })),
    draggedId,
    droppedId
  );

  yield put(
    KiSyokenSettingActionCreators.selectKiSyokenKasidamaColumnsOrderAction(
      ordered
    )
  );
}

function* handleSaga() {
  yield takeEvery(
    KiSyokenSettingActionTypes.SEARCH_KISYOKEN_KASIDAMA,
    searchKiSyokenKasidamaDataSaga
  );

  yield takeEvery(
    KiSyokenSettingActionTypes.CHANGE_KISYOKEN_KASIDAMA_SHU,
    changeKiSyokenKasidamaShuSagas
  );

  yield takeEvery(
    KiSyokenSettingActionTypes.CHANGE_KISYOKEN_KASIDAMA_FIELDS,
    changeKiSyokenKasidamaFieldsSagas
  );

  yield takeEvery(
    KiSyokenSettingActionTypes.CHANGE_KISYOKEN_KASIDAMA_SORT,
    changeKiSyokenKasidamaSortSagas
  );

  yield takeEvery(
    KiSyokenSettingActionTypes.TRIGGER_KISYOKEN_KASIDAMA_SWAP_FIELDS,
    triggerKiSyokenKasidamaSwapFieldsSaga
  );
}

export function* kiSyokenKasidamaSaga() {
  yield fork(handleSaga);
}
