import { createSelector } from 'reselect';

import {
  DataKiSyokenResponse,
  DataKiSyokenSearchParams,
} from '../../domain/kiSyoken/types';
import { LoadingState } from '../../domain/schemas';

/* ---------------------------------------------------------------
 * Action Types
 */

const FETCH_DATA_KISYOKEN = 'FETCH_DATA_KISYOKEN' as const;
const FETCH_DATA_KISYOKEN_REQUEST = 'FETCH_DATA_KISYOKEN_REQUEST' as const;
const FETCH_DATA_KISYOKEN_SUCCESS = 'FETCH_DATA_KISYOKEN_SUCCESS' as const;
const RENEW_DATA_KISYOKEN = 'RENEW_DATA_KISYOKEN' as const;

export const DataKiSyokenActionTypes = {
  FETCH_DATA_KISYOKEN,
  FETCH_DATA_KISYOKEN_REQUEST,
  FETCH_DATA_KISYOKEN_SUCCESS,
  RENEW_DATA_KISYOKEN,
};

/* ---------------------------------------------------------------
 * Action Creators
 */

/**
 * dataKiSyokenのデータを取得する
 */
function fetchDataKiSyokenAction(params?: DataKiSyokenSearchParams) {
  return {
    type: FETCH_DATA_KISYOKEN,
    payload: { params },
  };
}

function fetchDataKiSyokenRequestAction() {
  return {
    type: FETCH_DATA_KISYOKEN_REQUEST,
  };
}

function fetchDataKiSyokenSuccessAction(dataKiSyoken: DataKiSyokenResponse) {
  return {
    type: FETCH_DATA_KISYOKEN_SUCCESS,
    payload: { dataKiSyoken },
  };
}

function renewDataKiSyokenAction() {
  return {
    type: RENEW_DATA_KISYOKEN,
  };
}

export const DataKiSyokenActionCreators = {
  fetchDataKiSyokenAction,
  fetchDataKiSyokenRequestAction,
  fetchDataKiSyokenSuccessAction,
  renewDataKiSyokenAction,
};

/* ---------------------------------------------------------------
 * Actions
 */

export type FetchDataKiSyokenAction = ReturnType<
  typeof fetchDataKiSyokenAction
>;
type FetchDataKiSyokenRequestAction = ReturnType<
  typeof fetchDataKiSyokenRequestAction
>;
type FetchDataKiSyokenSuccessAction = ReturnType<
  typeof fetchDataKiSyokenSuccessAction
>;
type RenewDataKiSyokenAction = ReturnType<typeof renewDataKiSyokenAction>;

type DataKiSyokenAction =
  | FetchDataKiSyokenAction
  | FetchDataKiSyokenRequestAction
  | FetchDataKiSyokenSuccessAction
  | RenewDataKiSyokenAction;

/* ---------------------------------------------------------------
 * State
 */

type DataKiSyokenState = {
  loadingState: LoadingState;
  dataKiSyoken: DataKiSyokenResponse | undefined;
};

const initialState: DataKiSyokenState = {
  loadingState: 'prepare',
  dataKiSyoken: undefined,
};

/* ---------------------------------------------------------------
 * Selector
 */

/**
 * [機種別商圏] dataの全ての状態を取得する
 */
export function dataKiSyokenSelector(rootState: {
  dataKiSyoken: DataKiSyokenState;
}) {
  return rootState.dataKiSyoken;
}

/**
 * [機種別商圏] dataのローディング状態を取得する
 */
export const dataKiSyokenLoadingStateSelector = createSelector(
  dataKiSyokenSelector,
  ({ loadingState }) => loadingState
);

/**
 * [機種別商圏] dataのデータを取得する
 */
export const dataKiSyokenDataSelector = createSelector(
  dataKiSyokenSelector,
  ({ dataKiSyoken }) => dataKiSyoken
);

/* ---------------------------------------------------------------
 * Reducer
 */

export function dataKiSyokenReducer(
  state = initialState,
  action: DataKiSyokenAction
): DataKiSyokenState {
  switch (action.type) {
    case FETCH_DATA_KISYOKEN_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_DATA_KISYOKEN_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        dataKiSyoken: action.payload.dataKiSyoken,
      };
    case RENEW_DATA_KISYOKEN:
      return initialState;
    default:
      return state;
  }
}
