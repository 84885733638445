import { createSelector } from 'reselect';

import {
  DataKiSyokenKasidamaResponse,
  DataKiSyokenKasidamaSearchParams,
} from '../../domain/kiSyoken/types';
import { LoadingState } from '../../domain/schemas';

/* ---------------------------------------------------------------
 * Action Types
 */

const FETCH_DATA_KISYOKEN_KASIDAMA = 'FETCH_DATA_KISYOKEN_KASIDAMA' as const;
const FETCH_DATA_KISYOKEN_KASIDAMA_REQUEST =
  'FETCH_DATA_KISYOKEN_KASIDAMA_REQUEST' as const;
const FETCH_DATA_KISYOKEN_KASIDAMA_SUCCESS =
  'FETCH_DATA_KISYOKEN_KASIDAMA_SUCCESS' as const;
const RENEW_DATA_KISYOKEN_KASIDAMA = 'RENEW_DATA_KISYOKEN_KASIDAMA' as const;
const HIDE_DATA_KISYOKEN_KASIDAMA = 'HIDE_DATA_KISYOKEN_KASIDAMA' as const;

export const DataKiSyokenKasidamaActionTypes = {
  FETCH_DATA_KISYOKEN_KASIDAMA,
  FETCH_DATA_KISYOKEN_KASIDAMA_REQUEST,
  FETCH_DATA_KISYOKEN_KASIDAMA_SUCCESS,
  RENEW_DATA_KISYOKEN_KASIDAMA,
  HIDE_DATA_KISYOKEN_KASIDAMA,
};

/* ---------------------------------------------------------------
 * Action Creators
 */

/**
 * dataKiSyokenKasidamaのデータを取得する
 */
function fetchDataKiSyokenKasidamaAction(
  params?: DataKiSyokenKasidamaSearchParams
) {
  return {
    type: FETCH_DATA_KISYOKEN_KASIDAMA,
    payload: { params },
  };
}

function fetchDataKiSyokenKasidamaRequestAction() {
  return {
    type: FETCH_DATA_KISYOKEN_KASIDAMA_REQUEST,
  };
}

function fetchDataKiSyokenKasidamaSuccessAction(
  dataKiSyokenKasidama: DataKiSyokenKasidamaResponse
) {
  return {
    type: FETCH_DATA_KISYOKEN_KASIDAMA_SUCCESS,
    payload: { dataKiSyokenKasidama },
  };
}
/** チェーン店の情報を初期化 */
function renewDataKiSyokenKasidamaAction() {
  return {
    type: RENEW_DATA_KISYOKEN_KASIDAMA,
  };
}

function hideDataKiSyokenKasidamaAction() {
  return {
    type: HIDE_DATA_KISYOKEN_KASIDAMA,
  };
}

export const DataKiSyokenKasidamaActionCreators = {
  fetchDataKiSyokenKasidamaAction,
  fetchDataKiSyokenKasidamaRequestAction,
  fetchDataKiSyokenKasidamaSuccessAction,
  renewDataKiSyokenKasidamaAction,
  hideDataKiSyokenKasidamaAction,
};

/* ---------------------------------------------------------------
 * Actions
 */

export type FetchDataKiSyokenKasidamaAction = ReturnType<
  typeof fetchDataKiSyokenKasidamaAction
>;
type FetchDataKiSyokenKasidamaRequestAction = ReturnType<
  typeof fetchDataKiSyokenKasidamaRequestAction
>;
type FetchDataKiSyokenKasidamaSuccessAction = ReturnType<
  typeof fetchDataKiSyokenKasidamaSuccessAction
>;
type RenewDataKiSyokenKasidamaAction = ReturnType<
  typeof renewDataKiSyokenKasidamaAction
>;
type HideDataKiSyokenKasidamaAction = ReturnType<
  typeof hideDataKiSyokenKasidamaAction
>;

type DataKiSyokenKasidamaAction =
  | FetchDataKiSyokenKasidamaAction
  | FetchDataKiSyokenKasidamaRequestAction
  | FetchDataKiSyokenKasidamaSuccessAction
  | RenewDataKiSyokenKasidamaAction
  | HideDataKiSyokenKasidamaAction;

/* ---------------------------------------------------------------
 * State
 */

type DataKiSyokenKasidamaState = {
  loadingState: LoadingState;
  dataKiSyokenKasidama: DataKiSyokenKasidamaResponse | undefined;
};

const initialState: DataKiSyokenKasidamaState = {
  loadingState: 'prepare',
  dataKiSyokenKasidama: undefined,
};

/* ---------------------------------------------------------------
 * Selector
 */

/**
 * [機種別商圏] dataの全ての状態を取得する
 */
export function dataKiSyokenKasidamaSelector(rootState: {
  dataKiSyokenKasidama: DataKiSyokenKasidamaState;
}) {
  return rootState.dataKiSyokenKasidama;
}

/**
 * [機種別商圏] dataのローディング状態を取得する
 */
export const dataKiSyokenKasidamaLoadingStateSelector = createSelector(
  dataKiSyokenKasidamaSelector,
  ({ loadingState }) => loadingState
);

/**
 * [機種別商圏] dataを取得する
 *
 * 最終的にテーブルに表示させるデータはdataColumnsSwappedSelectorを使用してください
 */
export const dataKiSyokenKasidamaDataSelector = createSelector(
  dataKiSyokenKasidamaSelector,
  ({ dataKiSyokenKasidama }) => dataKiSyokenKasidama
);

/**
 * [機種別商圏] dataのsettingを取得する
 */
export const dataKiSyokenKasidamaSettingSelector = createSelector(
  dataKiSyokenKasidamaDataSelector,
  (data) => data?.setting
);

/**
 * [機種別商圏] チェーン店一覧のデータが存在するか取得
 */
export const dataKiSyokenKasidamaIsExistSelector = createSelector(
  dataKiSyokenKasidamaDataSelector,
  (data) => data?.data !== undefined
);

/* ---------------------------------------------------------------
 * Reducer
 */

export function dataKiSyokenKasidamaReducer(
  state = initialState,
  action: DataKiSyokenKasidamaAction
): DataKiSyokenKasidamaState {
  switch (action.type) {
    case FETCH_DATA_KISYOKEN_KASIDAMA_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_DATA_KISYOKEN_KASIDAMA_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        dataKiSyokenKasidama: action.payload.dataKiSyokenKasidama,
      };
    case RENEW_DATA_KISYOKEN_KASIDAMA:
      return initialState;
    case HIDE_DATA_KISYOKEN_KASIDAMA:
      return {
        ...state,
        dataKiSyokenKasidama: initialState.dataKiSyokenKasidama,
      };
    default:
      return state;
  }
}
