import { createSelector } from 'reselect';

import {
  DataPlanProgressTransition,
  DataPlanProgressTransitionParams,
} from '../../domain/dataPlanProgressTransition';
import { filterShowRows } from '../../domain/planProgressTransition/filterShowRows';
import { LoadingState } from '../../domain/schemas';

import { RootState } from '../../store';
import { planProgressReportsIsShowRowSelector } from '../ui/planProgressTransitionReportsSetting';

// Action Types

const FETCH_DATA_PLAN_PROGRESS_TRANSITION =
  'FETCH_DATA_PLAN_PROGRESS_TRANSITION' as const;
const FETCH_DATA_PLAN_PROGRESS_TRANSITION_REQUEST =
  'FETCH_DATA_PLAN_PROGRESS_TRANSITION_REQUEST' as const;
const FETCH_DATA_PLAN_PROGRESS_TRANSITION_SUCCESS =
  'FETCH_DATA_PLAN_PROGRESS_TRANSITION_SUCCESS' as const;
const RENEW_DATA_PLAN_PROGRESS_TRANSITION =
  'RENEW_DATA_PLAN_PROGRESS_TRANSITION' as const;

export const DataPlanProgressTransitionActionTypes = {
  FETCH_DATA_PLAN_PROGRESS_TRANSITION,
  FETCH_DATA_PLAN_PROGRESS_TRANSITION_REQUEST,
  FETCH_DATA_PLAN_PROGRESS_TRANSITION_SUCCESS,
  RENEW_DATA_PLAN_PROGRESS_TRANSITION,
};

// Action Creators

export function fetchDataPlanProgressTransitionAction(
  params: DataPlanProgressTransitionParams
) {
  return {
    type: FETCH_DATA_PLAN_PROGRESS_TRANSITION,
    payload: { params },
  };
}

function fetchDataPlanProgressTransitionRequestAction() {
  return {
    type: FETCH_DATA_PLAN_PROGRESS_TRANSITION_REQUEST,
  };
}

function fetchDataPlanProgressTransitionSuccessAction(
  dataPlanProgressTransition: DataPlanProgressTransition
) {
  return {
    type: FETCH_DATA_PLAN_PROGRESS_TRANSITION_SUCCESS,
    payload: { dataPlanProgressTransition },
  };
}

function renewDataPlanProgressTransitionAction() {
  return {
    type: RENEW_DATA_PLAN_PROGRESS_TRANSITION,
  };
}

export const DataPlanProgressTransitionActionCreators = {
  fetchDataPlanProgressTransitionAction,
  fetchDataPlanProgressTransitionRequestAction,
  fetchDataPlanProgressTransitionSuccessAction,
  renewDataPlanProgressTransitionAction,
};

// Actions

export type FetchDataPlanProgressTransitionAction = ReturnType<
  typeof fetchDataPlanProgressTransitionAction
>;

type DataPlanProgressTransitionAction =
  | FetchDataPlanProgressTransitionAction
  | ReturnType<typeof fetchDataPlanProgressTransitionRequestAction>
  | ReturnType<typeof fetchDataPlanProgressTransitionSuccessAction>
  | ReturnType<typeof renewDataPlanProgressTransitionAction>;

// State

type DataPlanProgressTransitionState = {
  loadingState: LoadingState;
  dataPlanProgressTransition?: DataPlanProgressTransition;
};

const initialState: DataPlanProgressTransitionState = {
  loadingState: 'prepare',
  dataPlanProgressTransition: undefined,
};

// Selector

export const dataPlanProgressTransitionAllSelector = (state: RootState) => {
  return state.dataPlanProgressTransition;
};

export const dataPlanProgressTransitionSelector = (state: RootState) => {
  return state.dataPlanProgressTransition.dataPlanProgressTransition;
};

export const dataPlanProgressTransitionLoadingStateSelector = (
  state: RootState
) => {
  return state.dataPlanProgressTransition.loadingState;
};

/**
 * レスポンスの検索条件を取得
 */
export const dataPlanProgressTransitionSettingSelector = createSelector(
  dataPlanProgressTransitionSelector,
  (data) => data?.setting
);

/**
 * レスポンスの検索条件からエリアコードを取得
 */
export const dataPlanProgressTransitionSettingAreaSelector = createSelector(
  dataPlanProgressTransitionSettingSelector,
  (setting) => setting?.areas
);

/**
 * data.rowsを表示対象のみフィルターしたデータを取得
 * 通常は使用しません selectedFieldsDataPlanProgressTransitionSettingSelector を使用してください
 */
export const filterShowRowsDataPlanProgressTransitionSettingSelector =
  createSelector(
    dataPlanProgressTransitionSelector,
    planProgressReportsIsShowRowSelector,
    (data, isShowRow): DataPlanProgressTransition | undefined => {
      if (!data) {
        return undefined;
      }
      const rows = filterShowRows(data.data.rows, isShowRow);
      return { ...data, data: { ...data.data, rows } };
    }
  );

// Reducer

export function dataPlanProgressTransitionReducer(
  state = initialState,
  action: DataPlanProgressTransitionAction
): DataPlanProgressTransitionState {
  switch (action.type) {
    case FETCH_DATA_PLAN_PROGRESS_TRANSITION_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_DATA_PLAN_PROGRESS_TRANSITION_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        dataPlanProgressTransition: action.payload.dataPlanProgressTransition,
      };
    case RENEW_DATA_PLAN_PROGRESS_TRANSITION:
      return initialState;
    default:
      return state;
  }
}
