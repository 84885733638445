import { createSelector } from 'reselect';

import { ClarisApiError } from '../../domain/error';
import {
  RagEvaluationApiRequestParams,
  RagEvaluationItem,
  RagEvaluationState,
} from '../../domain/rag/types';

import { RootState } from '../../store';

/* ---------------------------------------------------------------
 * Action Types
 */

const POST_EVALUATION_RAG = 'POST_EVALUATION_RAG' as const;
const POST_EVALUATION_RAG_REQUEST = 'POST_EVALUATION_RAG_REQUEST' as const;
const POST_EVALUATION_RAG_SUCCESS = 'POST_EVALUATION_RAG_SUCCESS' as const;
const POST_EVALUATION_RAG_FAILURE = 'POST_EVALUATION_RAG_FAILURE' as const;
const POST_EVALUATION_RAG_RENEW = 'POST_EVALUATION_RAG_RENEW' as const;

export const RagEvaluationActionTypes = {
  POST_EVALUATION_RAG,
  POST_EVALUATION_RAG_REQUEST,
  POST_EVALUATION_RAG_SUCCESS,
  POST_EVALUATION_RAG_FAILURE,
  POST_EVALUATION_RAG_RENEW,
};

/* ---------------------------------------------------------------
 * Action Creators
 */

/**
 * 評価表APIのPOSTリクエスト
 */
function postRagEvaluationRagAction(evaluation: RagEvaluationApiRequestParams) {
  return {
    type: POST_EVALUATION_RAG,
    payload: { evaluation },
  };
}

/** リクエスト時に該当のチャットのローディングステータスをローディング中に変更する */
function postRagEvaluationRagRequestAction(
  evaluation: RagEvaluationApiRequestParams
) {
  return {
    type: POST_EVALUATION_RAG_REQUEST,
    payload: { evaluation },
  };
}

/** 成功時に該当のチャットのローディングステータスを通常に更新する */
function postRagEvaluationRagSuccessAction(
  evaluation: RagEvaluationApiRequestParams
) {
  return {
    type: POST_EVALUATION_RAG_SUCCESS,
    payload: { evaluation },
  };
}

/** store初期化処理 */
function renewRagEvaluationRagAction() {
  return {
    type: POST_EVALUATION_RAG_RENEW,
  };
}

/** 失敗時のアクション */
function postRagEvaluationRagFailureAction(
  evaluation: RagEvaluationApiRequestParams
) {
  return {
    type: POST_EVALUATION_RAG_FAILURE,
    payload: { evaluation },
  };
}

export const RagEvaluationActions = {
  postRagEvaluationRagAction,
  postRagEvaluationRagRequestAction,
  postRagEvaluationRagSuccessAction,
  postRagEvaluationRagFailureAction,
  renewRagEvaluationRagAction,
};

export type PostRagEvaluationRagAction = ReturnType<
  typeof postRagEvaluationRagAction
>;

export type RagEvaluationAction =
  | PostRagEvaluationRagAction
  | ReturnType<typeof postRagEvaluationRagRequestAction>
  | ReturnType<typeof postRagEvaluationRagSuccessAction>
  | ReturnType<typeof postRagEvaluationRagFailureAction>
  | ReturnType<typeof renewRagEvaluationRagAction>;

/* ---------------------------------------------------------------
 * Selectors
 */

const getRagEvaluation = (state: RootState) => state.ragEvaluation;

export const ragEvaluationSelector = createSelector(
  [getRagEvaluation],
  (ragEvaluation) => ragEvaluation
);

/* ---------------------------------------------------------------
 * Reducer
 */
const initial: RagEvaluationState = {
  evaluation: [],
};

export function ragEvaluationReducer(
  state = initial,
  action: RagEvaluationAction
): RagEvaluationState {
  switch (action.type) {
    // 評価表APIのPOSTリクエスト時に、該当の情報をstoreに保存
    case POST_EVALUATION_RAG_REQUEST: {
      // 評価情報のローディングステータスをローディング中に更新
      const payloadEvaluation = action.payload.evaluation;

      // 再評価時にも追加処理が呼び出されるため、storeに同じ情報が重複している場合は現在のstateをそのまま返す
      const isAlreadyRegistered = state.evaluation.some(
        (evaluation) => evaluation.id === payloadEvaluation.id
      );

      /** 重複があり、かつ評価をリセットする場合は、storeから該当の情報を削除
       * 評価リセットは基本この重複の中でのみ発生するため、この処理はここで行う
       */
      if (isAlreadyRegistered && payloadEvaluation.feedback === '') {
        const newEvaluationItems: RagEvaluationItem[] = state.evaluation.filter(
          (evaluation) => evaluation.id !== payloadEvaluation.id
        );

        return {
          ...state,
          evaluation: newEvaluationItems,
        };
      }

      // 重複がある場合は何もしない
      if (isAlreadyRegistered) {
        return state;
      }

      // stateを更新
      const newEvaluation: RagEvaluationItem = {
        ...payloadEvaluation,
        isEvaluated: false,
        loadingStatus: 'loading',
      };

      // 新規の評価情報の場合は追加
      return {
        ...state,
        evaluation: [...state.evaluation, newEvaluation],
      };
    }

    // 評価表APIのPOSTリクエスト成功時に、該当の情報のローディングステータスを通常に更新
    case POST_EVALUATION_RAG_SUCCESS: {
      const newEvaluation = action.payload.evaluation;

      /**
       * 評価情報のローディングステータスを通常に更新
       * すでにstoreに登録されている情報の中から、IDを比較し、該当の情報のローディングステータスを完了に更新
       */
      const newEvaluationItems: RagEvaluationItem[] = state.evaluation.map(
        (evaluation) =>
          evaluation.id === newEvaluation.id
            ? {
                ...evaluation,
                feedback: newEvaluation.feedback,
                loadingStatus: 'loaded',
                isEvaluated: true,
              }
            : evaluation
      );

      return {
        ...state,
        evaluation: newEvaluationItems,
      };
    }
    // 評価表APIのPOSTリクエスト失敗時に、該当の情報のローディングステータスをエラーに更新
    case POST_EVALUATION_RAG_FAILURE: {
      const newEvaluation = action.payload.evaluation;
      const findEvaluationById = state.evaluation.find(
        (evaluation) => evaluation.id === newEvaluation.id
      );
      // 該当の情報がない場合は何もしない
      if (!findEvaluationById) {
        return state;
      }

      return {
        ...state,
        evaluation: [
          ...state.evaluation,
          {
            ...findEvaluationById,
            loadingStatus: 'prepare',
          },
        ],
      };
    }
    // store初期化処理
    case POST_EVALUATION_RAG_RENEW: {
      return initial;
    }
    default: {
      return state;
    }
  }
}
