import { DataKiSyokenKasidamaResponse } from './types';

export const dataKiSyokenKasidamaSwapColumnsOrder = (
  responseData: DataKiSyokenKasidamaResponse,
  columnsOrder: string[]
): DataKiSyokenKasidamaResponse => {
  if (columnsOrder.length === 0) {
    return responseData;
  }

  const indexes = columnsOrder
    .map((code) =>
      responseData.data.columns.findIndex((col) => col.code === code)
    )
    .filter((index) => index !== -1);

  return {
    ...responseData,
    data: {
      ...responseData.data,
      columns: indexes.map((index) => responseData.data.columns[index]),
      rows: responseData.data.rows.map((row) => ({
        ...row,
        data: indexes.map((index) => row.data[index]),
      })),
    },
  };
};
