import { call, fork, put, select, take, takeEvery } from 'redux-saga/effects';

import { PlanProgressTransitionGraph } from '../domain/dataPlanProgressGraph';
import {
  DataPlanProgressTransition,
  DataPlanProgressTransitionParams,
} from '../domain/dataPlanProgressTransition';
import { FavoriteItem } from '../domain/favorites';
import { dataPlanProgressTransitionDefaultValue } from '../domain/planProgressTransition/defaultValue';
import { filterFieldsByCategory } from '../domain/planProgressTransition/filterFieldsByCategory';
import { makePlanProgressDateRangeParams } from '../domain/planProgressTransition/makeDateRange';
import { PlanProgressTransitionFavorite } from '../domain/planProgressTransition/types';
import { LoadingState } from '../domain/schemas';
import { Option } from '../domain/schemas';
import { SettingsOptionsPlanProgressTransition } from '../domain/settingsOptionsPlanProgressTransition';
import { ShuOption, detectCategoryFromShuOption } from '../domain/shu';
import { User } from '../domain/user';

import {
  DataPlanProgressTransitionActionCreators,
  dataPlanProgressTransitionLoadingStateSelector,
  dataPlanProgressTransitionSelector,
  dataPlanProgressTransitionSettingSelector,
} from '../redux/server/dataPlanProgressTransition';
import {
  DataPlanProgressTransitionGraphActionCreators,
  dataPlanProgressTransitionGraphAllSelector,
  dataPlanProgressTransitionGraphSearchConditionSelector,
} from '../redux/server/dataPlanProgressTransitionGraph';
import { SettingsCommentsMonthToDateActionCreators } from '../redux/server/settingsCommentsMonthToDate';
import {
  SettingsOptionsPlanProgressTransitionActionCreators,
  SettingsOptionsPlanProgressTransitionActionTypes,
  settingsOptionsPlanProgressTransitionLoadingStateSelector,
  settingsOptionsPlanProgressTransitionsettingsOptionsSelector,
} from '../redux/server/settingsOptionsPlanProgressTransition';
import { ShortenedUrlActionCreators } from '../redux/server/shortenedUrl';
import { ErrorActionCreators } from '../redux/ui/error';
import {
  ChangePlanProgressTransitionReportsCurrentHallsAction,
  ChangePlanProgressTransitionReportsCurrentShuAction,
  ChangePlanProgressTransitionReportsDataRangeAction,
  ChangePlanProgressTransitionReportsFavoriteAction,
  CreatePlanProgressTransitionReportsShortenedUrlAction,
  PlanProgressTransitionReportsSettingActionCreators,
  PlanProgressTransitionReportsSettingActionTypes,
  PlanProgressTransitionReportsSettingState,
  SaveAsPlanProgressTransitionReportsFavoriteAction,
  SavePlanProgressTransitionReportsFavoriteAction,
  SearchPlanProgressTransitionReportsAction,
  SearchResetPlanProgressTransitionReportsAction,
  defaultShu,
  planProgressReportsGraphShowNumberLabelSelector,
  planProgressReportsGraphShowPlanSelector,
  planProgressReportsGraphShowTotalSelector,
  planProgressTransitionReportsCurrentShuSelector,
  planProgressTransitionReportsSearchConditionSelector,
  planProgressTransitionReportsSelectedFavoriteDataSelector,
  planProgressTransitionReportsSelectedFavoriteSelector,
  planProgressTransitionReportsSelectedFavoriteSettingSelector,
  planProgressTransitionReportsSettingCurrentHallsSelector,
  planProgressTransitionReportsSettingSelector,
} from '../redux/ui/planProgressTransitionReportsSetting';
import { SettingsFavoritesActionCreators } from '../redux/ui/settingsFavorites';
import { UserActionTypes, userSelector } from '../redux/ui/user';
import { compressToEncodedURIComponent } from '../utils/compressToEncodedURIComponent';
import { customSelect } from '../utils/customSelect';
import { isValidArea } from '../utils/isValidArea';
import { selectShu2SearchCondition } from '../utils/shu';

/**
 * 初回表示
 */
function* initActionSaga() {
  const loadingState: LoadingState = yield select(
    dataPlanProgressTransitionLoadingStateSelector
  );

  // ローディング中または既にエラーの場合は初回取得しない
  if (loadingState !== 'prepare') {
    return;
  }

  const user: User | undefined = yield select(userSelector);

  // ユーザがセットされていない場合は、初回を待機する
  if (!user?.userId) {
    yield take(UserActionTypes.SET_USER);
  }

  const { hallCodeInCharge }: User = yield select(userSelector);
  const halls =
    hallCodeInCharge && hallCodeInCharge.length > 0 ? [hallCodeInCharge] : [];

  const defaultValue = dataPlanProgressTransitionDefaultValue().setting;

  const param: DataPlanProgressTransitionParams = {
    ...defaultValue,
    halls,
  };

  yield put(
    DataPlanProgressTransitionActionCreators.fetchDataPlanProgressTransitionAction(
      param
    )
  );

  yield put(
    DataPlanProgressTransitionGraphActionCreators.fetchDataPlanProgressGraphAction(
      param
    )
  );

  yield put(
    PlanProgressTransitionReportsSettingActionCreators.savePlanProgressTransitionReportsSearchConditionAction(
      param
    )
  );

  yield fork(fetchCommentSaga, {
    hallCodes: halls,
    year: defaultValue.year,
    month: defaultValue.month,
  });
}

/**
 * 検索ボタンクリック
 */
function* searchActionSaga(action: SearchPlanProgressTransitionReportsAction) {
  const params = action.payload.params;

  // 検索条件をStoreに格納する
  yield put(
    PlanProgressTransitionReportsSettingActionCreators.savePlanProgressTransitionReportsSearchConditionAction(
      params
    )
  );

  // 選択中の種別・種別グループを取得する
  const currentShu: ShuOption = yield select(
    planProgressTransitionReportsCurrentShuSelector
  );

  // 検索
  yield put(
    DataPlanProgressTransitionActionCreators.fetchDataPlanProgressTransitionAction(
      {
        ...params,
        ...selectShu2SearchCondition(currentShu),
      }
    )
  );

  // 推移グラフデータ取得
  yield put(
    DataPlanProgressTransitionGraphActionCreators.fetchDataPlanProgressGraphAction(
      {
        ...params,
        ...selectShu2SearchCondition(currentShu),
      }
    )
  );

  yield fork(fetchCommentSaga, {
    hallCodes: params.halls ?? [],
    year: params?.year,
    month: params?.month,
  });
}

/**
 * 種別変更
 */
function* changeCurrentShuSaga(
  action: ChangePlanProgressTransitionReportsCurrentShuAction
) {
  // レスポンスから現在の検索条件を取得する
  // NOTE: 店舗スライダ導入により、検索フォーム上の選択店舗と実際に検索された店舗が一致しない
  // そのため検索条件を維持したい場合はレスポンスにある店舗を見る必要がある
  const params: DataPlanProgressTransition | undefined = yield select(
    dataPlanProgressTransitionSelector
  );

  //グラフの検索条件
  const planProgressGraph: PlanProgressTransitionGraph = yield select(
    dataPlanProgressTransitionGraphAllSelector
  );

  // 種別を変更して検索
  yield put(
    DataPlanProgressTransitionActionCreators.fetchDataPlanProgressTransitionAction(
      {
        ...(params ? params.setting : {}),
        ...selectShu2SearchCondition(action.payload.shu),
      }
    )
  );

  // 推移グラフデータ取得
  yield put(
    DataPlanProgressTransitionGraphActionCreators.fetchDataPlanProgressGraphAction(
      {
        ...(params ? params.setting : {}),
        ...selectShu2SearchCondition(action.payload.shu),
        // action.payload.categoryがallの場合かつplanProgressGraph.setting.fieldがdtmart(出玉率)の場合はarariGkForHoseiAndGenkin(粗利合計)を設定する(粗利合計はデフォルトの検索条件)
        // それ以外はplanProgressGraph.setting.fieldを設定する
        // TODO :P/S種別選択&グラフ項目を出玉率に選択している状態から種別を”種別全体”に変更した場合に種別全体では出玉率が選択不可なので整合性を合わせつために条件分岐させているが,暫定的な対応方法なので、他に良い方法があれば変更する
        // https://www.notion.so/1c0a4e41249f81a79ab2e8834b00a419
        field:
          action.payload.category === 'all' &&
          planProgressGraph.setting.field === 'dtmart'
            ? 'arariGkForHoseiAndGenkin'
            : planProgressGraph.setting.field,
      }
    )
  );
}

/**
 * 変更後の店舗でデータを取得する
 */
function* changeCurrentHallsSaga(
  action: ChangePlanProgressTransitionReportsCurrentHallsAction
) {
  // 店舗が選択されていない場合は処理を中断
  if (action.payload.halls === undefined) return;

  // 現在の検索条件
  const params: DataPlanProgressTransition = yield select(
    dataPlanProgressTransitionSelector
  );

  //グラフの検索条件
  // customSelectに置き換え
  const planProgressGraph = yield* customSelect(
    dataPlanProgressTransitionGraphAllSelector
  );
  const { halls } = action.payload;
  // 店舗を変更して検索
  yield put(
    DataPlanProgressTransitionActionCreators.fetchDataPlanProgressTransitionAction(
      {
        ...(params ? params.setting : {}),
        halls,
      }
    )
  );
  // 推移グラフデータ取得
  yield put(
    DataPlanProgressTransitionGraphActionCreators.fetchDataPlanProgressGraphAction(
      {
        ...(params ? params.setting : {}),
        halls,
        field: planProgressGraph?.setting.field,
      }
    )
  );

  yield fork(fetchCommentSaga, {
    hallCodes: halls,
    year: params?.setting.year,
    month: params?.setting.month,
  });
}
/**
 * 期間スライダーで変更後のデータを取得する
 */
function* changeCurrentDataRangeSaga(
  action: ChangePlanProgressTransitionReportsDataRangeAction
) {
  // 現在の検索条件
  const params: DataPlanProgressTransition = yield select(
    dataPlanProgressTransitionSelector
  );

  //店舗選択リストで選択中の店舗を取得
  const currentHall: string[] = yield select(
    planProgressTransitionReportsSettingCurrentHallsSelector
  );

  const searchConditionParams: DataPlanProgressTransitionParams = yield select(
    planProgressTransitionReportsSearchConditionSelector
  );

  const updatedSearchConditionParams = {
    ...searchConditionParams,
    year: action.payload.year,
    month: action.payload.month,
  };

  // 検索条件をStoreに格納する
  yield put(
    PlanProgressTransitionReportsSettingActionCreators.savePlanProgressTransitionReportsSearchConditionAction(
      updatedSearchConditionParams
    )
  );

  //グラフの検索条件
  const planProgressGraph: PlanProgressTransitionGraph = yield select(
    dataPlanProgressTransitionGraphAllSelector
  );
  // 期間を変更して検索
  yield put(
    DataPlanProgressTransitionActionCreators.fetchDataPlanProgressTransitionAction(
      {
        ...(params.setting || {}),
        halls: currentHall,
        year: action.payload.year,
        month: action.payload.month,
      }
    )
  );

  // 推移グラフデータ取得
  yield put(
    DataPlanProgressTransitionGraphActionCreators.fetchDataPlanProgressGraphAction(
      {
        ...(params.setting || {}),
        year: action.payload.year,
        month: action.payload.month,
        field: planProgressGraph.setting.field,
      }
    )
  );

  yield fork(fetchCommentSaga, {
    hallCodes: currentHall,
    year: action.payload.year,
    month: action.payload.month,
  });
}

/**
 * コメントを取得する
 */
function* fetchCommentSaga({
  hallCodes,
  year,
  month,
}: {
  hallCodes: string[];
  year: number | undefined;
  month: number | undefined;
}) {
  // コメントの取得、店舗が1件のみ選択されている場合に表示する
  if (hallCodes.length === 1) {
    yield put(
      SettingsCommentsMonthToDateActionCreators.fetchSettingsCommentsMonthToDateAction(
        {
          hallCode: hallCodes[0],
          year: year?.toString() ?? new Date().getFullYear().toString(),
          month: month?.toString() ?? (new Date().getMonth() + 1).toString(),
        }
      )
    );
  } else {
    yield put(
      SettingsCommentsMonthToDateActionCreators.renewSettingsCommentsMonthToDateAction()
    );
  }
}
/**
 *
 * お気に入りのpageSettingを取得する共通化処理
 */
function* settingsPageSaga() {
  // 現在の予定進捗推移の設定すべて
  const pageSetting = yield* customSelect(
    planProgressTransitionReportsSettingSelector
  );
  // テーブルの検索条件
  const dataPlanProgressParams = yield* customSelect(
    dataPlanProgressTransitionSettingSelector
  );

  // グラフの検索条件
  const graphParams = yield* customSelect(
    dataPlanProgressTransitionGraphSearchConditionSelector()
  );

  // グラフのラベル表示条件
  const numberLabel = yield* customSelect(
    planProgressReportsGraphShowNumberLabelSelector
  );
  const planLabel = yield* customSelect(
    planProgressReportsGraphShowPlanSelector
  );
  const totalLabel = yield* customSelect(
    planProgressReportsGraphShowTotalSelector
  );
  // こんなケースはないが念の為
  if (pageSetting.currentShu === undefined) return;
  // レスポンスの検索条件がundefinedの場合に動作しない
  if (dataPlanProgressParams === undefined) return;

  const favorite: FavoriteItem['pageSetting'] = {
    planProgressTransition: {
      searchCondition: pageSetting.searchCondition,
      selectedDateRangeParams: {
        dateRange: pageSetting.selectedDateRangeParams.dateRange,
      },
      currentShu: pageSetting.currentShu,
      currentHalls: pageSetting.currentHalls,
      showNumberLabel: numberLabel,
      showPlan: planLabel,
      showTotal: totalLabel,
      dataPlanProgress: {
        setting: dataPlanProgressParams,
      },
      dataPlanProgressGraph: {
        setting: graphParams,
      },
      isShowRow: pageSetting.isShowRow,
      selectedFields: pageSetting.selectedFields,
    },
  };
  return favorite;
}

/**
 * お気に入り新規保存時の処理
 */
function* saveAsFavoriteSaga(
  action: SaveAsPlanProgressTransitionReportsFavoriteAction
) {
  // 現在の予定進捗推移の設定すべて
  const pageSetting: FavoriteItem['pageSetting'] = yield call(settingsPageSaga);

  if (pageSetting == null) return;

  // 変更後のお気に入りデータ
  const favorite: FavoriteItem = {
    name: action.payload.name,
    isShared: action.payload.isShared,
    pageName: '予定進捗推移',
    pageSetting,
    memo: action.payload.memo,
    privatelySharedUsers: action.payload.sharedUser,
  };

  // お気に入りを新規保存
  yield put(
    SettingsFavoritesActionCreators.postSettingsFavoritesAction([favorite], {})
  );
}

/**
 * お気に入り上書き保存時の処理
 */
function* saveFavoriteSaga(
  action: SavePlanProgressTransitionReportsFavoriteAction
) {
  // 選択中のお気に入りID
  const selectedFavoriteId: number | undefined = yield select(
    planProgressTransitionReportsSelectedFavoriteSelector
  );
  // 選択中のお気に入り
  const selectedFavorite: FavoriteItem | undefined = yield select(
    planProgressTransitionReportsSelectedFavoriteDataSelector
  );

  // 現在の予定進捗推移の設定すべて
  const pageSetting: FavoriteItem['pageSetting'] = yield call(settingsPageSaga);

  // デフォルトの時は動作しない
  if (selectedFavoriteId === undefined || selectedFavorite === undefined)
    return;

  let favorite: FavoriteItem = {};
  if (action.payload.mode === 'memo') {
    // メモのみ更新モードでの変更後のお気に入りデータ
    favorite = {
      ...selectedFavorite,
      memo: action.payload.memo,
    };
  } else {
    // 変更後のお気に入りデータ
    favorite = {
      name: action.payload.name,
      isShared: action.payload.isShared,
      pageName: '予定進捗推移',
      pageSetting,
      memo: action.payload.memo,
      privatelySharedUsers: action.payload.sharedUser,
    };
  }

  // お気に入りを上書き
  yield put(
    SettingsFavoritesActionCreators.patchSettingsFavoritesAction(
      selectedFavoriteId,
      favorite
    )
  );
}

/**
 * お気に入り 適用時の処理
 */
function* applyFavoriteByIdSaga(
  action: ChangePlanProgressTransitionReportsFavoriteAction
) {
  // 選択されたお気に入りID
  const selectedFavoriteId = action.payload.favorite;

  // 現在選択中のお気に入りデータを取得
  const favorite: PlanProgressTransitionFavorite | undefined = yield select(
    planProgressTransitionReportsSelectedFavoriteSettingSelector
  );

  // デフォルトのお気に入りを選択した場合、リセットと同じ動作を行う
  if (selectedFavoriteId === undefined) {
    // 担当店舗取得
    const user: User | undefined = yield select(userSelector);
    const { searchCondition: halls }: SettingsOptionsPlanProgressTransition =
      yield select(
        settingsOptionsPlanProgressTransitionsettingsOptionsSelector
      );

    // settingsOptionと担当店舗から担当店舗の情報を取得
    const findHallCodeInCharge = halls.halls.find(
      (hall) => hall.code === user?.hallCodeInCharge
    );

    // 店舗設定
    const favoriteHalls = () => {
      if (favorite?.searchCondition.halls) {
        return favorite?.searchCondition.halls;
      }
      // 担当店舗が設定されている場合は担当店舗を設定
      else if (user?.hallCodeInCharge && findHallCodeInCharge) {
        return [findHallCodeInCharge.code];
      } else {
        const selectedHall = halls.halls.at(0);
        if (!selectedHall) {
          throw new Error('settingsOptionsに該当する店舗が存在しません');
        }
        return [];
      }
    };
    yield put(
      PlanProgressTransitionReportsSettingActionCreators.searchResetPlanProgressTransitionReportsAction(
        favoriteHalls()
      )
    );
    yield put(
      PlanProgressTransitionReportsSettingActionCreators.resetPlanProgressTransitionReportsCurrentHallsAction()
    );
    // 表示項目選択を初期化
    yield put(
      PlanProgressTransitionReportsSettingActionCreators.resetPlanProgressTransitionReportsFieldsAction()
    );
    return;
  }

  if (favorite == null) {
    throw new Error('該当するお気に入りが見つかりません');
  }

  yield fork(applyFavoriteSaga, favorite);
}

/**
 * PlanProgressTransitionFavoriteの内容を実際に反映させる
 */
export function* applyFavoriteSaga(favorite: PlanProgressTransitionFavorite) {
  const loadingState: LoadingState = yield select(
    settingsOptionsPlanProgressTransitionLoadingStateSelector
  );

  // settingsOptionsが取得されていない場合は、取得する
  if (loadingState === 'prepare') {
    yield put(
      SettingsOptionsPlanProgressTransitionActionCreators.fetchSettingsOptionsPlanProgressTransitionAction()
    );
    yield take(
      SettingsOptionsPlanProgressTransitionActionTypes.FETCH_SETTINGS_OPTIONS_PLAN_PROGRESS_TRANSITION_SUCCESS
    );
  }

  if (!isValidArea(favorite.searchCondition.areas)) {
    yield put(
      ErrorActionCreators.setError(
        'お気に入りの検索条件に存在しないエリアが指定されています。'
      )
    );
  }

  const year = favorite.searchCondition.year;
  const month = favorite.searchCondition.month;
  if (year === undefined || month === undefined) {
    yield put(
      ErrorActionCreators.setError(
        'お気に入りの検索条件に適切な年月が指定されていません。'
      )
    );
    return;
  }

  // 検索期間がカスタム以外の場合は、お気に入り選択時を起点に期間を再算出
  const dateRangeParams = makePlanProgressDateRangeParams(
    favorite.selectedDateRangeParams.dateRange,
    year,
    month
  );

  const currentHalls = favorite.currentHalls;
  if (currentHalls) {
    const hallCodes = currentHalls.at(0);
    if (hallCodes === undefined) {
      yield put(
        ErrorActionCreators.setError(
          'お気に入りの検索条件に適切な店舗が指定されていません。'
        )
      );
      return;
    }

    // コメント適用は単店舗が指定されている場合のみ行うため
    if (currentHalls.length === 1) {
      // コメントの適用
      yield put(
        SettingsCommentsMonthToDateActionCreators.fetchSettingsCommentsMonthToDateAction(
          {
            hallCode: hallCodes,
            year: dateRangeParams.year.toString(),
            month: dateRangeParams.month.toString(),
          }
        )
      );
    }
  }

  const settingsOptions: SettingsOptionsPlanProgressTransition = yield select(
    settingsOptionsPlanProgressTransitionsettingsOptionsSelector
  );

  let defaultFields: Option[] = [];

  // selectedFieldsが存在しない場合場合過去のお気に入りを考慮するためにデフォルトの選択項目を設定
  // また過去のお気に入りのみデフォルトでSP項目を追加する
  if (!favorite.selectedFields) {
    // メインテーブルの種別ドロップダウンで選択した種別がパチンコかスロットか
    // currentShuのnameがcodeが空の場合(種別全体)はdetectCategoryFromShuOptionを実行しないでcategoryにallを設定
    const category = favorite.currentShu?.code
      ? detectCategoryFromShuOption(
          favorite.currentShu,
          settingsOptions.searchCondition.shuGroupList ?? []
        )
      : 'all';

    // transitionのisDefaultがtrueのOption[]を使用
    const isDefaultFields = settingsOptions.fields.transition.filter(
      (field) => field.isDefault
    );

    // SP項目を追加(spRiekiRituとspTmaArari)
    // 過去のお気に入りのみSP項目を追加する理由として表示項目追加後のデフォルト項目にはSP項目は入っていないが過去はSP項目が入っていた
    // https://daikoku-cloud.slack.com/archives/C026YMFKL8L/p1740528824698379?thread_ts=1740480390.543249&cid=C026YMFKL8L
    const additionalFields = ['spRiekiRitu', 'spTmaArari']
      .map((code) =>
        settingsOptions.fields.transition.find((field) => field.code === code)
      )
      .filter((field): field is Option => field !== undefined);

    // categoryの種別に応じてフィルタリングする
    const isDefaultFieldsFiltered = filterFieldsByCategory(
      isDefaultFields,
      category
    );

    defaultFields = isDefaultFieldsFiltered.concat(additionalFields);
  }

  // 検索条件をStoreに格納する
  yield put(
    PlanProgressTransitionReportsSettingActionCreators.savePlanProgressTransitionReportsSearchConditionAction(
      {
        ...favorite.searchCondition,
        year: dateRangeParams.year,
        month: dateRangeParams.month,
      }
    )
  );

  // 店舗を反映
  yield put(
    PlanProgressTransitionReportsSettingActionCreators.selectPlanProgressTransitionReportsCurrentShuAction(
      favorite.currentShu ?? defaultShu
    )
  );

  yield put(
    PlanProgressTransitionReportsSettingActionCreators.savePlanProgressTransitionReportsDateRangeAction(
      favorite.selectedDateRangeParams.dateRange
    )
  );

  // 検索
  yield put(
    DataPlanProgressTransitionActionCreators.fetchDataPlanProgressTransitionAction(
      {
        ...favorite.searchCondition,
        year: dateRangeParams.year,
        month: dateRangeParams.month,
        halls: favorite.currentHalls,
        ...selectShu2SearchCondition(favorite.currentShu ?? defaultShu),
      }
    )
  );

  // 推移グラフデータの取得
  const dataPlanProgressTransitionGraph =
    favorite.dataPlanProgressGraph?.setting;
  if (dataPlanProgressTransitionGraph !== undefined) {
    yield put(
      DataPlanProgressTransitionGraphActionCreators.fetchDataPlanProgressGraphAction(
        {
          ...dataPlanProgressTransitionGraph,
          year: dateRangeParams.year,
          month: dateRangeParams.month,
        }
      )
    );
    // UIのグラフラベルの表示
    yield put(
      PlanProgressTransitionReportsSettingActionCreators.selectPlanGraphShowPlanAction(
        favorite.showPlan
      )
    );
    yield put(
      PlanProgressTransitionReportsSettingActionCreators.selectPlanGraphShowNumberLabelAction(
        favorite.showNumberLabel
      )
    );
    yield put(
      PlanProgressTransitionReportsSettingActionCreators.selectPlanGraphShowTotalAction(
        favorite.showTotal
      )
    );
  }
  // 行の表示管理フラグの反映
  yield put(
    PlanProgressTransitionReportsSettingActionCreators.selectPlanProgressTransitionReportsIsShowRowAction(
      favorite.isShowRow ?? { previousYearSameMonth: true, forecast: true }
    )
  );

  // 表示項目の反映
  yield put(
    PlanProgressTransitionReportsSettingActionCreators.changePlanProgressTransitionReportsFieldsAction(
      favorite.selectedFields ?? defaultFields
    )
  );
}
/**
 * リセットボタン押下時、デフォルトの検索条件または選択中のお気に入りでデータを取得する
 */
function* resetSearchActionSaga(
  action: SearchResetPlanProgressTransitionReportsAction
) {
  const favoriteId: number | undefined = yield select(
    planProgressTransitionReportsSelectedFavoriteSelector
  );

  // お気に入り選択時のリセットボタン押下時
  if (favoriteId !== undefined) {
    yield put(
      PlanProgressTransitionReportsSettingActionCreators.changePlanProgressTransitionReportsFavoriteAction(
        favoriteId
      )
    );
    return;
  }

  // デフォルトが選択されている場合は検索条件を初期化
  yield put(
    PlanProgressTransitionReportsSettingActionCreators.resetPlanProgressTransitionReportsSearchConditionAction()
  );

  // 種別を初期化
  yield put(
    PlanProgressTransitionReportsSettingActionCreators.resetPlanProgressTransitionReportsCurrentShuAction()
  );

  // 期間タイプを初期化
  yield put(
    PlanProgressTransitionReportsSettingActionCreators.resetPlanProgressTransitionReportsDateRangeAction()
  );

  // 表示項目選択を初期化
  yield put(
    PlanProgressTransitionReportsSettingActionCreators.resetPlanProgressTransitionReportsFieldsAction()
  );

  // 検索条件に店舗を指定する（初期化された検索条件に店舗情報は無く、未指定の場合、全店舗のデータがレスポンスされる）
  if (action.payload.halls !== undefined) {
    yield put(
      PlanProgressTransitionReportsSettingActionCreators.selectPlanProgressTransitionReportsCurrentHallsAction(
        action.payload.halls
      )
    );
  }

  const planProgressSetting: PlanProgressTransitionReportsSettingState =
    yield select(planProgressTransitionReportsSettingSelector);

  yield put(
    PlanProgressTransitionReportsSettingActionCreators.searchPlanProgressTransitionReportsAction(
      {
        ...planProgressSetting.searchCondition,
        halls: planProgressSetting.currentHalls,
      }
    )
  );
  // 行の表示管理フラグの初期化
  yield put(
    PlanProgressTransitionReportsSettingActionCreators.resetPlanProgressTransitionReportsIsShowRowAction()
  );
}

/**
 * 画面共有用短縮URL作成の処理
 */
function* createShortenedUrlSaga(
  action: CreatePlanProgressTransitionReportsShortenedUrlAction
) {
  const pageSetting: FavoriteItem['pageSetting'] = yield call(settingsPageSaga);
  if (pageSetting == null) return;
  const compressed = compressToEncodedURIComponent(
    action.payload.pageName,
    pageSetting ?? {}
  );
  const originalUrl = `${action.payload.locationUrl}?q=${compressed}`;
  yield put(ShortenedUrlActionCreators.postShortenedUrlAction({ originalUrl }));
}

/**
 * 初回表示時のデータを取得する
 */
function* handleInitSearchSaga() {
  yield takeEvery(
    PlanProgressTransitionReportsSettingActionTypes.INIT_PLAN_PROGRESS_TRANSITION_REPORTS,
    initActionSaga
  );
}

// 検索ボタンクリック時にデータを取得する
function* handleSearchSaga() {
  yield takeEvery(
    PlanProgressTransitionReportsSettingActionTypes.SEARCH_PLAN_PROGRESS_TRANSITION_REPORTS,
    searchActionSaga
  );
}

/**
 * 種別変更時にデータを取得する
 */
function* handleChangeCurrentShuSaga() {
  yield takeEvery(
    PlanProgressTransitionReportsSettingActionTypes.CHANGE_PLAN_PROGRESS_TRANSITION_REPORTS_CURRENT_SHU,
    changeCurrentShuSaga
  );
}

/**
 * 選択中の店舗変更時、データを取得する
 */
function* handleChangeCurrentHallsSaga() {
  yield takeEvery(
    PlanProgressTransitionReportsSettingActionTypes.CHANGE_PLAN_PROGRESS_TRANSITION_REPORTS_CURRENT_HALLS,
    changeCurrentHallsSaga
  );
}

/**
 * 選択中の期間変更時、データを取得する
 */
function* handleChangeCurrentDataRangeSaga() {
  yield takeEvery(
    PlanProgressTransitionReportsSettingActionTypes.CHANGE_PLAN_PROGRESS_TRANSITION_REPORTS_DATA_RANGE,
    changeCurrentDataRangeSaga
  );
}

/**
 * リセットボタン押下時予定進捗推移の全てのデータを破棄する
 */
function* handleResetSaga() {
  yield takeEvery(
    PlanProgressTransitionReportsSettingActionTypes.SEARCH_RESET_PLAN_PROGRESS_TRANSITION_REPORTS,
    resetSearchActionSaga
  );
}

/**
 * お気に入り関連の処理
 */
function* handleFavoriteSaga() {
  // お気に入り適用
  yield takeEvery(
    PlanProgressTransitionReportsSettingActionTypes.CHANGE_PLAN_PROGRESS_TRANSITION_REPORTS_FAVORITE,
    applyFavoriteByIdSaga
  );
  // // お気に入り上書き保存
  yield takeEvery(
    PlanProgressTransitionReportsSettingActionTypes.SAVE_PLAN_PROGRESS_TRANSITION_REPORTS_FAVORITE,
    saveFavoriteSaga
  );
  // お気に入り新規保存
  yield takeEvery(
    PlanProgressTransitionReportsSettingActionTypes.SAVE_AS_PLAN_PROGRESS_TRANSITION_REPORTS_FAVORITE,
    saveAsFavoriteSaga
  );
}

// 画面共有用短縮URL作成の処理
function* handleShortenedUrlSaga() {
  yield takeEvery(
    PlanProgressTransitionReportsSettingActionTypes.CREATE_PLAN_PROGRESS_TRANSITION_REPORTS_SHORTENED_URL,
    createShortenedUrlSaga
  );
}

/**
 * 予定進捗推移の検索フォームに関するタスクを実行する
 */
export function* planProgressTransitionReportsSettingSagas() {
  yield fork(handleInitSearchSaga);
  yield fork(handleSearchSaga);
  yield fork(handleChangeCurrentShuSaga);
  yield fork(handleChangeCurrentHallsSaga);
  yield fork(handleChangeCurrentDataRangeSaga);
  yield fork(handleFavoriteSaga);
  yield fork(handleResetSaga);
  yield fork(handleShortenedUrlSaga);
}
