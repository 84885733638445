import { createSelector } from 'reselect';

import { SharedUser } from '../../domain/favorites';
import {
  defaultDateRangeParams,
  defaultShu,
} from '../../domain/kiSyoken/defaultValue';
import {
  KiSyokenDateRange,
  KiSyokenDateRangeParams,
  KiSyokenKasidamaSearchParams,
  KiSyokenSearchParams,
} from '../../domain/kiSyoken/types';
import { Option, OrderType } from '../../domain/schemas';
import { ShuOption } from '../../domain/shu';

import { RootState } from '../../store';
import { convertShuOption } from '../../utils/shu';
import { dataKiSyokenDataSelector } from '../server/dataKiSyoken';
import { dataKiSyokenKasidamaSettingSelector } from '../server/dataKiSyokenKasidama';
import { settingsOptionsKiSyokenSearchConditionSelector } from '../server/settingsOptionsKiSyoken';
import { favoritesSelector } from './settingsFavorites';

/* ---------------------------------------------------------------
 * Action Types
 */

const INIT_KISYOKEN_SETTING = 'INIT_KISYOKEN_SETTING' as const;
const RENEW_KISYOKEN_SETTING = 'RENEW_KISYOKEN_SETTING' as const;
const SELECT_KISYOKEN_SEARCH_PARAMS = 'SELECT_KISYOKEN_SEARCH_PARAMS' as const;
const SELECT_KISYOKEN_DATE_RANGE_PARAMS =
  'SELECT_KISYOKEN_DATE_RANGE_PARAMS' as const;
const SELECT_KISYOKEN_FORM_CONDITION =
  'SELECT_KISYOKEN_FORM_CONDITION' as const;
const TRIGGER_KISYOKEN_SWAP_FIELDS = 'TRIGGER_KISYOKEN_SWAP_FIELDS' as const;
const SELECT_KISYOKEN_COLUMNS_ORDER = 'SELECT_KISYOKEN_COLUMNS_ORDER' as const;

const ADD_KISYOKEN_TABLE_FILTER = 'ADD_KISYOKEN_TABLE_FILTER' as const;
const REMOVE_KISYOKEN_TABLE_FILTER = 'REMOVE_KISYOKEN_TABLE_FILTER' as const;
const APPLY_KISYOKEN_TABLE_FILTER = 'APPLY_KISYOKEN_TABLE_FILTER' as const;
const RESET_KISYOKEN_TABLE_FILTER = 'RESET_KISYOKEN_TABLE_FILTER' as const;

const SELECT_KISYOKEN_NAME_FILTER = 'SELECT_KISYOKEN_NAME_FILTER' as const;

const SELECT_KISYOKEN_SUB_MENU_NUMBER_FILTER =
  'SELECT_KISYOKEN_SUB_MENU_NUMBER_FILTER' as const;
const RESET_KISYOKEN_SUB_MENU_NUMBER_FILTER =
  'RESET_KISYOKEN_SUB_MENU_NUMBER_FILTER' as const;
const TRIGGER_KISYOKEN_SORT = 'TRIGGER_KISYOKEN_SORT' as const;
const CHANGE_KISYOKEN_FIELDS = 'CHANGE_KISYOKEN_FIELDS' as const;
const SELECT_KISYOKEN_FIELDS = 'SELECT_KISYOKEN_FIELDS' as const;

const SEARCH_KI_SYOKEN_SETTINGS = 'SEARCH_KI_SYOKEN_SETTINGS' as const;
const SEARCH_RESET_KI_SYOKEN_SEARCH_PARAMS =
  'SEARCH_RESET_KI_SYOKEN_SEARCH_PARAMS' as const;
const RESET_KI_SYOKEN_SEARCH_CONDITION =
  'RESET_KI_SYOKEN_SEARCH_CONDITION' as const;

const SELECT_KISYOKEN_MKS_HALL_CODES =
  'SELECT_KISYOKEN_MKS_HALL_CODES' as const;
const SELECT_KISYOKEN_CURRENT_HALLS = 'SELECT_KISYOKEN_CURRENT_HALLS' as const;
const CHANGE_KISYOKEN_CURRENT_HALLS = 'CHANGE_KISYOKEN_CURRENT_HALLS' as const;
const CHANGE_KISYOKEN_CURRENT_SHU = 'CHANGE_KISYOKEN_CURRENT_SHU' as const;
const SEARCH_KISYOKEN_DATE_RANGE_SLIDE =
  'SEARCH_KISYOKEN_DATE_RANGE_SLIDE' as const;
const CHANGE_KISYOKEN_FAVORITE = 'CHANGE_KISYOKEN_FAVORITE' as const;
const RESET_KISYOKEN_FAVORITE = 'RESET_KISYOKEN_FAVORITE' as const;
const SAVE_KISYOKEN_FAVORITE = 'SAVE_KISYOKEN_FAVORITE' as const;
const SAVE_AS_KISYOKEN_FAVORITE = 'SAVE_AS_KISYOKEN_FAVORITE' as const;

const SEARCH_KISYOKEN_KASIDAMA = 'SEARCH_KISYOKEN_KASIDAMA' as const;
const SELECT_KISYOKEN_KASIDAMA_SEARCH_PARAMS =
  'SELECT_KISYOKEN_KASIDAMA_SEARCH_PARAMS' as const;
const CHANGE_KISYOKEN_KASIDAMA_SORT = 'CHANGE_KISYOKEN_KASIDAMA_SORT' as const;
const SELECT_KISYOKEN_KASIDAMA_COLUMNS_ORDER =
  'SELECT_KISYOKEN_KASIDAMA_COLUMNS_ORDER' as const;
const CHANGE_KISYOKEN_KASIDAMA_FIELDS =
  'CHANGE_KISYOKEN_KASIDAMA_FIELDS' as const;
const CHANGE_KISYOKEN_KASIDAMA_SHU = 'CHANGE_KISYOKEN_KASIDAMA_SHU' as const;
const TRIGGER_KISYOKEN_KASIDAMA_SWAP_FIELDS =
  'TRIGGER_KISYOKEN_KASIDAMA_SWAP_FIELDS' as const;
const CREATE_KISYOKEN_SHORTENED_URL = 'CREATE_KISYOKEN_SHORTENED_URL' as const;

export const KiSyokenSettingActionTypes = {
  INIT_KISYOKEN_SETTING,
  RENEW_KISYOKEN_SETTING,
  SELECT_KISYOKEN_SEARCH_PARAMS,
  SELECT_KISYOKEN_DATE_RANGE_PARAMS,
  SELECT_KISYOKEN_FORM_CONDITION,
  TRIGGER_KISYOKEN_SWAP_FIELDS,
  SELECT_KISYOKEN_COLUMNS_ORDER,
  ADD_KISYOKEN_TABLE_FILTER,
  REMOVE_KISYOKEN_TABLE_FILTER,
  APPLY_KISYOKEN_TABLE_FILTER,
  RESET_KISYOKEN_TABLE_FILTER,
  SELECT_KISYOKEN_NAME_FILTER,
  SELECT_KISYOKEN_SUB_MENU_NUMBER_FILTER,
  RESET_KISYOKEN_SUB_MENU_NUMBER_FILTER,
  TRIGGER_KISYOKEN_SORT,
  SELECT_KISYOKEN_MKS_HALL_CODES,
  SELECT_KISYOKEN_CURRENT_HALLS,
  CHANGE_KISYOKEN_CURRENT_HALLS,
  CHANGE_KISYOKEN_CURRENT_SHU,
  SEARCH_KISYOKEN_DATE_RANGE_SLIDE,
  CHANGE_KISYOKEN_FIELDS,
  SELECT_KISYOKEN_FIELDS,
  SEARCH_KI_SYOKEN_SETTINGS,
  SEARCH_RESET_KI_SYOKEN_SEARCH_PARAMS,
  RESET_KI_SYOKEN_SEARCH_CONDITION,
  CHANGE_KISYOKEN_FAVORITE,
  RESET_KISYOKEN_FAVORITE,
  SAVE_KISYOKEN_FAVORITE,
  SAVE_AS_KISYOKEN_FAVORITE,
  SEARCH_KISYOKEN_KASIDAMA,
  SELECT_KISYOKEN_KASIDAMA_SEARCH_PARAMS,
  CHANGE_KISYOKEN_KASIDAMA_SORT,
  SELECT_KISYOKEN_KASIDAMA_COLUMNS_ORDER,
  CHANGE_KISYOKEN_KASIDAMA_FIELDS,
  CHANGE_KISYOKEN_KASIDAMA_SHU,
  TRIGGER_KISYOKEN_KASIDAMA_SWAP_FIELDS,
  CREATE_KISYOKEN_SHORTENED_URL,
};

/* ---------------------------------------------------------------
 * Action Creators
 */

/**
 * 初回リクエスト
 */
function initKiSyokenSettingAction() {
  return {
    type: INIT_KISYOKEN_SETTING,
  };
}

/**
 * 初期化（派生テーブルの情報含む）
 */
function renewKiSyokenSettingAction() {
  return {
    type: RENEW_KISYOKEN_SETTING,
  };
}

/**
 * 検索条件を変更
 */
function selectKiSyokenSearchParamsAction(params: KiSyokenSearchParams) {
  return {
    type: SELECT_KISYOKEN_SEARCH_PARAMS,
    payload: { params },
  };
}

/**
 * 初期条件でデータを取得する（リセットボタン押下時）
 */
function searchResetKiSyokenSettingsAction() {
  return {
    type: SEARCH_RESET_KI_SYOKEN_SEARCH_PARAMS,
  };
}

/**
 * 検索ボタンでデータ取得
 * @param params 検索条件
 */
function searchKiSyokenSettingsAction({
  params,
  dateRangeParams,
  // selectedShu,
  selectedKiList,
}: {
  params: Omit<
    KiSyokenSearchParams,
    'dateRangeParams' | 'selectedShu' | 'selectedKiList'
  >;
  dateRangeParams: KiSyokenDateRangeParams;
  // selectedShu: ShuOption[];
  selectedKiList: string[];
}) {
  return {
    type: SEARCH_KI_SYOKEN_SETTINGS,
    payload: { params, dateRangeParams, selectedKiList },
  };
}

/**
 * 検索条件の期間を変更
 */
function selectKiSyokenDateRangeParamsAction(
  dateRangeParams: KiSyokenDateRangeParams
) {
  return {
    type: SELECT_KISYOKEN_DATE_RANGE_PARAMS,
    payload: { dateRangeParams },
  };
}

/**
 * 表示項目の並び順を入替
 */
function triggerKiSyokenSwapFieldsAction(draggedId: string, droppedId: string) {
  return {
    type: TRIGGER_KISYOKEN_SWAP_FIELDS,
    payload: { draggedId, droppedId },
  };
}

/**
 * 表示項目の並び順を変更
 */
function selectKiSyokenColumnsOrderAction(columnsOrder: string[]) {
  return {
    type: SELECT_KISYOKEN_COLUMNS_ORDER,
    payload: { columnsOrder },
  };
}

/**
 * テーブルの非表示項目を追加する
 * @param columnCode カラム名
 */
function addKiSyokenTableFilterAction(columnCode: string) {
  return {
    type: ADD_KISYOKEN_TABLE_FILTER,
    payload: { columnCode },
  };
}

/**
 * テーブルの非表示項目を削除する
 * @param columnCode カラム名
 */
function removeKiSyokenTableFilterAction(columnCode: string) {
  return {
    type: REMOVE_KISYOKEN_TABLE_FILTER,
    payload: { columnCode },
  };
}

/**
 * テーブルの非表示項目を反映する
 * @param tableFilterItems テーブルの非表示項目
 */
function applyKiSyokenTableFilterAction(tableFilterItems: string[]) {
  return {
    type: APPLY_KISYOKEN_TABLE_FILTER,
    payload: { tableFilterItems },
  };
}

/**
 * テーブルの非表示項目をリセットする
 */
function resetKiSyokenTableFilterAction() {
  return {
    type: RESET_KISYOKEN_TABLE_FILTER,
  };
}

/**
 * 機種名フィルタを変更
 * @param name 検索する文字列
 */
export function selectKiSyokenNameFilterAction(name: string) {
  return {
    type: SELECT_KISYOKEN_NAME_FILTER,
    payload: { name },
  };
}

/**
 * サブメニュー内数値フィルタを変更
 * @param filterNumbers 検索する数値フィルタ条件
 */
export function selectKiSyokenSubMenuNumberFilterAction(filterNumbers: {
  [field: string]: {
    minimumNumber: number | undefined;
    maximumNumber: number | undefined;
  };
}) {
  return {
    type: SELECT_KISYOKEN_SUB_MENU_NUMBER_FILTER,
    payload: { filterNumbers },
  };
}

/**
 * 数値フィルタを全てリセットする
 */
export function resetKiSyokenSubMenuNumberFilterAction() {
  return {
    type: RESET_KISYOKEN_SUB_MENU_NUMBER_FILTER,
  };
}

/**
 * ソートボタン押下 (サブメニュー内・ラベル共通)
 */
function triggerKiSyokenSortAction(sort: string, order: OrderType) {
  return {
    type: TRIGGER_KISYOKEN_SORT,
    payload: { sort, order },
  };
}

/**
 * 商圏店舗を選択
 */
function selectKiSyokenMksHallCodesAction(hallCodes: string[]) {
  return {
    type: SELECT_KISYOKEN_MKS_HALL_CODES,
    payload: { hallCodes },
  };
}

/**
 * 選択した店舗のデータを取得する
 *
 * MEMO: 初回取得時とテーブル上部の店舗選択で変更時に使用する
 * @param halls 絞り込む店舗
 */
function selectKiSyokenCurrentHallsAction(halls: string[]) {
  return {
    type: SELECT_KISYOKEN_CURRENT_HALLS,
    payload: { halls },
  };
}

/**
 * 店舗選択スライダーで選択した店舗のデータを取得する
 *
 * MEMO: テーブル上部の店舗選択で変更時に使用する
 * @param halls 絞り込む店舗
 */
function changeKiSyokenCurrentHallsAction(halls: string[]) {
  return {
    type: CHANGE_KISYOKEN_CURRENT_HALLS,
    payload: { halls },
  };
}

/**
 * 種別選択スライダーで選択した種別のデータを取得する
 *
 * MEMO: テーブル上部の種別選択で変更時に使用する
 * @param shu 絞り込む種別
 */
export function changeKiSyokenCurrentShuAction(shu: ShuOption) {
  return {
    type: CHANGE_KISYOKEN_CURRENT_SHU,
    payload: { shu },
  };
}

/**
 * 期間スライドコンポーネントがクリックされた時に、期間を変更して検索を実行する
 * @param {KiSyokenDateRange} newDateRange 期間単位
 * @param {string} newStartDate           検索期間 開始日
 * @param {string} newEndDate             検索期間 終了日
 * @param {string} newStartComparisonDate 比較期間 開始日
 * @param {string} newEndComparisonDate   比較期間 終了日
 */
export function searchKiSyokenDateRangeSlideAction(
  newDateRange: KiSyokenDateRange,
  newStartDate: string,
  newEndDate: string,
  newStartComparisonDate: string | undefined,
  newEndComparisonDate: string | undefined
) {
  return {
    type: SEARCH_KISYOKEN_DATE_RANGE_SLIDE,
    payload: {
      dateRange: newDateRange,
      startDate: newStartDate,
      endDate: newEndDate,
      startComparisonDate: newStartComparisonDate,
      endComparisonDate: newEndComparisonDate,
    },
  };
}

/**
 * 表示項目変更
 */
function changeKiSyokenFieldsAction(
  isSyokenFields: string[],
  notSyokenFields: string[]
) {
  return {
    type: CHANGE_KISYOKEN_FIELDS,
    payload: { isSyokenFields, notSyokenFields },
  };
}

/**
 * 表示項目を変更(fetchしない)
 */
function selectKiSyokenFieldsAction(fields: string[]) {
  return {
    type: SELECT_KISYOKEN_FIELDS,
    payload: { fields },
  };
}

/**
 * 選択したお気に入りでデータを取得する
 *
 * @param favorite 変更するお気に入りID（undefined時は選択なし）
 */
function changeKiSyokenFavoriteAction(favoriteId?: number) {
  return {
    type: CHANGE_KISYOKEN_FAVORITE,
    payload: { favoriteId },
  };
}

/**
 * データを取得せずにお気に入りをデフォルトに戻す
 */
export function resetKiSyokenFavoriteToDefaultAction() {
  return {
    type: RESET_KISYOKEN_FAVORITE,
    payload: {},
  };
}
/**
 * 現在の検索条件でお気に入りを新規保存する
 * @param name お気に入り名
 * @param isShared 共有設定
 * @param memo メモ
 * @param sharedUser 共有ユーザ
 */
function saveAsKiSyokenFavoriteAction(
  name: string,
  isShared: boolean,
  memo: string,
  sharedUser: SharedUser[]
) {
  return {
    type: SAVE_AS_KISYOKEN_FAVORITE,
    payload: { name, isShared, memo, sharedUser },
  };
}

/**
 * 現在の検索条件でお気に入りを上書き保存する
 * @param name お気に入り名
 * @param isShared 共有設定
 * @param memo メモ
 * @param sharedUser 共有ユーザ
 * @param mode メモ更新モード
 */
function saveKisyokenFavoriteAction(
  name: string,
  isShared: boolean,
  memo: string,
  sharedUser: SharedUser[],
  mode?: 'standard' | 'memo'
) {
  return {
    type: SAVE_KISYOKEN_FAVORITE,
    payload: { name, isShared, memo, sharedUser, mode },
  };
}

/**
 * 選択中のお気に入りIDを取得する
 */
export const kiSyokenSelectedFavoriteSelector = createSelector(
  kiSyokenSettingSelector,
  (kiSyokenSettings) => kiSyokenSettings.selectedFavoriteId
);
/**
 * 機種別商圏で現在選択中のお気に入りデータを取得する
 * @returns 現在選択中のお気に入りデータ（undefined時未選択）
 */
export const kiSyokenSelectedFavoriteDataSelector = createSelector(
  [kiSyokenSelectedFavoriteSelector, favoritesSelector],
  (favoriteId, favorites) => {
    if (favoriteId === undefined) return;

    return favorites?.favorites?.find((favorite) => favorite.id === favoriteId);
  }
);

/**
 * 機種別商圏で選択中のお気に入りデータを取得する
 * @returns 現在選択中のお気に入りデータ
 */
export const kiSyokenSelectedFavoritePageSettingSelector = createSelector(
  kiSyokenSelectedFavoriteDataSelector,
  (favorite) => {
    return favorite?.pageSetting?.kiSyoken;
  }
);

/**
 * チェーン店一覧の検索（メインテーブルからの機種選択、機種セレクタで機種変更）
 */
function searchKiSyokenKasidamaAction(kiCode: string) {
  return {
    type: SEARCH_KISYOKEN_KASIDAMA,
    payload: { kiCode },
  };
}

/**
 * チェーン店一覧の検索条件を変更
 */
function selectKiSyokenKasidamaSearchParamsAction(
  params: KiSyokenSearchParams
) {
  return {
    type: SELECT_KISYOKEN_KASIDAMA_SEARCH_PARAMS,
    payload: { params },
  };
}

/**
 * チェーン店一覧のソートボタン押下 (サブメニュー内・ラベル共通)
 */
function changeKiSyokenKasidamaSortAction(sort: string, order: OrderType) {
  return {
    type: CHANGE_KISYOKEN_KASIDAMA_SORT,
    payload: { sort, order },
  };
}

/**
 * チェーン店一覧の表示項目の並び順を変更
 */
function selectKiSyokenKasidamaColumnsOrderAction(columnsOrder: string[]) {
  return {
    type: SELECT_KISYOKEN_KASIDAMA_COLUMNS_ORDER,
    payload: { columnsOrder },
  };
}

/**
 * チェーン店一覧の表示項目を変更
 */
function changeKiSyokenKasidamaFieldsAction(fields: Option[]) {
  return {
    type: CHANGE_KISYOKEN_KASIDAMA_FIELDS,
    payload: { fields },
  };
}

/**
 * チェーン店一覧の種別を変更
 */
export function changeKiSyokenKasidamaShuAction(currentShu: ShuOption) {
  return {
    type: CHANGE_KISYOKEN_KASIDAMA_SHU,
    payload: { currentShu },
  };
}

/**
 * チェーン店一覧の表示項目の並び順を変更（columnsOrderを変更するためのラッパー）
 */
function triggerKiSyokenKasidamaSwapFieldsAction(
  draggedId: string,
  droppedId: string
) {
  return {
    type: TRIGGER_KISYOKEN_KASIDAMA_SWAP_FIELDS,
    payload: { draggedId, droppedId },
  };
}

/**
 * 画面共有用の短縮URLを作成する
 */
export function createKiSyokenShortenedUrlAction(
  pageName: string,
  locationUrl: string
) {
  return {
    type: CREATE_KISYOKEN_SHORTENED_URL,
    payload: { pageName, locationUrl },
  };
}

export const KiSyokenSettingActionCreators = {
  initKiSyokenSettingAction,
  renewKiSyokenSettingAction,
  selectKiSyokenSearchParamsAction,
  selectKiSyokenDateRangeParamsAction,
  triggerKiSyokenSwapFieldsAction,
  selectKiSyokenColumnsOrderAction,
  addKiSyokenTableFilterAction,
  removeKiSyokenTableFilterAction,
  applyKiSyokenTableFilterAction,
  resetKiSyokenTableFilterAction,
  selectKiSyokenNameFilterAction,
  selectKiSyokenSubMenuNumberFilterAction,
  resetKiSyokenSubMenuNumberFilterAction,
  triggerKiSyokenSortAction,
  selectKiSyokenMksHallCodesAction,
  selectKiSyokenCurrentHallsAction,
  changeKiSyokenCurrentHallsAction,
  changeKiSyokenCurrentShuAction,
  searchKiSyokenDateRangeSlideAction,
  changeKiSyokenFieldsAction,
  selectKiSyokenFieldsAction,
  searchKiSyokenSettingsAction,
  searchResetKiSyokenSettingsAction,
  changeKiSyokenFavoriteAction,
  resetKiSyokenFavoriteToDefaultAction,
  saveKisyokenFavoriteAction,
  saveAsKiSyokenFavoriteAction,
  searchKiSyokenKasidamaAction,
  selectKiSyokenKasidamaSearchParamsAction,
  changeKiSyokenKasidamaSortAction,
  selectKiSyokenKasidamaColumnsOrderAction,
  changeKiSyokenKasidamaFieldsAction,
  changeKiSyokenKasidamaShuAction,
  triggerKiSyokenKasidamaSwapFieldsAction,
  createKiSyokenShortenedUrlAction,
};

/* ---------------------------------------------------------------
 * Actions
 */

type InitKiSyokenSettingAction = ReturnType<typeof initKiSyokenSettingAction>;
type RenewKiSyokenSettingAction = ReturnType<typeof renewKiSyokenSettingAction>;
export type ChangeKiSyokenFieldsAction = ReturnType<
  typeof changeKiSyokenFieldsAction
>;

export type SearchKiSyokenSettingsAction = ReturnType<
  typeof searchKiSyokenSettingsAction
>;

export type TriggerKiSyokenSwapFieldsAction = ReturnType<
  typeof triggerKiSyokenSwapFieldsAction
>;

export type SelectKiSyokenFieldsAction = ReturnType<
  typeof selectKiSyokenFieldsAction
>;

export type ChangeKiSyokenFavoriteAction = ReturnType<
  typeof changeKiSyokenFavoriteAction
>;

export type SaveAsKiSyokenFavoriteAction = ReturnType<
  typeof saveAsKiSyokenFavoriteAction
>;

export type SaveKiSyokenFavoriteAction = ReturnType<
  typeof saveKisyokenFavoriteAction
>;
export type SearchKiSyokenKasidamaAction = ReturnType<
  typeof searchKiSyokenKasidamaAction
>;

export type ChangeKiSyokenKasidamaFieldsAction = ReturnType<
  typeof changeKiSyokenKasidamaFieldsAction
>;

export type ChangeKiSyokenKasidamaShuAction = ReturnType<
  typeof changeKiSyokenKasidamaShuAction
>;

export type ChangeKiSyokenKasidamaSortAction = ReturnType<
  typeof changeKiSyokenKasidamaSortAction
>;

export type TriggerKiSyokenKasidamaSwapFieldsAction = ReturnType<
  typeof triggerKiSyokenKasidamaSwapFieldsAction
>;

export type CreateKiSyokenShortenedUrlAction = ReturnType<
  typeof createKiSyokenShortenedUrlAction
>;

type KiSyokenSettingActions =
  | InitKiSyokenSettingAction
  | RenewKiSyokenSettingAction
  | ChangeKiSyokenFieldsAction
  | SelectKiSyokenFieldsAction
  | ReturnType<typeof selectKiSyokenSearchParamsAction>
  | ReturnType<typeof selectKiSyokenDateRangeParamsAction>
  | ReturnType<typeof triggerKiSyokenSwapFieldsAction>
  | ReturnType<typeof selectKiSyokenColumnsOrderAction>
  | ReturnType<typeof addKiSyokenTableFilterAction>
  | ReturnType<typeof removeKiSyokenTableFilterAction>
  | ReturnType<typeof applyKiSyokenTableFilterAction>
  | ReturnType<typeof resetKiSyokenTableFilterAction>
  | ReturnType<typeof selectKiSyokenNameFilterAction>
  | ReturnType<typeof selectKiSyokenSubMenuNumberFilterAction>
  | ReturnType<typeof resetKiSyokenSubMenuNumberFilterAction>
  | ReturnType<typeof triggerKiSyokenSortAction>
  | ReturnType<typeof selectKiSyokenMksHallCodesAction>
  | ReturnType<typeof selectKiSyokenCurrentHallsAction>
  | ReturnType<typeof changeKiSyokenCurrentHallsAction>
  | ReturnType<typeof changeKiSyokenCurrentShuAction>
  | ReturnType<typeof searchKiSyokenDateRangeSlideAction>
  | ReturnType<typeof searchKiSyokenSettingsAction>
  | ReturnType<typeof searchResetKiSyokenSettingsAction>
  | SearchKiSyokenKasidamaAction
  | ReturnType<typeof selectKiSyokenKasidamaSearchParamsAction>
  | ChangeKiSyokenKasidamaSortAction
  | ReturnType<typeof selectKiSyokenKasidamaColumnsOrderAction>
  | ChangeKiSyokenKasidamaFieldsAction
  | ChangeKiSyokenKasidamaShuAction
  | TriggerKiSyokenKasidamaSwapFieldsAction
  | ChangeKiSyokenFavoriteAction
  | CreateKiSyokenShortenedUrlAction;

/* ---------------------------------------------------------------
 * State
 */

type KiSyokenSettingState = {
  searchParams: KiSyokenSearchParams;
  selectedDateRangeParams: KiSyokenDateRangeParams;
  columnsOrder: string[];
  nameFilter: string;
  subMenuNumberFilter: {
    [field: string]: {
      minimumNumber: number | undefined;
      maximumNumber: number | undefined;
    };
  };
  tableFilterItems: string[];
  selectedMksHallCodes: string[];
  selectedFavoriteId?: number;
  kasidamaTable: {
    columnsOrder: string[];
    searchParams: KiSyokenKasidamaSearchParams;
  };
};

const initialState: KiSyokenSettingState = {
  searchParams: {},
  selectedDateRangeParams: defaultDateRangeParams,
  columnsOrder: [],
  nameFilter: '',
  subMenuNumberFilter: {},
  tableFilterItems: [],
  selectedMksHallCodes: [],
  selectedFavoriteId: undefined,
  kasidamaTable: {
    columnsOrder: [],
    searchParams: {},
  },
};

/* ---------------------------------------------------------------
 * Selector
 */

/**
 *
 * [機種別商圏] settingの全ての状態を取得する
 */
export function kiSyokenSettingSelector(rootState: {
  kiSyokenSettings: KiSyokenSettingState;
}) {
  return rootState.kiSyokenSettings;
}

/**
 * [機種別商圏] 検索に使用された文字列クエリ
 *
 * 基本的にはデータのレスポンスのsettingを使用することになりますが、この値はお気に入りで使用されます
 */
export const kiSyokenSearchParamsSelector = createSelector(
  kiSyokenSettingSelector,
  (kiSyokenSetting) => kiSyokenSetting.searchParams
);

/**
 * 選択中の種別・種別グループを取得する（テーブルに表示されている種別・種別グループ）
 */
export const kiSyokenCurrentShuSelector = createSelector(
  [dataKiSyokenDataSelector, settingsOptionsKiSyokenSearchConditionSelector],
  (kiSyokenData, searchCondition) => {
    const convertShu =
      kiSyokenData?.setting &&
      searchCondition?.shuGroupList &&
      convertShuOption(kiSyokenData.setting, searchCondition.shuGroupList);
    return convertShu || defaultShu;
  }
);

/**
 * [機種別商圏] 検索フォームで検索された期間
 */
export const kiSyokenSelectedDateRangeParamsSelector = createSelector(
  kiSyokenSettingSelector,
  (kiSyokenSetting) => {
    return kiSyokenSetting.selectedDateRangeParams;
  }
);

/**
 * [機種別商圏] 表示項目の列順を取得する
 */
export const kiSyokenColumnsOrderSelector = createSelector(
  kiSyokenSettingSelector,
  (kiSyokenSettings) => kiSyokenSettings.columnsOrder
);

/**
 * [機種別商圏]機種名フィルタを返す
 */
export const kiSyokenNameFilterSelector = createSelector(
  kiSyokenSettingSelector,
  (kiSyokenSetting) => {
    return kiSyokenSetting.nameFilter;
  }
);

/**
 * [機種別商圏]サブメニュー内の数値フィルタを返す
 */
export const kiSyokenSubMenuNumberFilterSelector = createSelector(
  kiSyokenSettingSelector,
  (kiSyokenSetting) => {
    return kiSyokenSetting.subMenuNumberFilter;
  }
);

/**
 * [機種別商圏]非表示項目一覧を取得する
 */
export const kiSyokenSelectedTableFilterSelector = createSelector(
  kiSyokenSettingSelector,
  (kiSyokenSetting) => {
    return kiSyokenSetting.tableFilterItems;
  }
);

/**
 * ソート条件を取得する
 */
export const kiSyokenSortSelector = createSelector(
  kiSyokenSettingSelector,
  (kiSyokenSettings) => kiSyokenSettings.searchParams.sort
);

/**
 * ソート順を取得する
 */
export const kiSyokenOrderSelector = createSelector(
  kiSyokenSettingSelector,
  (kiSyokenSettings) => kiSyokenSettings.searchParams.order
);

/**
 * 選択中の商圏店舗を取得する
 */
export const kiSyokenMksHallCodesSelector = createSelector(
  kiSyokenSettingSelector,
  (kiSyokenSetting) => kiSyokenSetting.selectedMksHallCodes
);

// 機種別商圏の検索条件を取得する
export function kiSyokenSearchConditionSelector(state: RootState) {
  return state.kiSyokenSettings.searchParams;
}
/**
 * チェーン店一覧の列順を取得する
 */
export const kiSyokenKasidamaColumnsOrderSelector = createSelector(
  kiSyokenSettingSelector,
  (kiSyokenSetting) => kiSyokenSetting.kasidamaTable.columnsOrder
);

/**
 * チェーン店一覧の検索条件を取得する
 */
export const kiSyokenKasidamaSearchParamsSelector = createSelector(
  kiSyokenSettingSelector,
  (kiSyokenSetting) => kiSyokenSetting.kasidamaTable.searchParams
);

/**
 * チェーン店一覧の表示項目を取得する
 */
export const kiSyokenKasidamaFieldsSelector = createSelector(
  kiSyokenSettingSelector,
  (kiSyokenSetting) => kiSyokenSetting.kasidamaTable.searchParams.fields
);

/**
 * チェーン店一覧の種別を取得する
 */
export const kiSyokenKasidamaCurrentShuSelector = createSelector(
  [
    dataKiSyokenKasidamaSettingSelector,
    settingsOptionsKiSyokenSearchConditionSelector,
  ],
  (setting, searchCondition) => {
    const convertShu =
      setting &&
      searchCondition?.shuGroupList &&
      convertShuOption(setting, searchCondition.shuGroupList);
    return convertShu || defaultShu;
  }
);
/* ---------------------------------------------------------------
 * Reducer
 */

export function kiSyokenSettingReducer(
  state: KiSyokenSettingState = initialState,
  action: KiSyokenSettingActions
): KiSyokenSettingState {
  switch (action.type) {
    case RENEW_KISYOKEN_SETTING:
      return initialState;
    case SELECT_KISYOKEN_SEARCH_PARAMS:
      return {
        ...state,
        searchParams: action.payload.params,
      };
    case SELECT_KISYOKEN_DATE_RANGE_PARAMS:
      return {
        ...state,
        selectedDateRangeParams: action.payload.dateRangeParams,
      };
    case SELECT_KISYOKEN_COLUMNS_ORDER:
      return {
        ...state,
        columnsOrder: action.payload.columnsOrder,
      };
    case SELECT_KISYOKEN_NAME_FILTER:
      return {
        ...state,
        nameFilter: action.payload.name,
      };
    case SELECT_KISYOKEN_SUB_MENU_NUMBER_FILTER:
      return {
        ...state,
        subMenuNumberFilter: {
          ...state.subMenuNumberFilter,
          ...action.payload.filterNumbers,
        },
      };
    case RESET_KISYOKEN_SUB_MENU_NUMBER_FILTER:
      return {
        ...state,
        subMenuNumberFilter: {},
      };
    case ADD_KISYOKEN_TABLE_FILTER:
      return {
        ...state,
        tableFilterItems: [
          ...state.tableFilterItems,
          action.payload.columnCode,
        ],
      };
    case REMOVE_KISYOKEN_TABLE_FILTER:
      return {
        ...state,
        tableFilterItems: state.tableFilterItems.filter(
          (columnCode) => columnCode !== action.payload.columnCode
        ),
      };
    case APPLY_KISYOKEN_TABLE_FILTER:
      return {
        ...state,
        tableFilterItems: action.payload.tableFilterItems,
      };
    case RESET_KISYOKEN_TABLE_FILTER:
      return {
        ...state,
        tableFilterItems: [],
      };
    case TRIGGER_KISYOKEN_SORT:
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          sort: action.payload.sort,
          order: action.payload.order,
        },
      };
    case SELECT_KISYOKEN_MKS_HALL_CODES:
      return {
        ...state,
        selectedMksHallCodes: action.payload.hallCodes,
      };
    case SELECT_KISYOKEN_CURRENT_HALLS:
    case CHANGE_KISYOKEN_CURRENT_HALLS:
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          halls: action.payload.halls,
        },
      };
    case SELECT_KISYOKEN_FIELDS:
      return {
        ...state,
        searchParams: {
          ...(state.searchParams ? state.searchParams : {}),
          fields: action.payload.fields,
        },
      };
    case SELECT_KISYOKEN_KASIDAMA_SEARCH_PARAMS:
      return {
        ...state,
        kasidamaTable: {
          ...state.kasidamaTable,
          searchParams: action.payload.params,
        },
      };
    case SELECT_KISYOKEN_KASIDAMA_COLUMNS_ORDER:
      return {
        ...state,
        kasidamaTable: {
          ...state.kasidamaTable,
          columnsOrder: action.payload.columnsOrder,
        },
      };
    case CHANGE_KISYOKEN_FAVORITE:
      return {
        ...state,
        selectedFavoriteId: action.payload.favoriteId,
      };

    default:
      return state;
  }
}
