import { eachDayOfInterval, format } from 'date-fns';
import { createSelector } from 'reselect';

import { SharedUser } from '../../domain/favorites';
import { HallReportsFormConditions } from '../../domain/hallReportsFormConditions';
import { HallReportsSettingDateRangeParams } from '../../domain/hallReportsSettingDateRangeParams';
import {
  DataCategory,
  DataCategoryShareRate,
  DateRange,
  DateRange30Days,
  Mks,
  TimelineValue,
} from '../../domain/hallReportsSettingMks';
import { Column } from '../../domain/schemas';
import { ShuOption } from '../../domain/shu';

import { RootState } from '../../store';
import { ComparativeSection } from '../../utils/comparativeSectionUtil';
import { dataHallDefaultValue } from '../../utils/dataHallDefaultValue';
import { ReportsDateRange } from '../../utils/reportsDateRange';
import { getShuGroupOption } from '../../utils/shu';
import {
  settingsOptionsHallHallSelector,
  settingsOptionsHallSearchConditionSelector,
} from '../server/settingsOptionsHall';
import { favoritesSelector } from './settingsFavorites';

/**
 * Action Types
 *
 * プレフィックスの意味
 * SEARCH: ReducerでStateの変更はしないがデータを取得する（検索ボタン等Sagaを発火させたい）
 * CHANGE: Stateを変更してデータも取得する
 * SELECT: Stateを変更する（データ取得は行わない）
 * RESET: Stateを初期状態に戻す（データ取得は行わない）
 */
const SEARCH_HALL_REPORTS = 'SEARCH_HALL_REPORTS' as const;
const SEARCH_RESET_HALL_REPORTS = 'SEARCH_RESET_HALL_REPORTS' as const;

const CHANGE_HALL_REPORTS_FAVORITE = 'CHANGE_HALL_REPORTS_FAVORITE' as const;

const SELECT_HALL_REPORTS_SEARCH_CONDITION =
  'SELECT_HALL_REPORTS_SEARCH_CONDITION' as const;
const SELECT_HALL_REPORTS_SHU = 'SELECT_HALL_REPORTS_SHU' as const;
const SELECT_HALL_REPORTS_DATE_RANGE_PARAMS =
  'SELECT_HALL_REPORTS_DATE_RANGE_PARAMS' as const;
const SELECT_HALL_REPORT_TAB_ID = 'SELECT_HALL_REPORT_TAB_ID' as const;

const RESET_HALL_REPORTS_SEARCH_CONDITION =
  'RESET_HALL_REPORTS_SEARCH_CONDITION' as const;
const RESET_HALL_REPORTS_DATE_RANGE_PARAMS =
  'RESET_HALL_REPORTS_DATE_RANGE_PARAMS' as const;
const RESET_HALL_REPORTS_SHU = 'RESET_HALL_REPORTS_SHU' as const;

const SAVE_HALL_REPORTS_FAVORITE = 'SAVE_HALL_REPORTS_FAVORITE' as const;
const SAVE_AS_HALL_REPORTS_FAVORITE = 'SAVE_AS_HALL_REPORTS_FAVORITE' as const;
const RESET_HALL_REPORTS_FAVORITE_TO_DEFAULT =
  'RESET_HALL_REPORTS_FAVORITE_TO_DEFAULT' as const;

const ADD_HALL_REPORTS_TABLE_FILTER = 'ADD_HALL_REPORTS_TABLE_FILTER' as const;
const REMOVE_HALL_REPORTS_TABLE_FILTER =
  'REMOVE_HALL_REPORTS_TABLE_FILTER' as const;
const APPLY_HALL_REPORTS_TABLE_FILTER =
  'APPLY_HALL_REPORTS_TABLE_FILTER' as const;
const RESET_HALL_REPORTS_TABLE_FILTER =
  'RESET_HALL_REPORTS_TABLE_FILTER' as const;

const ADD_HALL_REPORTS_KI_TABLE_FILTER =
  'ADD_HALL_REPORTS_KI_TABLE_FILTER' as const;
const REMOVE_HALL_REPORTS_KI_TABLE_FILTER =
  'REMOVE_HALL_REPORTS_KI_TABLE_FILTER' as const;
const APPLY_HALL_REPORTS_KI_TABLE_FILTER =
  'APPLY_HALL_REPORTS_KI_TABLE_FILTER' as const;
const RESET_ALL_HALL_REPORTS_KI_TABLE_FILTER =
  'RESET_ALL_HALL_REPORTS_KI_TABLE_FILTER' as const;
const RESET_HALL_REPORTS_KI_TABLE_FILTER =
  'RESET_HALL_REPORTS_KI_TABLE_FILTER' as const;

const SEARCH_HALL_REPORTS_DATE_RANGE_SLIDE =
  'SEARCH_HALL_REPORTS_DATE_RANGE_SLIDE' as const;

const SELECT_KADO_GRAPH_SHOW_GRAPH_NUMBER_LABEL =
  'SELECT_KADO_GRAPH_SHOW_GRAPH_NUMBER_LABEL' as const;
const SELECT_KI_GRAPH_SHOW_GRAPH_NUMBER_LABEL =
  'SELECT_KI_GRAPH_SHOW_GRAPH_NUMBER_LABEL' as const;
const SELECT_MKS_SELECTED_HALLS_ACTION =
  'SELECT_MKS_SELECTED_HALLS_ACTION' as const;
const SELECT_MKS_DATA_CATEGORY_ACTION =
  'SELECT_MKS_DATA_CATEGORY_ACTION' as const;
const SELECT_MKS_SHOW_GRAPH_NUMBER_LABEL_ACTION =
  'SELECT_MKS_SHOW_GRAPH_NUMBER_LABEL_ACTION' as const;
const SELECT_MKS_SHU_OPTION_ACTION = 'SELECT_MKS_SHU_OPTION_ACTION' as const;
const SELECT_MKS_TIMELINE_ACTION = 'SELECT_MKS_TIMELINE_ACTION' as const;
const SELECT_MKS_SHOW_TRANSITION_GRAPH_NUMBER_LABEL_ACTION =
  'SELECT_MKS_SHOW_TRANSITION_GRAPH_NUMBER_LABEL_ACTION' as const;
const SELECT_MKS_DATE_RANGE_ACTION = 'SELECT_MKS_DATE_RANGE_ACTION' as const;
const RESTORE_MKS_FROM_FAVORITE_ACTION =
  'RESTORE_MKS_FROM_FAVORITE_ACTION' as const;
const CLEAR_MKS_SELECTED_HALLS = 'CLEAR_MKS_SELECTED_HALLS' as const;
const HIDE_MKS_TABLE_COLUMN_ACTION = 'HIDE_MKS_TABLE_COLUMN_ACTION' as const;
const CLEAR_MKS_HIDDEN_TABLE_COLUMNS_ACTION =
  'CLEAR_MKS_HIDDEN_TABLE_COLUMNS_ACTION' as const;

const SELECT_MKS_TABLE_COLUMNS_ORDER =
  'SELECT_MKS_TABLE_COLUMNS_ORDER' as const;
const CHANGE_MKS_TABLE_COLUMNS_ORDER =
  'CHANGE_MKS_TABLE_COLUMNS_ORDER' as const;

const SELECT_SHOW_DAILY_COMMENT = 'SELECT_SHOW_DAILY_COMMENT' as const;
const SELECT_HIDE_DAILY_COMMENT = 'SELECT_HIDE_DAILY_COMMENT' as const;

const SELECT_HALL_REPORTS_CHECK_KI = 'SELECT_HALL_REPORTS_CHECK_KI' as const;
const SELECT_HALL_REPORTS_UNCHECK_KI =
  'SELECT_HALL_REPORTS_UNCHECK_KI' as const;
const SELECT_HALL_REPORTS_CHECKED_KI_LIST =
  'SELECT_HALL_REPORTS_CHECKED_KI_LIST' as const;
const SELECT_HALL_REPORTS_CURRENT_HALLS =
  'SELECT_HALL_REPORTS_CURRENT_HALLS' as const;
const SELECT_HALL_REPORTS_CHECK_KI_AS_NEW_MODEL_BY_SHUCODE =
  'SELECT_HALL_REPORTS_CHECK_KI_AS_NEW_MODEL_BY_SHUCODE' as const;
const RESET_HALL_REPORTS_CHECKED_KI_LIST_AS_NEW_MODEL_BY_SHUCODE =
  'RESET_HALL_REPORTS_CHECKED_KI_LIST_AS_NEW_MODEL_BY_SHUCODE' as const;
const SELECT_HALL_IS_KI_GRAPH_CLOSED =
  'SELECT_HALL_IS_KI_GRAPH_CLOSED' as const;
const RESET_HALL_REPORTS_IS_GRAPH_CLOSED_BY_USER =
  'RESET_HALL_REPORTS_IS_GRAPH_CLOSED_BY_USER' as const;
const SELECT_HALL_REPORTS_IS_GRAPH_CLOSED_BY_USER =
  'SELECT_HALL_REPORTS_IS_GRAPH_CLOSED_BY_USER' as const;
const RESET_HALL_REPORTS_CHECKED_KI_LIST =
  'RESET_HALL_REPORTS_CHECKED_KI_LIST' as const;
const CREATE_HALL_REPORTS_SHORTENED_URL =
  'CREATE_HALL_REPORTS_SHORTENED_URL' as const;

export const HallReportsSettingActionTypes = {
  SEARCH_HALL_REPORTS,
  SEARCH_RESET_HALL_REPORTS,
  CHANGE_HALL_REPORTS_FAVORITE,
  SELECT_HALL_REPORTS_SEARCH_CONDITION,
  SELECT_HALL_REPORTS_SHU,
  SELECT_HALL_REPORTS_DATE_RANGE_PARAMS,
  SELECT_HALL_REPORT_TAB_ID,
  RESET_HALL_REPORTS_SEARCH_CONDITION,
  RESET_HALL_REPORTS_DATE_RANGE_PARAMS,
  RESET_HALL_REPORTS_SHU,
  RESET_HALL_REPORTS_FAVORITE_TO_DEFAULT,
  SAVE_HALL_REPORTS_FAVORITE,
  SAVE_AS_HALL_REPORTS_FAVORITE,
  ADD_HALL_REPORTS_TABLE_FILTER,
  REMOVE_HALL_REPORTS_TABLE_FILTER,
  APPLY_HALL_REPORTS_TABLE_FILTER,
  RESET_HALL_REPORTS_TABLE_FILTER,
  ADD_HALL_REPORTS_KI_TABLE_FILTER,
  REMOVE_HALL_REPORTS_KI_TABLE_FILTER,
  APPLY_HALL_REPORTS_KI_TABLE_FILTER,
  RESET_HALL_REPORTS_KI_TABLE_FILTER,
  RESET_ALL_HALL_REPORTS_KI_TABLE_FILTER,
  SEARCH_HALL_REPORTS_DATE_RANGE_SLIDE,
  SELECT_KADO_GRAPH_SHOW_GRAPH_NUMBER_LABEL,
  SELECT_KI_GRAPH_SHOW_GRAPH_NUMBER_LABEL,
  SELECT_MKS_SELECTED_HALLS_ACTION,
  SELECT_MKS_DATA_CATEGORY_ACTION,
  SELECT_MKS_SHOW_GRAPH_NUMBER_LABEL_ACTION,
  SELECT_MKS_SHU_OPTION_ACTION,
  SELECT_MKS_TIMELINE_ACTION,
  SELECT_MKS_SHOW_TRANSITION_GRAPH_NUMBER_LABEL_ACTION,
  SELECT_MKS_DATE_RANGE_ACTION,
  RESTORE_MKS_FROM_FAVORITE_ACTION,
  CLEAR_MKS_SELECTED_HALLS,
  HIDE_MKS_TABLE_COLUMN_ACTION,
  CLEAR_MKS_HIDDEN_TABLE_COLUMNS_ACTION,
  SELECT_MKS_TABLE_COLUMNS_ORDER,
  CHANGE_MKS_TABLE_COLUMNS_ORDER,
  SELECT_SHOW_DAILY_COMMENT,
  SELECT_HIDE_DAILY_COMMENT,
  SELECT_HALL_REPORTS_CHECK_KI,
  SELECT_HALL_REPORTS_UNCHECK_KI,
  SELECT_HALL_REPORTS_CHECKED_KI_LIST,
  SELECT_HALL_REPORTS_CURRENT_HALLS,
  SELECT_HALL_REPORTS_CHECK_KI_AS_NEW_MODEL_BY_SHUCODE,
  RESET_HALL_REPORTS_CHECKED_KI_LIST_AS_NEW_MODEL_BY_SHUCODE,
  SELECT_HALL_IS_KI_GRAPH_CLOSED,
  RESET_HALL_REPORTS_IS_GRAPH_CLOSED_BY_USER,
  SELECT_HALL_REPORTS_IS_GRAPH_CLOSED_BY_USER,
  RESET_HALL_REPORTS_CHECKED_KI_LIST,
  CREATE_HALL_REPORTS_SHORTENED_URL,
};

/**
 * Action Creators
 */

/**
 * 指定した検索条件でデータ取得する（検索ボタン押下時）
 * @param params 検索条件
 * @param selectedShu 選択中の種別
 * @param dateUnit 日付単位
 * @param dateRange 期間
 * @param isComparison 比較期間の有無
 */
export function searchHallReportsAction(
  params: HallReportsFormConditions,
  selectedShu: ShuOption[],
  dateRange: HallReportsSettingDateRangeParams['dateRange'],
  dateUnit: HallReportsSettingDateRangeParams['dateUnit'],
  isComparison: HallReportsSettingDateRangeParams['isComparison'],
  comparativeSection: ComparativeSection
) {
  return {
    type: SEARCH_HALL_REPORTS,
    payload: {
      params,
      selectedShu,
      dateRange,
      dateUnit,
      isComparison,
      comparativeSection,
    },
  };
}

/**
 * 初期条件でデータを取得する（リセットボタン押下時）
 */
function searchResetHallReportsAction() {
  return {
    type: SEARCH_RESET_HALL_REPORTS,
  };
}

/**
 * 選択したお気に入りでデータを取得する
 * @param favorite 変更するお気に入りID（undefined時は選択なし）
 */
function changeHallReportsFavoriteAction(favorite?: number) {
  return {
    type: CHANGE_HALL_REPORTS_FAVORITE,
    payload: { favorite },
  };
}

/**
 * 検索条件を変更する（データ取得は行わない）
 * @param params 検索条件
 */
function selectHallReportsSearchConditionAction(
  params: HallReportsFormConditions
) {
  return {
    type: SELECT_HALL_REPORTS_SEARCH_CONDITION,
    payload: { params },
  };
}

/**
 * 種別一覧を変更
 * @param shus 種別一覧
 */
function selectHallReportsShuAction(shus: ShuOption[]) {
  return {
    type: SELECT_HALL_REPORTS_SHU,
    payload: { shus },
  };
}

/**
 * 選択中の期間を変更
 * @param dateRange 期間
 * @param dateUnit 日付単位
 * @param isComparison 比較期間の有無
 */
function selectHallReportsDateRangeParamsAction(
  dateRange: HallReportsSettingDateRangeParams['dateRange'],
  dateUnit: HallReportsSettingDateRangeParams['dateUnit'],
  isComparison: HallReportsSettingDateRangeParams['isComparison'],
  comparativeSection: ComparativeSection | undefined
) {
  return {
    type: SELECT_HALL_REPORTS_DATE_RANGE_PARAMS,
    payload: { dateRange, dateUnit, isComparison, comparativeSection },
  };
}

/**
 * 選択中の期間をデフォルトに戻す
 */
function resetHallReportsDateRangeParamsAction() {
  return {
    type: RESET_HALL_REPORTS_DATE_RANGE_PARAMS,
  };
}

/**
 * 選択中のタブを変更
 * @param tabID 変更するタブのID
 */
function selectHallReportsTabIDAction(tabID: string) {
  return {
    type: SELECT_HALL_REPORT_TAB_ID,
    payload: { tabID },
  };
}

/**
 * 検索条件をデフォルトに戻す
 */
function resetHallReportsSearchConditionAction() {
  return {
    type: RESET_HALL_REPORTS_SEARCH_CONDITION,
  };
}

/**
 * 選択中の種別一覧をデフォルトに戻す
 */
function resetHallReportsShuAction() {
  return {
    type: RESET_HALL_REPORTS_SHU,
  };
}

/**
 * 現在の検索条件でお気に入りを上書き保存する
 * @param name お気に入り名
 * @param isShared 共有設定
 * @param memo メモ
 * @param sharedUser 共有ユーザー
 * @param mode メモ更新モード
 */
function saveHallReportsFavoriteAction(
  name: string,
  isShared: boolean,
  memo: string,
  sharedUser: SharedUser[],
  mode?: 'standard' | 'memo'
) {
  return {
    type: SAVE_HALL_REPORTS_FAVORITE,
    payload: { name, isShared, memo, sharedUser, mode },
  };
}

/**
 * 現在の検索条件でお気に入りを新規保存する
 * @param name お気に入り名
 * @param isShared 共有設定
 * @param memo メモ
 * @param sharedUser 共有ユーザー
 */
function saveAsHallReportsFavoriteAction(
  name: string,
  isShared: boolean,
  memo: string,
  sharedUser: SharedUser[]
) {
  return {
    type: SAVE_AS_HALL_REPORTS_FAVORITE,
    payload: { name, isShared, memo, sharedUser },
  };
}

/**
 * テーブルの非表示項目を追加する
 * @param columnCode カラム名
 */
function addHallReportsTableFilterAction(columnCode: string) {
  return {
    type: ADD_HALL_REPORTS_TABLE_FILTER,
    payload: { columnCode },
  };
}

/**
 * テーブルの表示項目を削除する
 * @param columnCode カラム名
 */
function removeHallReportsTableFilterAction(columnCode: string) {
  return {
    type: REMOVE_HALL_REPORTS_TABLE_FILTER,
    payload: { columnCode },
  };
}

/**
 * テーブルの非表示項目を反映する
 * @param tableFilterItems テーブルの非表示項目
 */
function applyHallReportsTableFilterAction(tableFilterItems: string[]) {
  return {
    type: APPLY_HALL_REPORTS_TABLE_FILTER,
    payload: { tableFilterItems },
  };
}

/**
 * テーブルの非表示項目をリセットする
 */
function resetHallReportsTableFilterAction() {
  return {
    type: RESET_HALL_REPORTS_TABLE_FILTER,
  };
}

/**
 * テーブルの非表示項目を追加する（新台/メイン機種）
 * @param code コード名
 * @param columnCode カラム名
 */
function addHallReportsKiTableFilterAction(code: string, columnCode: string) {
  return {
    type: ADD_HALL_REPORTS_KI_TABLE_FILTER,
    payload: { code, columnCode },
  };
}

/**
 * テーブルの表示項目を削除する（新台/メイン機種）
 * @param code コード名
 * @param columnCode カラム名
 */
function removeHallReportsKiTableFilterAction(
  code: string,
  columnCode: string
) {
  return {
    type: REMOVE_HALL_REPORTS_KI_TABLE_FILTER,
    payload: { code, columnCode },
  };
}

/**
 * テーブルの非表示項目を反映する（新台/メイン機種）
 * @param tableFilterItemsKi テーブルの非表示項目
 */
function applyHallReportsKiTableFilterAction(
  tableFilterItemsKi: { code: string; items: string[] }[]
) {
  return {
    type: APPLY_HALL_REPORTS_KI_TABLE_FILTER,
    payload: { tableFilterItemsKi },
  };
}

/**
 * テーブルの非表示項目をリセットする（新台/メイン機種）
 */
function resetHallReportsKiTableFilterAction(code: string) {
  return {
    type: RESET_HALL_REPORTS_KI_TABLE_FILTER,
    payload: { code },
  };
}

/**
 * テーブルの非表示項目をすべてリセットする（新台/メイン機種）
 */
function resetAllHallReportsKiTableFilterAction() {
  return {
    type: RESET_ALL_HALL_REPORTS_KI_TABLE_FILTER,
  };
}

/**
 * 期間スライドコンポーネントがクリックされた時に、期間を変更して検索を実行する
 * @param {ReportsDateRange} newDateRange   期間単位
 * @param {string} newStartDate             検索期間 開始日
 * @param {string} newEndDate               検索期間 終了日
 * @param {string} newStartComparisonDate   比較期間 開始日
 * @param {string} newEndComparisonDate     比較期間 終了日
 */
export function searchHallReportsDateRangeSlideAction(
  newDateRange: ReportsDateRange,
  newStartDate: string,
  newEndDate: string,
  newStartComparisonDate: string | undefined,
  newEndComparisonDate: string | undefined
) {
  return {
    type: SEARCH_HALL_REPORTS_DATE_RANGE_SLIDE,
    payload: {
      dateRange: newDateRange,
      ymdList: eachDayOfInterval({
        start: new Date(newStartDate),
        end: new Date(newEndDate),
      }).map((date) => format(date, 'yyyy-MM-dd')),
      ymdComparisonList:
        newStartComparisonDate !== undefined &&
        newEndComparisonDate !== undefined
          ? eachDayOfInterval({
              start: new Date(newStartComparisonDate),
              end: new Date(newEndComparisonDate),
            }).map((date) => format(date, 'yyyy-MM-dd'))
          : undefined,
    },
  };
}

/**
 * 稼働グラフの折れ線グラフに数値を表示するかどうかのフラグをセットする
 * @param {boolean} flag 表示フラグ
 */
function selectKadoGraphShowNumberLabelAction(flag: boolean) {
  return {
    type: SELECT_KADO_GRAPH_SHOW_GRAPH_NUMBER_LABEL,
    payload: {
      flag,
    },
  };
}

/**
 * 推移グラフの折れ線グラフに数値を表示するかどうかのフラグをセットする
 * @param {boolean} flag 表示フラグ
 */
function selectKiGraphShowNumberLabelAction(flag: boolean) {
  return {
    type: SELECT_KI_GRAPH_SHOW_GRAPH_NUMBER_LABEL,
    payload: {
      flag,
    },
  };
}

/**
 * 選択店舗を変更する時に発行するアクション
 *
 * @param hallCodes 店舗コードの配列
 */
function selectMksSelectedHallsAction(hallCodes: string[]) {
  return {
    type: SELECT_MKS_SELECTED_HALLS_ACTION,
    payload: {
      hallCodes,
    },
  };
}

/**
 * データ種別を変更する時に発行するアクション
 *
 * @param dataCategory 選択中のデータ種別
 */
function selectMksDataCategoryAction(dataCategory: DataCategory) {
  return {
    type: SELECT_MKS_DATA_CATEGORY_ACTION,
    payload: {
      dataCategory,
    },
  };
}

/**
 * 比較グラフの数値表示のオンオフを変更する時に発行するアクション
 *
 * @param showGraphNumberLabel 数値表示のオン・オフ状態
 */
function selectMksShowGraphNumberLabelAction(showGraphNumberLabel: boolean) {
  return {
    type: SELECT_MKS_SHOW_GRAPH_NUMBER_LABEL_ACTION,
    payload: {
      showGraphNumberLabel,
    },
  };
}

/**
 * 種別を変更する時に発行するアクション
 *
 * @param shuOptionCode 種別コード
 */
function selectMksShuOptionAction(shuOptionCode: string) {
  return {
    type: SELECT_MKS_SHU_OPTION_ACTION,
    payload: {
      shuOptionCode,
    },
  };
}

/**
 * 時間帯を変更する時に発行するアクション
 *
 * @param timeline 時間帯
 */
function selectMksTimelineAction(timeline: string) {
  return {
    type: SELECT_MKS_TIMELINE_ACTION,
    payload: {
      timeline,
    },
  };
}

/**
 * 推移グラフの数値表示のオンオフを変更する時に発行するアクション
 *
 * @param showTransitionGraphNumberLabel 数値表示のオン・オフ状態
 */
function selectMksShowTransitionGraphNumberLabelAction(
  showTransitionGraphNumberLabel: boolean
) {
  return {
    type: SELECT_MKS_SHOW_TRANSITION_GRAPH_NUMBER_LABEL_ACTION,
    payload: {
      showTransitionGraphNumberLabel,
    },
  };
}

/**
 * 推移グラフの期間を変更する時に発行するアクション
 *
 * @param dateRange 期間
 */
function selectMksDateRangeAction(dateRange: DateRange) {
  return {
    type: SELECT_MKS_DATE_RANGE_ACTION,
    payload: {
      dateRange,
    },
  };
}

// お気に入りから商圏実績タブの設定を復元する
function restoreMksFromFavoriteAction(mks: Mks) {
  return {
    type: RESTORE_MKS_FROM_FAVORITE_ACTION,
    payload: {
      mks,
    },
  };
}

// 商圏実績タブで選択中の店舗を初期化する
function clearMksSelectedHalls() {
  return {
    type: CLEAR_MKS_SELECTED_HALLS,
  };
}

// 商圏実績タブでテーブルのカラムを非表示にする
function hideMksTableColumn(columnCode: string) {
  return {
    type: HIDE_MKS_TABLE_COLUMN_ACTION,
    payload: {
      columnCode,
    },
  };
}

// 商圏実績タブのテーブルで非表示にしたカラムを再表示する
function clearMksHiddenTableColumns() {
  return {
    type: CLEAR_MKS_HIDDEN_TABLE_COLUMNS_ACTION,
  };
}

// 商圏実績テーブルの列の並び替え情報を設定する
export function selectMksTableColumnsOrderAction(columns: Column[]) {
  return {
    type: SELECT_MKS_TABLE_COLUMNS_ORDER,
    payload: columns.map((x) => x.code),
  };
}

// 商圏実績の列の並び替え実行時にカラムの並び順を更新する
function changeMksTableColumnsOrderAction(
  draggedId?: string,
  droppedId?: string
) {
  return {
    type: CHANGE_MKS_TABLE_COLUMNS_ORDER,
    payload: { draggedId, droppedId },
  };
}

/**
 * データを取得せずにお気に入りをデフォルトに戻す
 */
function resetHallReportsFavoriteToDefaultAction() {
  return {
    type: RESET_HALL_REPORTS_FAVORITE_TO_DEFAULT,
  };
}

// デイリーコメントを表示する
export function selectShowDailyCommentAction() {
  return {
    type: SELECT_SHOW_DAILY_COMMENT,
  };
}

// デイリーコメントを隠す
export function selectHideDailyCommentAction() {
  return {
    type: SELECT_HIDE_DAILY_COMMENT,
  };
}

/**
 * ユーザーがグラフ閉じたときにどの種別のグラフを閉じたかを保持する
 */
export function selectHallReportsIsKiGraphClosedAction(shuCode: string) {
  return {
    type: SELECT_HALL_IS_KI_GRAPH_CLOSED,
    payload: { shuCode },
  };
}

/**
 * チェックボックにチェックを付ける
 */
export const selectCheckKi = (shuCode: string, modelCode: string) => ({
  type: SELECT_HALL_REPORTS_CHECK_KI,
  payload: { shuCode, modelCode },
});

/**
 * チェックボックのチェックをはずす
 */
export const selectUncheckKi = (shuCode: string, modelCode: string) => ({
  type: SELECT_HALL_REPORTS_UNCHECK_KI,
  payload: { shuCode, modelCode },
});

/**
 * 種別ごとに複数の機種を一括でチェックする
 */
export const selectCheckKiAsNewModelsByShuCode = (
  shuCode: string,
  modelCodes: string[]
) => ({
  type: SELECT_HALL_REPORTS_CHECK_KI_AS_NEW_MODEL_BY_SHUCODE,
  payload: { shuCode, modelCodes },
});

/**
 * 指定した種別の機種のチェックをすべて外す
 */
export const resetCheckedKiListAsNewModelByShuCode = (shuCode: string) => ({
  type: RESET_HALL_REPORTS_CHECKED_KI_LIST_AS_NEW_MODEL_BY_SHUCODE,
  payload: { shuCode },
});

/**
 * 複数機種のチェックを一括で設定する
 */
const selectCheckedKiListForFavorite = (
  checkedModelCodes: HallReportsSettingState['checkedModelCodes']
) => ({
  type: SELECT_HALL_REPORTS_CHECKED_KI_LIST,
  payload: { checkedModelCodes },
});

/**
 * グラフの開閉状態を初期値に戻す
 */
const resetHallReportsIsGraphClosedByUser = () => ({
  type: RESET_HALL_REPORTS_IS_GRAPH_CLOSED_BY_USER,
});

/**
 * 機種のチェック状態を初期値に戻す
 */
const resetHallReportsCheckedKiList = () => ({
  type: RESET_HALL_REPORTS_CHECKED_KI_LIST,
});

/**
 * お気に入りからユーザーが閉じたグラフの種別を復元
 */
const selectHallReportsIsGraphClosedByUserForFavorite = (
  isKiGraphClosedByUser: HallReportsSettingState['isKiGraphClosedByUser']
) => ({
  type: SELECT_HALL_REPORTS_IS_GRAPH_CLOSED_BY_USER,
  payload: { isKiGraphClosedByUser },
});

const selectHallReportsCurrentHallsAction = (halls: string[]) => {
  return {
    type: SELECT_HALL_REPORTS_CURRENT_HALLS,
    payload: { halls },
  };
};

/**
 * 画面共有用の短縮URLを作成する
 */
function createHallReportsShortenedUrlAction(
  pageName: string,
  locationUrl: string
) {
  return {
    type: CREATE_HALL_REPORTS_SHORTENED_URL,
    payload: { pageName, locationUrl },
  };
}

export const HallReportsSettingActionCreators = {
  searchHallReportsAction,
  searchResetHallReportsAction,
  changeHallReportsFavoriteAction,
  selectHallReportsSearchConditionAction,
  selectHallReportsShuAction,
  selectHallReportsDateRangeParamsAction,
  resetHallReportsDateRangeParamsAction,
  selectHallReportsTabIDAction,
  resetHallReportsSearchConditionAction,
  resetHallReportsShuAction,
  saveHallReportsFavoriteAction,
  saveAsHallReportsFavoriteAction,
  addHallReportsTableFilterAction,
  removeHallReportsTableFilterAction,
  applyHallReportsTableFilterAction,
  resetHallReportsTableFilterAction,
  addHallReportsKiTableFilterAction,
  removeHallReportsKiTableFilterAction,
  applyHallReportsKiTableFilterAction,
  resetHallReportsKiTableFilterAction,
  resetAllHallReportsKiTableFilterAction,
  searchHallReportsDateRangeSlideAction,
  selectKadoGraphShowNumberLabelAction,
  selectKiGraphShowNumberLabelAction,
  selectMksSelectedHallsAction,
  selectMksDataCategoryAction,
  selectMksShowGraphNumberLabelAction,
  selectMksShuOptionAction,
  selectMksTimelineAction,
  selectMksShowTransitionGraphNumberLabelAction,
  selectMksDateRangeAction,
  restoreMksFromFavoriteAction,
  clearMksSelectedHalls,
  hideMksTableColumn,
  clearMksHiddenTableColumns,
  changeMksTableColumnsOrderAction,
  selectMksTableColumnsOrderAction,
  resetHallReportsFavoriteToDefaultAction,
  selectShowDailyCommentAction,
  selectHideDailyCommentAction,
  selectCheckKi,
  selectUncheckKi,
  selectCheckedKiListForFavorite,
  selectHallReportsCurrentHallsAction,
  selectCheckKiAsNewModelsByShuCode,
  resetCheckedKiListAsNewModelByShuCode,
  selectHallReportsIsKiGraphClosedAction,
  resetHallReportsIsGraphClosedByUser,
  selectHallReportsIsGraphClosedByUserForFavorite,
  resetHallReportsCheckedKiList,
  createHallReportsShortenedUrlAction,
};

/**
 * Actions
 */

export type SearchHallReportsAction = ReturnType<
  typeof searchHallReportsAction
>;
type SearchResetHallReportsAction = ReturnType<
  typeof searchResetHallReportsAction
>;
export type ChangeHallReportsFavoriteAction = ReturnType<
  typeof changeHallReportsFavoriteAction
>;
type SelectHallReportsSearchConditionAction = ReturnType<
  typeof selectHallReportsSearchConditionAction
>;
type SelectHallReportsShuAction = ReturnType<typeof selectHallReportsShuAction>;
type SelectHallReportsDateRangeParamsAction = ReturnType<
  typeof selectHallReportsDateRangeParamsAction
>;
type ResetHallReportsDateRangeParamsAction = ReturnType<
  typeof resetHallReportsDateRangeParamsAction
>;
type SelectHallReportsTabIDAction = ReturnType<
  typeof selectHallReportsTabIDAction
>;
type ResetHallReportsSearchConditionAction = ReturnType<
  typeof resetHallReportsSearchConditionAction
>;
type ResetHallReportsShuAction = ReturnType<typeof resetHallReportsShuAction>;
export type SaveHallReportsFavoriteAction = ReturnType<
  typeof saveHallReportsFavoriteAction
>;
export type SaveAsHallReportsFavoriteAction = ReturnType<
  typeof saveAsHallReportsFavoriteAction
>;
type AddHallReportsTableFilterAction = ReturnType<
  typeof addHallReportsTableFilterAction
>;
type RemoveHallReportsTableFilterAction = ReturnType<
  typeof removeHallReportsTableFilterAction
>;
type ApplyHallReportsTableFilterAction = ReturnType<
  typeof applyHallReportsTableFilterAction
>;
type ResetHallReportsTableFilterAction = ReturnType<
  typeof resetHallReportsTableFilterAction
>;
type AddHallReportsKiTableFilterAction = ReturnType<
  typeof addHallReportsKiTableFilterAction
>;
type RemoveHallReportsKiTableFilterAction = ReturnType<
  typeof removeHallReportsKiTableFilterAction
>;
type ApplyHallReportsKiTableFilterAction = ReturnType<
  typeof applyHallReportsKiTableFilterAction
>;
type ResetHallReportsKiTableFilterAction = ReturnType<
  typeof resetHallReportsKiTableFilterAction
>;
type ResetAllHallReportsKiTableFilterAction = ReturnType<
  typeof resetAllHallReportsKiTableFilterAction
>;
export type SearchHallReportsDateRangeSlideAction = ReturnType<
  typeof searchHallReportsDateRangeSlideAction
>;
type SelectKadoGraphShowNumberLabelAction = ReturnType<
  typeof selectKadoGraphShowNumberLabelAction
>;
type SelectKiGraphShowNumberLabelAction = ReturnType<
  typeof selectKiGraphShowNumberLabelAction
>;
type SelectMksSelectedHallsAction = ReturnType<
  typeof selectMksSelectedHallsAction
>;
type SelectMksDataCategoryAction = ReturnType<
  typeof selectMksDataCategoryAction
>;
type SelectMksShowGraphNumberLabelAction = ReturnType<
  typeof selectMksShowGraphNumberLabelAction
>;
type SelectMksShuOptionAction = ReturnType<typeof selectMksShuOptionAction>;
type SelectMksTimelineAction = ReturnType<typeof selectMksTimelineAction>;
type SelectMksShowTransitionGraphNumberLabelAction = ReturnType<
  typeof selectMksShowTransitionGraphNumberLabelAction
>;
type SelectMksDateRangeAction = ReturnType<typeof selectMksDateRangeAction>;
type RestoreMksFromFavoriteAction = ReturnType<
  typeof restoreMksFromFavoriteAction
>;
type ClearMksSelectedHalls = ReturnType<typeof clearMksSelectedHalls>;

type ResetHallReportsFavoriteToDefaultAction = ReturnType<
  typeof resetHallReportsFavoriteToDefaultAction
>;
type SelectHallReportsCurrentHallsAction = ReturnType<
  typeof selectHallReportsCurrentHallsAction
>;
type HideMksTableColumn = ReturnType<typeof hideMksTableColumn>;
type ClearMksHiddenTableColumns = ReturnType<typeof clearMksHiddenTableColumns>;
type SelectMksTableColumnsOrderAction = ReturnType<
  typeof selectMksTableColumnsOrderAction
>;
type ChangeMksTableColumnsOrderAction = ReturnType<
  typeof changeMksTableColumnsOrderAction
>;
type SelectShowDailyCommentAction = ReturnType<
  typeof selectShowDailyCommentAction
>;
type SelectHideDailyCommentAction = ReturnType<
  typeof selectHideDailyCommentAction
>;
type SelectCheckKi = ReturnType<typeof selectCheckKi>;
type SelectUncheckKi = ReturnType<typeof selectUncheckKi>;
type SelectCheckedKiListForFavorite = ReturnType<
  typeof selectCheckedKiListForFavorite
>;
type SelectCheckKiAsNewModelsByShuCode = ReturnType<
  typeof selectCheckKiAsNewModelsByShuCode
>;
type ResetCheckedKiListAsNewModelByShuCode = ReturnType<
  typeof resetCheckedKiListAsNewModelByShuCode
>;
type SelectHallReportsIsKiGraphClosedAction = ReturnType<
  typeof selectHallReportsIsKiGraphClosedAction
>;
type ResetHallReportsIsGraphClosedByUser = ReturnType<
  typeof resetHallReportsIsGraphClosedByUser
>;
type SelectHallReportsIsGraphClosedByUserForFavorite = ReturnType<
  typeof selectHallReportsIsGraphClosedByUserForFavorite
>;
type ResetHallReportsCheckedKiList = ReturnType<
  typeof resetHallReportsCheckedKiList
>;

export type CreateHallReportsShortenedUrlAction = ReturnType<
  typeof createHallReportsShortenedUrlAction
>;

type HallReportsSettingAction =
  | SearchHallReportsAction
  | SearchResetHallReportsAction
  | ChangeHallReportsFavoriteAction
  | SelectHallReportsSearchConditionAction
  | SelectHallReportsShuAction
  | SelectHallReportsDateRangeParamsAction
  | ResetHallReportsDateRangeParamsAction
  | SelectHallReportsTabIDAction
  | ResetHallReportsSearchConditionAction
  | ResetHallReportsShuAction
  | SaveHallReportsFavoriteAction
  | SaveAsHallReportsFavoriteAction
  | AddHallReportsTableFilterAction
  | RemoveHallReportsTableFilterAction
  | ApplyHallReportsTableFilterAction
  | ResetHallReportsTableFilterAction
  | AddHallReportsKiTableFilterAction
  | RemoveHallReportsKiTableFilterAction
  | ApplyHallReportsKiTableFilterAction
  | ResetHallReportsKiTableFilterAction
  | ResetAllHallReportsKiTableFilterAction
  | SearchHallReportsDateRangeSlideAction
  | SelectKadoGraphShowNumberLabelAction
  | SelectKiGraphShowNumberLabelAction
  | SelectMksSelectedHallsAction
  | SelectMksDataCategoryAction
  | SelectMksShowGraphNumberLabelAction
  | SelectMksShuOptionAction
  | SelectMksTimelineAction
  | SelectMksShowTransitionGraphNumberLabelAction
  | SelectMksDateRangeAction
  | RestoreMksFromFavoriteAction
  | ClearMksSelectedHalls
  | HideMksTableColumn
  | ClearMksHiddenTableColumns
  | ChangeMksTableColumnsOrderAction
  | SelectMksTableColumnsOrderAction
  | ResetHallReportsFavoriteToDefaultAction
  | SelectShowDailyCommentAction
  | SelectHideDailyCommentAction
  | SelectCheckKi
  | SelectUncheckKi
  | SelectCheckedKiListForFavorite
  | SelectHallReportsCurrentHallsAction
  | SelectCheckKiAsNewModelsByShuCode
  | ResetCheckedKiListAsNewModelByShuCode
  | SelectHallReportsIsKiGraphClosedAction
  | ResetHallReportsIsGraphClosedByUser
  | SelectHallReportsIsGraphClosedByUserForFavorite
  | ResetHallReportsCheckedKiList
  | CreateHallReportsShortenedUrlAction;

/**
 * State
 */

export type HallReportsSettingState = {
  /**
   * 現在の検索条件
   */
  searchCondition: HallReportsFormConditions;
  /**
   * 現在選択中の種別・種別グループ
   */
  selectedShu: ShuOption[];
  /**
   * 現在選択中の期間指定
   */
  selectedDateRangeParams: HallReportsSettingDateRangeParams;
  /**
   * 選択している比較区分
   */
  comparativeSection: ComparativeSection | undefined;
  /**
   * 選択中のお気に入りID
   */
  selectedFavoriteId?: number;
  /**
   * 選択中のタブのID
   */
  selectedTabId: string | undefined;
  /**
   * テーブルの非表示項目一覧
   */
  tableFilterItems: string[];
  tableFilterItemsKi: { code: string; items: string[] }[];
  /**
   * 種別実績の稼働グラフに数値ラベルを表示するかどうか
   */
  showKadoGraphNumberLabel: boolean;
  /**
   * 新台/メイン機種の推移グラフに数値ラベルを表示するかどうか
   */
  showKiGraphNumberLabel: boolean;

  /**
   * 商圏タブ
   */
  mks: Mks;

  /**
   * デイリーコメントを表示するかどうか
   */
  isDailyCommentVisible: boolean | undefined;
  /**
   * 新台/メイン機種をユーザーが閉じたかどうかのフラグ
   */
  isKiGraphClosedByUser: {
    [shuCode: string]: boolean;
  };
  /**
   * チェックの付いた機種のコード
   */
  checkedModelCodes:
    | {
        byUser: {
          // ユーザーによって手動でチェックされたもの
          [shuCode: string]: string[];
        };
        asNewModel: {
          // ページ読み込み時に新機種に自動でチェックが入ったもの
          [shuCode: string]: string[];
        };
      }
    | undefined;
};

const initialDateRangeParams: HallReportsSettingDateRangeParams = {
  dateUnit: dataHallDefaultValue().dateRangeParams.dateUnit,
  dateRange: dataHallDefaultValue().dateRangeParams.dateRange,
  isComparison: dataHallDefaultValue().dateRangeParams.isComparison,
};

// Stateの初期値
const initialState: HallReportsSettingState = {
  searchCondition: dataHallDefaultValue().setting,
  selectedShu: [],
  selectedDateRangeParams: initialDateRangeParams,
  comparativeSection: undefined,
  selectedFavoriteId: undefined,
  selectedTabId: undefined,
  tableFilterItems: [],
  tableFilterItemsKi: [],
  showKadoGraphNumberLabel: true,
  showKiGraphNumberLabel: true,
  mks: {
    selectedHallCodes: null,
    dataCategory: DataCategoryShareRate,
    showGraphNumberLabel: true,
    shuOptionCode: 'all',
    timeline: 'all',
    dateRange: DateRange30Days,
    showTransitionGraphNumberLabel: true,
    hiddenColumnCodes: [],
    columnsOrder: [],
  },
  isDailyCommentVisible: undefined,
  isKiGraphClosedByUser: {},
  checkedModelCodes: undefined,
};

/**
 * Selector
 */

// 店舗レポート設定全てを取得する
export function hallReportsSettingSelector(rootState: {
  hallReportsSetting: HallReportsSettingState;
}) {
  return rootState.hallReportsSetting;
}

// 店舗レポートの検索条件を取得する
export function hallReportsSearchConditionSelector(rootState: {
  hallReportsSetting: HallReportsSettingState;
}) {
  return rootState.hallReportsSetting.searchCondition;
}

// 検索条件のうち店舗コードを返す
export const hallReportsSearchConditionHallsSelector = createSelector(
  hallReportsSearchConditionSelector,
  ({ halls }) => (halls && halls[0]) || null
);

/**
 * 検索条件のうち選択されている店舗の設定を返す
 */
export const hallReportsSearchConditionSelectedHallSelector = createSelector(
  [settingsOptionsHallHallSelector, hallReportsSearchConditionHallsSelector],
  (halls, code) => {
    const selectedHall = halls.find((hall) => hall.code === code);
    return selectedHall;
  }
);

// 検索条件のうち検索期間を返す
export const hallReportsSearchConditionYmdListSelector = createSelector(
  hallReportsSearchConditionSelector,
  ({ ymdList }) => ymdList ?? null
);

// 検索条件のうち比較期間を返す
export const hallReportsSearchConditionYmdComparisonListSelector =
  createSelector(
    hallReportsSearchConditionSelector,
    ({ ymdComparisonList }) => ymdComparisonList ?? null
  );

// 検索条件のうち当日除外を返す
export const hallReportsSearchConditionExcludeTodaySelector = createSelector(
  hallReportsSearchConditionSelector,
  ({ excludeToday }) => excludeToday
);

// 店舗レポートで選択中の種別・種別グループを取得する
export function hallReportsSelectedShuSelector(rootState: {
  hallReportsSetting: HallReportsSettingState;
}) {
  return rootState.hallReportsSetting.selectedShu;
}

// 店舗レポートで選択中の期間指定を取得する
export function hallReportsSelectedDateRangeParamsSelector(rootState: {
  hallReportsSetting: HallReportsSettingState;
}) {
  return rootState.hallReportsSetting.selectedDateRangeParams;
}

// 機種集計で選択中のお気に入りIDを取得する
export function hallReportsSelectedFavoriteSelector(rootState: {
  hallReportsSetting: HallReportsSettingState;
}) {
  return rootState.hallReportsSetting.selectedFavoriteId;
}

/**
 * 店舗レポートで現在選択中のお気に入りデータを取得する
 * @returns 現在選択中のお気に入りデータ（未選択時: undefined）
 */
export const hallReportsSelectedFavoriteDataSelector = createSelector(
  [hallReportsSelectedFavoriteSelector, favoritesSelector],
  (favoriteId, favorites) => {
    if (favoriteId === undefined) return;

    return favorites?.favorites?.find((favorite) => favorite.id === favoriteId);
  }
);

/**
 * 現在選択中のお気に入りの店舗レポートの各種データ
 */
export const hallReportsSelectedFavoriteSettingSelector = createSelector(
  [hallReportsSelectedFavoriteSelector, favoritesSelector],
  (favoriteId, favorite) => {
    if (!favoriteId) return;

    const favoriteItem = favorite?.favorites?.find(
      (item) => item.id === favoriteId
    );

    return favoriteItem?.pageSetting?.hallReports;
  }
);

/**
 * 比較区分を取得する
 */
export const hallReportsComparativeSectionSelector = createSelector(
  hallReportsSettingSelector,
  (setting) => setting.comparativeSection
);

/**
 * 現在選択中のタブのIDを取得する
 */
export const hallReportsSelectedTabIDSelector = (state: RootState) => {
  return state.hallReportsSetting.selectedTabId;
};

/**
 * 非表示項目一覧を取得する
 * @returns 非表示項目一覧
 */
export const hallReportsSelectedTableFilterSelector = (state: RootState) => {
  return state.hallReportsSetting.tableFilterItems;
};

/**
 * 非表示項目一覧を取得する(新台/メイン機種)
 * @returns 非表示項目一覧
 */
export const hallReportsKiSelectedTableFilterSelector = (state: RootState) => {
  return state.hallReportsSetting.tableFilterItemsKi;
};

/**
 * @returns 種別実績の稼働グラフに数値ラベルを表示するかどうか
 */
export const hallReportsShuKadoGraphNumberLabelSelector = (
  state: RootState
) => {
  return state.hallReportsSetting.showKadoGraphNumberLabel;
};

/**
 * @returns 新台/メイン機種の推移グラフに数値ラベルを表示するかどうか
 */
export const hallReportsKiGraphNumberLabelSelector = (state: RootState) => {
  return state.hallReportsSetting.showKiGraphNumberLabel;
};

/**
 * 商圏実績タブの状態をすべて取得する
 */
export const hallReportsSettingMksSelector = createSelector(
  hallReportsSettingSelector,
  ({ mks }) => mks
);

/**
 * 商圏実績タブ 商圏店舗ドロップダウンリストで選択された商圏店舗コードの配列を取得する
 * デフォルト値は全店選択
 */
export const hallReportsSettingMksSelectedHallCodesSelector = createSelector(
  [
    hallReportsSettingMksSelector,
    hallReportsSearchConditionSelectedHallSelector,
  ],
  ({ selectedHallCodes }, currentHall) => {
    const marketAreaHalls = currentHall
      ? currentHall.hallsOfMarketArea ?? []
      : [];

    // 選択されたすべての商圏店舗が、商圏店舗リストに存在するか確認し、
    // 存在しない店舗があれば全件選択にする
    return selectedHallCodes &&
      selectedHallCodes.every((code) =>
        marketAreaHalls.some((v) => v.code === code)
      )
      ? selectedHallCodes
      : marketAreaHalls.map((v) => v.code);
  }
);

/**
 * 商圏実績タブ 商圏店舗ドロップダウンリストで選択された商圏店舗名の配列を取得する
 */
export const hallReportsSettingMksSelectedHallNamesSelector = createSelector(
  [
    hallReportsSettingMksSelectedHallCodesSelector,
    hallReportsSearchConditionSelectedHallSelector,
  ],
  (selectedHallCodes, searchCondition) => {
    if (!searchCondition) {
      return [];
    }
    const selectedHallNames = selectedHallCodes
      .map(
        (code) =>
          searchCondition.hallsOfMarketArea?.find((hall) => hall.code === code)
            ?.name
      )
      .filter(Boolean) as string[];

    return selectedHallNames;
  }
);

/**
 * 商圏実績タブ 商圏実績で選択された表示するデータの種類を取得する
 */
export const hallReportsSettingMksDataCategorySelector = createSelector(
  hallReportsSettingMksSelector,
  ({ dataCategory }) => dataCategory
);

/**
 * 商圏実績タブ 実績・比較差グラフの数値表示フラグを取得する
 */
export const hallReportsSettingMksShowNumberLabelSelector = createSelector(
  hallReportsSettingMksSelector,
  ({ showGraphNumberLabel }) => showGraphNumberLabel
);

/**
 * 商圏実績タブ 選択された種別グループコードを取得する
 */
export const hallReportsSettingMksShuOptionSelector = createSelector(
  hallReportsSettingMksSelector,
  ({ shuOptionCode }) => shuOptionCode
);

/**
 * 商圏実績タブ 選択された種別グループ名を取得する
 */
export const hallReportsSettingMksSelectedShuNameSelector = createSelector(
  [
    hallReportsSettingMksShuOptionSelector,
    settingsOptionsHallSearchConditionSelector,
  ],
  (selectedShuCode, settingsOptions) => {
    const allShuOptions = getShuGroupOption(settingsOptions);

    if (selectedShuCode === 'all') {
      return '全店';
    }
    return allShuOptions?.find((x) => x.code === selectedShuCode)?.name || '';
  }
);

/**
 * 商圏実績タブ 選択された時間帯を取得する
 */
export const hallReportsSettingMksTimelineSelector = createSelector(
  hallReportsSettingMksSelector,
  ({ timeline }) => timeline
);

/**
 * 商圏実績タブ 推移グラフで選択された期間を取得する
 */
export const hallReportsSettingMksDateRangeSelector = createSelector(
  hallReportsSettingMksSelector,
  ({ dateRange }) => dateRange
);

/**
 * 商圏実績タブ 推移グラフの数値表示フラグを取得する
 */
export const hallReportsSettingMksShowTransitionNumberLabelSelector =
  createSelector(
    hallReportsSettingMksSelector,
    ({ showTransitionGraphNumberLabel }) => showTransitionGraphNumberLabel
  );

/**
 * 商圏実績タブ テーブルの非表示カラムのカラムコードを取得する
 */
export const hallReportsSettingMksHiddenColumnCodesSelector = createSelector(
  hallReportsSettingMksSelector,
  ({ hiddenColumnCodes }) => hiddenColumnCodes
);

/**
 * テーブルカラムの並び順を取得する
 */
export const hallReportsSettingMksColumnsOrderSelector = createSelector(
  hallReportsSettingMksSelector,
  ({ columnsOrder }) => columnsOrder
);

// デイリーコメントの表示状態を返す
export const hallReportsIsDailyCommentVisibleSelector = createSelector(
  hallReportsSettingSelector,
  ({ isDailyCommentVisible }) => isDailyCommentVisible
);

// 新台/メイン機種をユーザーが閉じたかどうかのフラグ
const hallReportsIsKiGraphClosedSelectorCallback = (
  {
    isKiGraphClosedByUser,
  }: {
    isKiGraphClosedByUser: HallReportsSettingState['isKiGraphClosedByUser'];
  },
  shuCode: string
) => isKiGraphClosedByUser[shuCode] ?? false;

export const hallReportsIsKiGraphClosedSelector = createSelector(
  [hallReportsSettingSelector, (_: RootState, shuCode: string) => shuCode],
  hallReportsIsKiGraphClosedSelectorCallback
);

// 種別グループごとにチェックされた機種コードを返す
const hallReportsCheckedModelCodesSelectorCallback = (
  {
    checkedModelCodes,
  }: {
    checkedModelCodes: HallReportsSettingState['checkedModelCodes'];
  },
  shuCode: string
) => {
  const byUser = checkedModelCodes?.byUser?.[shuCode];
  // ユーザーが手動でチェックしたものがあればそれが優先
  if (byUser && byUser.length > 0) {
    return checkedModelCodes?.byUser?.[shuCode];
  }
  return checkedModelCodes?.asNewModel?.[shuCode]
    ? checkedModelCodes?.asNewModel?.[shuCode]
    : [];
};
export const hallReportsCheckedModelCodesSelector = createSelector(
  [hallReportsSettingSelector, (_: RootState, shuCode: string) => shuCode],
  hallReportsCheckedModelCodesSelectorCallback
);

// 種別グループごとにユーザーがチェックした機種コードを返す
const hallReportsCheckedByUserModelCodeslSelectorCallback = (
  {
    checkedModelCodes,
  }: {
    checkedModelCodes: HallReportsSettingState['checkedModelCodes'];
  },
  shuCode: string
) =>
  checkedModelCodes?.byUser?.[shuCode]
    ? checkedModelCodes?.byUser?.[shuCode]
    : [];

export const hallReportsCheckedByUserModelCodeslSelector = createSelector(
  [hallReportsSettingSelector, (_: RootState, shuCode: string) => shuCode],
  hallReportsCheckedByUserModelCodeslSelectorCallback
);

/**
 * Reducer
 */

export function hallReportsSettingReducer(
  state = initialState,
  action: HallReportsSettingAction
): HallReportsSettingState {
  switch (action.type) {
    case SELECT_HALL_REPORTS_SEARCH_CONDITION:
      return {
        ...state,
        searchCondition: action.payload.params,
      };
    case RESET_HALL_REPORTS_SEARCH_CONDITION:
      return {
        ...state,
        searchCondition: initialState.searchCondition,
        mks: initialState.mks,
        checkedModelCodes: initialState.checkedModelCodes,
        isKiGraphClosedByUser: initialState.isKiGraphClosedByUser,
      };
    case SELECT_HALL_REPORTS_SHU:
      return {
        ...state,
        selectedShu: action.payload.shus,
      };
    case RESET_HALL_REPORTS_SHU:
      return {
        ...state,
        selectedShu: [],
      };
    case SELECT_HALL_REPORTS_DATE_RANGE_PARAMS:
      return {
        ...state,
        selectedDateRangeParams: {
          dateRange: action.payload.dateRange,
          dateUnit: action.payload.dateUnit,
          isComparison: action.payload.isComparison,
        },
        comparativeSection: action.payload.comparativeSection,
      };
    case RESET_HALL_REPORTS_DATE_RANGE_PARAMS:
      return {
        ...state,
        selectedDateRangeParams: initialState.selectedDateRangeParams,
      };
    case CHANGE_HALL_REPORTS_FAVORITE:
      return {
        ...state,
        selectedFavoriteId: action.payload.favorite,
      };
    case SELECT_HALL_REPORT_TAB_ID:
      return {
        ...state,
        selectedTabId: action.payload.tabID,
      };
    case ADD_HALL_REPORTS_TABLE_FILTER:
      return {
        ...state,
        tableFilterItems: [
          ...state.tableFilterItems,
          action.payload.columnCode,
        ],
      };
    case REMOVE_HALL_REPORTS_TABLE_FILTER:
      return {
        ...state,
        tableFilterItems: state.tableFilterItems.filter(
          (columnCode) => columnCode !== action.payload.columnCode
        ),
      };
    case APPLY_HALL_REPORTS_TABLE_FILTER:
      return {
        ...state,
        tableFilterItems: action.payload.tableFilterItems,
      };
    case RESET_HALL_REPORTS_TABLE_FILTER:
      return {
        ...state,
        tableFilterItems: [],
      };
    case ADD_HALL_REPORTS_KI_TABLE_FILTER: {
      if (state.tableFilterItemsKi.length === 0) {
        return {
          ...state,
          tableFilterItemsKi: [
            {
              code: action.payload.code,
              items: [action.payload.columnCode],
            },
          ],
        };
      }

      const result = state.tableFilterItemsKi.map((item) => {
        if (item.code === action.payload.code) {
          return {
            code: item.code,
            items: [...item.items, action.payload.columnCode],
          };
        }
        return item;
      });

      if (result.find((item) => item.code === action.payload.code)) {
        return {
          ...state,
          tableFilterItemsKi: [...result],
        };
      }

      const newItem = {
        code: action.payload.code,
        items: [action.payload.columnCode],
      };

      return {
        ...state,
        tableFilterItemsKi: [...result, newItem],
      };
    }
    case REMOVE_HALL_REPORTS_KI_TABLE_FILTER: {
      const result = state.tableFilterItemsKi.map((item) => {
        if (item.code === action.payload.code) {
          return {
            code: item.code,
            items: item.items.filter(
              (columnCode) => columnCode !== action.payload.columnCode
            ),
          };
        }
        return item;
      });

      return {
        ...state,
        tableFilterItemsKi: [...result],
      };
    }

    case APPLY_HALL_REPORTS_KI_TABLE_FILTER:
      return {
        ...state,
        tableFilterItemsKi: [...action.payload.tableFilterItemsKi],
      };
    case RESET_HALL_REPORTS_KI_TABLE_FILTER: {
      return {
        ...state,
        tableFilterItemsKi: [
          ...state.tableFilterItemsKi.filter(
            (item) => item.code !== action.payload.code
          ),
        ],
      };
    }
    case RESET_ALL_HALL_REPORTS_KI_TABLE_FILTER:
      return {
        ...state,
        tableFilterItemsKi: [],
      };
    case SELECT_KADO_GRAPH_SHOW_GRAPH_NUMBER_LABEL: {
      return {
        ...state,
        showKadoGraphNumberLabel: action.payload.flag,
      };
    }
    case SELECT_KI_GRAPH_SHOW_GRAPH_NUMBER_LABEL: {
      return {
        ...state,
        showKiGraphNumberLabel: action.payload.flag,
      };
    }

    case SELECT_MKS_SELECTED_HALLS_ACTION: {
      return {
        ...state,
        mks: {
          ...state.mks,
          selectedHallCodes: action.payload.hallCodes,
        },
      };
    }
    case SELECT_MKS_DATA_CATEGORY_ACTION: {
      return {
        ...state,
        mks: {
          ...state.mks,
          dataCategory: action.payload.dataCategory,
        },
      };
    }
    case SELECT_MKS_SHOW_GRAPH_NUMBER_LABEL_ACTION: {
      return {
        ...state,
        mks: {
          ...state.mks,
          showGraphNumberLabel: action.payload.showGraphNumberLabel,
        },
      };
    }
    case SELECT_MKS_SHU_OPTION_ACTION: {
      return {
        ...state,
        mks: {
          ...state.mks,
          shuOptionCode: action.payload.shuOptionCode,
        },
      };
    }
    case SELECT_MKS_TIMELINE_ACTION: {
      return {
        ...state,
        mks: {
          ...state.mks,
          timeline: action.payload.timeline as TimelineValue,
        },
      };
    }
    case SELECT_MKS_SHOW_TRANSITION_GRAPH_NUMBER_LABEL_ACTION: {
      return {
        ...state,
        mks: {
          ...state.mks,
          showTransitionGraphNumberLabel:
            action.payload.showTransitionGraphNumberLabel,
        },
      };
    }
    case SELECT_MKS_DATE_RANGE_ACTION: {
      return {
        ...state,
        mks: {
          ...state.mks,
          dateRange: action.payload.dateRange,
        },
      };
    }
    case SEARCH_RESET_HALL_REPORTS: {
      return {
        ...state,
        mks: initialState.mks,
        isDailyCommentVisible: undefined,
        checkedModelCodes: initialState.checkedModelCodes,
        isKiGraphClosedByUser: initialState.isKiGraphClosedByUser,
        comparativeSection: initialState.comparativeSection,
      };
    }
    case RESTORE_MKS_FROM_FAVORITE_ACTION: {
      return {
        ...state,
        mks: {
          ...state.mks,
          ...action.payload.mks,
        },
      };
    }
    case CLEAR_MKS_SELECTED_HALLS: {
      return {
        ...state,
        mks: {
          ...state.mks,
          selectedHallCodes: initialState.mks.selectedHallCodes,
        },
      };
    }
    case HIDE_MKS_TABLE_COLUMN_ACTION: {
      return {
        ...state,
        mks: {
          ...state.mks,
          hiddenColumnCodes: [
            ...state.mks.hiddenColumnCodes,
            action.payload.columnCode,
          ],
        },
      };
    }
    case CLEAR_MKS_HIDDEN_TABLE_COLUMNS_ACTION: {
      return {
        ...state,
        mks: {
          ...state.mks,
          hiddenColumnCodes: [],
        },
      };
    }
    case CHANGE_MKS_TABLE_COLUMNS_ORDER: {
      const { draggedId, droppedId } = action.payload;
      if (!draggedId || !droppedId) {
        return state;
      }

      const prevColumnsOrder = state.mks.columnsOrder;

      // 移動されたカラムを並びから削除
      const draggedIdIndex = prevColumnsOrder.findIndex((x) => x === draggedId);
      const columnsWithoutDraggedColumn = [
        ...prevColumnsOrder.slice(0, draggedIdIndex),
        ...prevColumnsOrder.slice(draggedIdIndex + 1),
      ];

      // 移動先のカラムの前に移動されたカラムを追加
      const droppedIdIndex = columnsWithoutDraggedColumn.findIndex(
        (x) => x === droppedId
      );
      const ordered = [
        ...columnsWithoutDraggedColumn.slice(0, droppedIdIndex),
        draggedId,
        ...columnsWithoutDraggedColumn.slice(droppedIdIndex),
      ];

      return {
        ...state,
        mks: {
          ...state.mks,
          columnsOrder: ordered,
        },
      };
    }
    case SELECT_MKS_TABLE_COLUMNS_ORDER:
      if (state.mks.columnsOrder.length > 0) {
        return state;
      }
      return {
        ...state,
        mks: {
          ...state.mks,
          columnsOrder: action.payload,
        },
      };

    case RESET_HALL_REPORTS_FAVORITE_TO_DEFAULT: {
      return {
        ...state,
        selectedFavoriteId: undefined,
      };
    }

    case SEARCH_HALL_REPORTS: {
      return {
        ...state,
        isDailyCommentVisible: undefined,
        comparativeSection: action.payload.comparativeSection,
      };
    }
    case SEARCH_HALL_REPORTS_DATE_RANGE_SLIDE: {
      return {
        ...state,
        isDailyCommentVisible: undefined,
      };
    }
    case SELECT_SHOW_DAILY_COMMENT: {
      return {
        ...state,
        isDailyCommentVisible: true,
      };
    }
    case SELECT_HIDE_DAILY_COMMENT: {
      return {
        ...state,
        isDailyCommentVisible: false,
      };
    }
    case SELECT_HALL_IS_KI_GRAPH_CLOSED: {
      return {
        ...state,
        isKiGraphClosedByUser: {
          ...state.isKiGraphClosedByUser,
          [action.payload.shuCode]: true,
        },
      };
    }
    case SELECT_HALL_REPORTS_CHECK_KI: {
      const { shuCode, modelCode } = action.payload;
      const byUser = state.checkedModelCodes?.byUser?.[shuCode] ?? [];
      const asNewModels = state.checkedModelCodes?.asNewModel?.[shuCode] ?? [];
      const checked = [...byUser, ...asNewModels];
      return {
        ...state,
        checkedModelCodes: {
          ...state.checkedModelCodes,
          asNewModel: {
            ...(state.checkedModelCodes?.asNewModel
              ? state.checkedModelCodes.asNewModel
              : {}),
            [shuCode]: [],
          },
          byUser: {
            ...(state.checkedModelCodes?.byUser
              ? state.checkedModelCodes.byUser
              : {}),
            [shuCode]: [...checked, modelCode],
          },
        },
      };
    }
    case SELECT_HALL_REPORTS_UNCHECK_KI: {
      const { shuCode, modelCode } = action.payload;
      const byUser = state.checkedModelCodes?.byUser?.[shuCode] ?? [];
      const asNewModels = state.checkedModelCodes?.asNewModel?.[shuCode] ?? [];
      const checked = [...byUser, ...asNewModels];
      return {
        ...state,
        checkedModelCodes: {
          ...state.checkedModelCodes,
          asNewModel: {
            ...(state.checkedModelCodes?.asNewModel
              ? state.checkedModelCodes.asNewModel
              : {}),
            [shuCode]: [],
          },
          byUser: {
            ...(state.checkedModelCodes?.byUser
              ? state.checkedModelCodes.byUser
              : {}),
            [shuCode]: [...checked.filter((x: string) => x !== modelCode)],
          },
        },
      };
    }
    case SELECT_HALL_REPORTS_CHECK_KI_AS_NEW_MODEL_BY_SHUCODE: {
      const { shuCode, modelCodes } = action.payload;
      return {
        ...state,
        checkedModelCodes: {
          ...(state.checkedModelCodes
            ? state.checkedModelCodes
            : { byUser: {} }),
          asNewModel: {
            ...(state.checkedModelCodes?.asNewModel
              ? state.checkedModelCodes.asNewModel
              : {}),
            [shuCode]: modelCodes,
          },
        },
      };
    }
    case RESET_HALL_REPORTS_CHECKED_KI_LIST_AS_NEW_MODEL_BY_SHUCODE: {
      const { shuCode } = action.payload;
      return {
        ...state,
        checkedModelCodes: {
          ...(state.checkedModelCodes
            ? state.checkedModelCodes
            : { byUser: {} }),
          asNewModel: {
            ...(state.checkedModelCodes?.asNewModel
              ? state.checkedModelCodes.asNewModel
              : {}),
            [shuCode]: [],
          },
        },
      };
    }
    case SELECT_HALL_REPORTS_CHECKED_KI_LIST: {
      const { checkedModelCodes } = action.payload;
      return {
        ...state,
        checkedModelCodes,
      };
    }
    case RESET_HALL_REPORTS_IS_GRAPH_CLOSED_BY_USER: {
      return {
        ...state,
        isKiGraphClosedByUser: initialState.isKiGraphClosedByUser,
      };
    }
    case RESET_HALL_REPORTS_CHECKED_KI_LIST: {
      return {
        ...state,
        checkedModelCodes: initialState.checkedModelCodes,
      };
    }
    case SELECT_HALL_REPORTS_IS_GRAPH_CLOSED_BY_USER: {
      return {
        ...state,
        isKiGraphClosedByUser: action.payload.isKiGraphClosedByUser,
      };
    }
    case SELECT_HALL_REPORTS_CURRENT_HALLS: {
      return {
        ...state,
        searchCondition: {
          ...state.searchCondition,
          halls: action.payload.halls,
        },
      };
    }
    default:
      return state;
  }
}
